import {Component, EventEmitter, Input, Output} from '@angular/core';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {TranslateService} from '@ngx-translate/core';
import {sortBy} from 'lodash';
import {IwGridColumn, TableConfigEvent} from '../../../models';

@Component({
    selector: 'iw-table-config',
    styleUrls: ['./iw-table-config.component.scss'],
    templateUrl: './iw-table-config.component.html',
    standalone: false
})
export class IwTableConfigComponent<T> {

    @Output() public columnsChange = new EventEmitter<TableConfigEvent<T>>();
    @Output() public resetConfig = new EventEmitter<TableConfigEvent<T>>();

    constructor(private _translate: TranslateService) {
    }

    /** Columns to show/hide */
    private _columns: IwGridColumn<T>[] = [];

    public get columns() {
        return this._columns;
    }

    private _initialColumns: IwGridColumn<T>[] = [];

    @Input()
    public set initialColumns(v: IwGridColumn<T>[]) {
        this._initialColumns = v;
        this.setColumns(v);
    }

    private _visibleColumns: IwGridColumn<T>[] = [];

    @Input()
    public set visibleColumns(v: IwGridColumn<T>[]) {
        this._visibleColumns = v;
        this.setColumns(this.columns);
    }

    public apply() {
        this.columnsChange.emit({columns: this._columns});
    }

    public reset() {
        const columns = this._initialColumns.map(e => ({
            ...e, hidden: e.hidden
        }));

        this.resetConfig.emit({columns});
    }

    public setColumns(v: IwGridColumn<T>[]) {
        v = v.map(e => ({
            ...e, name: this._translate.instant((e.name || e.prop || '').toString())
        }));
        this._columns = getDeepCopy(sortBy(v, ['name'], ['asc']))
            .map((column: IwGridColumn<T>) => ({...column, hidden: !this.isColumnHidden(column)}));
    }

    private isColumnHidden(column: IwGridColumn<T>) {
        return this._visibleColumns.find(col => col.prop === column.prop);
    }
}
