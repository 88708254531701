/* eslint-disable no-null/no-null */
// PRIMENG uses NULL for no date

import {Component, EventEmitter, Input, Output} from '@angular/core';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';

@Component({
    selector: 'iw-month-picker',
    templateUrl: './iw-month-picker.component.html',
    providers: [buildCustomValueProvider(IwMonthPickerComponent)],
    standalone: false
})
export class IwMonthPickerComponent extends BaseFormControl<string> {

    public dateFormat = 'yy m';
    @Input() public yearFrom = 1900;

    @Input() public yearTo = 2000;

    @Input() public isReadOnly = false;
    public visible = true;

    /** Input to set background color week if needed */
    @Input() public bckColor = '';

    /** Input to set background color input date if needed */
    @Input() public bckClrCalendar = '';

    @Output() public selectEvent = new EventEmitter<Event>();

    public yearRange = `${this.yearFrom}:${new Date().getFullYear() + 25}`;

    private _primeValue?: Date;
    private _lastValue?: string;

    constructor() {
        super();
    }

    public get primeNgValue(): Date {
        return this._primeValue || new Date();
    }

    public set primeNgValue(v: Date) {
        this._lastValue = this.value;
        this.dateFormat = v?.getMonth() <= 8 ? 'yy m' : 'yym';
        this.updateVisibility();
        this._primeValue = v;
    }

    public onSelectEvent(event: Event) {
        this.selectEvent.emit(event);
    }

    public writeValue(obj: any): void {
        this.value = obj;
    }

    public updateVisibility(): void {
        this.visible = false;
        setTimeout(() => this.visible = true, 0);
    }

    public setValue(v?: string) {
        this._lastValue = v;
        this.dateFormat = v?.indexOf(' ') === -1 ? 'yym' : 'yy m';
        this._primeValue = new Date(v?.indexOf(' ') !== -1 ? (v || '') : (v?.split(/(?=.{2}$)/)
            .join(' ') || '')); // Convert salId on date
    }
}
