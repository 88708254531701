import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioItem} from '@app/sam-base/models/components/radio-item.model';

import {
    SearchEntityDateOperator,
    SearchEntityDateTimeOperator,
    SearchEntityNumberOperator,
    SearchEntityOperator,
    SearchEntityStatusOperator,
    SearchEntityStringOperator,
    SearchExactMatch
} from '../../core/rest-api';
import {IwColumnDataType, IwGridColumn} from '../../models';
import {EntityMap, EntityStatusMap} from './entity-status-map';
import {trueFalseOperator} from './iw-grid-column-config-mappings';

@Component({
    selector: 'iw-grid-conlumn-config',
    templateUrl: './iw-grid-column-config.component.html',
    standalone: false
})
export class IwGridColumnConfigComponent<T> {
    @Input() public groupBy?: Extract<keyof T, string | number>;

    @Input() public showGrouping = true;
    @Output() public groupByChange = new EventEmitter<Extract<keyof T, string | number>>();
    @Output() public applyFilters = new EventEmitter<IwGridColumn<T>>();
    public operators: any = SearchEntityStringOperator;
    public op: SearchEntityOperator = SearchEntityStringOperator.Like;
    public q = '';
    public q2 = '';
    public statusQuery: boolean[] = [];
    public cdeTypeOptions: RadioItem<string>[] = [
        {
            label: 'CDE',
            value: 'cde',
            checked: true
        },
        {
            label: 'CDF',
            value: 'cdf'
        }];
    public booleanOptions: RadioItem<boolean | string>[] = [
        {
            label: 'trueFalse',
            value: trueFalseOperator,
            checked: true
        },
        {
            label: 'true',
            value: true,
            checked: false
        },
        {
            label: 'false',
            value: false,
            checked: false
        }];

    constructor() {
    }

    public get columnStatus(): (number | string)[] {
        if (this._column && this._column.valueFormat) {
            return EntityStatusMap
                .getStatusValue(this._column.valueFormat as keyof EntityMap);
        }

        return [];
    }

    private _column?: IwGridColumn<T>;

    public get column() {
        return this._column;
    }

    @Input()
    public set column(c: IwGridColumn<T> | undefined) {
        this.setColumn(c);
    }

    public get groupByText() {
        const off = 'group_by';
        const on = 'ungroup_by';

        if (this.column) {
            return this.groupBy === this.column.prop ? on : off;
        }

        return off;
    }

    public onGroupBy() {
        if (this.column) {
            const prop = this.column.prop;
            this.groupBy = prop === this.groupBy ? undefined : prop;
            this.groupByChange.emit(this.groupBy);
        }
    }

    // eslint-disable-next-line complexity
    public setColumn(c?: IwGridColumn<T>) {
        if (c) {
            this._column = c;
            this.operators = this.getOperatorGroup(c.type);

            this.q = c.filterQuery || '';

            if (c.filterOperator) {
                this.op = c.filterOperator;
            } else {
                this.op = <SearchEntityOperator>Object.values(this.operators)[0];
            }
            this.statusQuery = c.statusQuery || [];
            if ((c.type === 'status' || c.type === 'misStatusRap') && !this.statusQuery.length) {
                this.columnStatus.forEach(() => this.statusQuery.push(true));
                this.op = SearchEntityStatusOperator.NotInclude;
            }
        }
    }

    public onApplyFiltersDelay() {
        setTimeout(() => {
            this.onApplyFilters();
        }, 300);
    }

    // eslint-disable-next-line complexity
    public onApplyFilters() {
        if (this._column) {
            // Clear filters for boolean radio options
            if (this.q === trueFalseOperator) {
                this.onClearFilters();
                return;
            }
            this._column.filterOperator = this.op || this.operators['Like'] || this.operators['Before'] || this.operators['GreaterThan'];
            if (typeof this.q === 'boolean') {
                this._column.filterQuery = this.q;
            } else {
                this._column.filterQuery = this.q.toLowerCase();
            }

            this._column.statusQuery = this.statusQuery;
            if (this.q2) {
                this._column.filterQuery2 = this.q2.toLowerCase();
            }

            // handle enum cases
            if (this._column.type === 'enum') {
                // if this.statusQuery is empty or all value false, then false otherwise true
                this._column.filterOperator = SearchEntityStatusOperator.In;
                if (this.statusQuery.length === 0 || this.statusQuery.every(status => !status)) {
                    this._column.statusQuery = EntityStatusMap.getFilterConfigAllIncluded(this._column.valueFormat as keyof EntityMap);
                } else {

                }
            }
        }

        this.applyFilters.emit(this._column);
    }

    public onClearFilters() {
        this.q = '';
        this.q2 = '';
        if (this.column && (this.column.type === 'status' || this.column.type === 'misStatusRap' || this.column.type === 'enum')) {
            this.statusQuery = [];
            this.columnStatus.forEach(() => this.statusQuery.push(true));
        }
        this.onApplyFilters();
    }

    public getTypeColumn() {
        if (this._column) {
            const typeTest = this._column.type;
            return typeTest !== undefined ? typeTest : 'string';
        }
        return 'string';
    }

    public onPeriodSelect(e: { salId: string } | undefined) {
        if (e) {
            this.q = e.salId || '';
            this.onApplyFilters();
        }
    }

    public getRadioOptions(type: IwColumnDataType): RadioItem<boolean | string | undefined>[] {
        if (type === 'cdeType') {
            return this.cdeTypeOptions;
        }
        return this.booleanOptions;
    }

    enumTranslate(column: IwGridColumn<T> | undefined, s: number | string) {
        return `enum.${column?.enumPrefix}.${s}`
    }

    // eslint-disable-next-line complexity
    private getOperatorGroup(type?: IwColumnDataType) {
        switch (type) {
            case 'number':
            case 'mnt':
                return SearchEntityNumberOperator;
            case 'string':
                return SearchEntityStringOperator;
            case 'date':
                return SearchEntityDateOperator;
            case 'dateTime':
                return SearchEntityDateTimeOperator;
            case 'activePer':
                return SearchExactMatch;
            case 'cdeType':
                return SearchExactMatch;
            case 'boolean':
                return SearchExactMatch;
            case 'enum':
                return SearchEntityStatusOperator;

            default:
                return SearchEntityStringOperator;
        }
    }
}
