import {Component, Type} from '@angular/core';
import {PlacementForm} from '@app/modules/sam-main/placement/placement.forms';
import {BaseColumnDetail} from '@app/sam-base/base/base-column-detail';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {EsQueryStatement, getEntityMetadata} from '@app/sam-base/core/rest-api';
import {IwGridColumn, RowClickEvent} from '@app/sam-base/models';
import {Ppsuivi} from '@app/sam-base/models/placement';

@Component({
    templateUrl: './entity-detail.component.html',
    standalone: false
})
export class EntityDetailMissionComponent<T> extends BaseColumnDetail<T> {
    public entity: Type<any> = Ppsuivi;

    public query: EsQueryStatement<T>[] = [];

    public noData = false;

    public columns: IwGridColumn<any>[] = [
        {
            index: 0,
            prop: 'dateCreat',
            type: 'dateTime',
            name: 'date'
        },
        {
            index: 1,
            prop: 'daterappel',
            type: 'date',
            name: 'rappel'
        },
        {
            index: 2,
            prop: 'action'
        },
        {
            index: 3,
            prop: 'nomcliatt',
            name: 'contact'
        }];

    constructor(private readonly _formHandler: FormHandlerService<PlacementForm>) {
        super();
    }

    public async instaciate(row: T) {
        try {
            const meta = getEntityMetadata(row);
            const sta = EsQueryStatement.fromTerm({
                [meta.$pk]: meta.$getPk()
            }, 'filter');
            this.query = [sta];
        } catch {
        }
    }

    public onDataChanged(data?: T[]) {
        if (data) {
            this.noData = data.length === 0;
        }
    }

    public getEntityName() {
        return 'ppclimis';
    }

    public onRowDoubleClick(rowEvent: RowClickEvent<T>) {
        const entity = rowEvent.row;
        const meta = getEntityMetadata(entity);
        const pk = meta.$getPk() || '';

        this._formHandler.showFormDialog(PlacementForm.SuiviEdit, {
            suitype: 'M',
            action: 'MISACTION'
        }, s => ({
            ...s,
            entityId: pk,
            diagTitle: 'title_ppmissui'
        }));
    }
}
