import {Component, EventEmitter} from '@angular/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalComponent} from '@app/sam-base/models';
import {Ppcha} from '@app/sam-base/models/placement';
import {ListItem} from '@shared/widgets/form-components/list-box/list-item.model';

import {PpchaData} from './ppcha.options';

@Component({
    selector: 'iw-ppcha', templateUrl: './ppcha.component.html',
    standalone: false
})
export class PpchaComponent implements ModalComponent<string, PpchaData> {

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();

    public selectionListIds: string[] = [];

    public input: ListItem<string>[] = [];

    public output: ListItem<string>[] = [];

    private _ok?: (result: string) => void;
    private _cancel?: (error?: any) => void;
    private _cliId?: string;

    constructor(private rest: RestApiService) {
    }

    public async setData(data: PpchaData): Promise<void> {
        if (data.cliId) {
            this._cliId = data.cliId;
        }
        this.selectionListIds = (data.ids ?? '').split(';');
        this.updateLists();
    }

    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onPressOk(event: ListItem<string>[]) {
        const seletions = event.map(e => e.value)
            .join(';');
        if (this._ok) {
            this._ok(seletions);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    private updateLists() {
        const input: ListItem<string>[] = [];
        const output: ListItem<string>[] = [];

        this.rest.entityClient.getService(Ppcha)
            .getRefData()
            .subscribe((res) => {
                // eslint-disable-next-line complexity
                res.forEach((e: Ppcha) => {
                    if (e.clichaId && !this.selectionListIds.includes(e.clichaId) && this._cliId && this._cliId === e.cliId) {
                        input.push({label: e.clichaId + ' | ' + e.chantier, value: e.clichaId});
                    }
                    if (e.clichaId && this.selectionListIds.includes(e.clichaId)) {
                        output.push({label: e.clichaId + ' | ' + e.chantier, value: e.clichaId});
                    }
                });
                this.output = output;
                this.input = input;
            });
    }

}
