import {Component, ElementRef, Input, OnInit, Type} from '@angular/core';
import {
    IwTextFieldAutocompleteComponent
} from '@app/sam-base/components/iw-textfield-autocomplete/iw-textfield-autocomplete.component';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {ModalComponent} from '@app/sam-base/models';
import {
    IwModalSelectTableComponent
} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.component';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {Observable} from 'rxjs';

/** Like iw-textfield but double click allows to slect a value from a table */
@Component({
    selector: 'iw-textfield-list', templateUrl: './iw-textfield-list.component.html',
    standalone: false
})
export class IwTextfieldListComponent<T> extends IwTextFieldAutocompleteComponent<T> implements OnInit {

    /** Option to use when loading the sugestions list */
    @Input() public listOption?: ModalSelectTableOption<T, string>;

    /** Property from which to filter the results */
    @Input() public propsToFilter?: (keyof T)[];

    public fetchAction?: () => Observable<T[]>;

    constructor(public readonly _modalService: ModalService, elRef: ElementRef, restService: RestApiService) {
        super(elRef, restService);
    }

    public ngOnInit() {
        if (!this.listOption || !this.listOption.fetchAction) {
            return;
        }
        this.fetchAction = this.listOption.fetchAction;
    }

    public async onDoubleClick() {
        if (!this.listOption) {
            return;
        }
        // eslint-disable-next-line max-len
        const comp: Type<ModalComponent<string[], T>> = <any>IwModalSelectTableComponent;
        try {
            const data = await this._modalService
                .showModal<string[], T>(comp, <any>this.listOption);
            this.value = data[0];
        } catch {
        }
    }
}
