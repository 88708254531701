import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SortDirection} from '@app/sam-base/models';

import {Sagstype} from '@app/sam-base/models/common/sagstype';

@Component({
    selector: 'iw-sagstype-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwSagstypeDropdownComponent)],
    standalone: false
})
export class IwSagstypeDropdownComponent extends BaseEntityDropdown<Sagstype> {

    @Input() public width?: string | number;
    public selectedValue = undefined;

    constructor() {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    @Input()
    public set types(v: string[]) {
        if (v.length) {
            // Filter by type
            this.resultFilters = v ? (e: Sagstype) => v.includes(e.gstype ?? '') : (_: Sagstype) => true;
        } else {
            // Show all sagstypes
            this.resultFilters = e => true;
        }
    }

    public getSortProperty(): keyof Sagstype {
        return 'gstype';
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    public getExtraProperties(): (keyof Sagstype)[] {
        return ['gstype'];
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType() {
        return Sagstype;
    }

    protected buildLabel(e: Sagstype): string {
        return e.titre ?? '';
    }
}
