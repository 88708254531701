import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';
import {FieldStatus} from '../../models';

@Component({
    selector: 'iw-textfield',
    templateUrl: './iw-textfield.component.html',
    providers: [buildCustomValueProvider(IwTextFieldComponent)],
    standalone: false
})
export class IwTextFieldComponent extends BaseFormControl<string> implements OnInit, OnDestroy {
    @Input() public required: boolean = false;
    @Input() public icon?: string;

    @Input() public placeholder = '';

    @Input() public warningMessage?: string;

    @Input() public inputClass?: string;

    @Input() public status: FieldStatus = 'undefined';

    @Input() public type: 'text' | 'number' | 'password' = 'text';

    @Input() public minValue?: number = -Number.MAX_VALUE;

    @Input() public maxValue?: number;

    @Input() public tooltip?: string;

    @Input() public tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'bottom';

    @Input() public stepValue = 1;

    @Input() public pattern?: string;

    // Applies the 'active-input' class that changes field background color
    @Input() public entityTextfield = false;

    // Mask format for AVS number: '000.0000.0000.00'
    @Input() public mask?: string;

    @Input() public prefix = '';
    @Input() public suffix = '';

    @Input() public maxlength?: string;

    /**
     * Set fraction decimals to format value
     * 0 disable this
     *
     * @type {(0 | 1 | 2 | 3 | 4 | 5)}
     * @memberof IwTextFieldComponent
     */
    @Input() public decimals: 0 | 1 | 2 | 3 | 4 | 5 = 2;

    @Input() public trimWhiteSpacesOnInput = false;

    private _subs = new Subject();

    constructor(private elRef: ElementRef) {
        super();
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public ngOnInit() {
        this.trimWhiteSpacesOnInputHandler();
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    public onBlur() {
        super.onBlur();
    }

    protected getClassName() {
        return {
            ['status-' + this.status]: true,
            ['suffix']: !!this.suffix
        };
    }

    private trimWhiteSpacesOnInputHandler() {
        if (this.trimWhiteSpacesOnInput) {
            this.valueChange.pipe(debounceTime(200), takeUntil(this._subs))
                .subscribe(value => {
                    const newValue = value?.trim();
                    if (this._value === newValue) {
                        return;
                    }
                    this.writeValue(newValue);
                });
        }
    }

}
