import {Component, ElementRef, Input, Type} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {IwTextFieldComponent} from '@app/sam-base/components/iw-textfield/iw-textfield.component';
import {getEntityMetadata} from '@app/sam-base/core/rest-api';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {EntitySelectService} from '@app/sam-base/core/services/entity-select.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {stringifyIfNotString} from '@app/sam-base/helpers/string';
import {ObjectMap} from '@app/sam-base/models/object-map.model';
import {Ppcli} from '@app/sam-base/models/placement';
import {isArray} from 'lodash';
import {filter} from 'rxjs/operators';

@Component({
    styleUrls: ['./entity-textfield.component.scss'],
    templateUrl: './entity-textfield.component.html',
    selector: 'iw-entity-textfield',
    providers: [buildCustomValueProvider(EntityTextfieldComponent)],
    standalone: false
})
export class EntityTextfieldComponent<T> extends IwTextFieldComponent {

    @Input() public showIdField = true;

    @Input() public allowSearch = false;

    @Input() public inputClass?: string;

    @Input() public elasticEntity?: Type<T>;

    @Input() public filter?: ObjectMap<T>;
    @Input() public name: (keyof T)[] = [];
    @Input() public separator = ' ';
    @Input() public valueField: keyof T | undefined;
    private _entityId = '';

    constructor(private readonly _store: IwStoreService, private readonly _select: EntitySelectService<T>, private readonly _restService: RestApiService, elRef: ElementRef) {
        super(elRef);
    }

    private _entityValue?: string;

    public get entityValue() {
        return this._entityValue;
    }

    public set entityValue(value) {
        this._entityValue = value;
    }

    private _entity: Type<T> = <any>Ppcli;

    public get entity() {
        return this._entity;
    }

    @Input()
    public set entity(val: Type<T>) {
        this.setEntity(val);
    }

    public onDoubleClick() {
        if (!this.allowSearch || !this._entity) {
            return;
        }

        this._select.selectEntity({entity: this.entity, filter: this.filter});
        const sub = this._store.entitySelect(this._entity)
            .pipe(filter(s => s.completed))
            .subscribe(s => {
                sub.unsubscribe();
                const sel = s.selected[0];
                if (sel) {
                    this._entityId = '' + getEntityMetadata(sel)
                        .$getPk();
                    let value: string | number = this._entityId;
                    if (this.valueField) {
                        // @ts-ignore
                        value = (typeof sel === 'object' && sel.hasOwnProperty(this.valueField)) ? '' + sel[this.valueField] : '' + getEntityMetadata(sel)
                            .$getPk();

                    }
                    this.setEntityValue(sel);
                    setTimeout(() => {
                        this.value = '' + value;
                    }, 0);
                } else {
                    this._entityValue = '';
                    this.value = '';
                    this._entityId = '';
                }
            });
    }

    public onValueChange(value?: string) {
        if (!value) {
            this._entityValue = undefined;
            return;
        }
        // If no value field set, this means the value is the id
        if (!this.valueField) {
            this._entityId = value;
        }
        this._restService.getEntityClient(this.entity)
            .getById(this._entityId)
            .subscribe({
                next: (entity: T) => {
                    const entityValue = this.buildEntityValue(entity);
                    if (entityValue !== this.entityValue) {
                        this._entityValue = entityValue;
                    }
                }
            });
    }

    public selectedFromAutocomplete(value: T) {
        this._entityId = '' + getEntityMetadata(value)
            .$getPk();
        // Check if 'value' is an object and has the property specified by 'valueField'.
        if (value && this.valueField && typeof value === 'object' && value.hasOwnProperty(this.valueField)) {
            this.value = stringifyIfNotString(value[this.valueField]);
        } else {
            // Fallback to id if 'valueField' is not provided or doesn't exist on 'value'.
            this.value = stringifyIfNotString(this._entityId);
        }
    }

    public clearValue() {
        this.value = undefined;
    }

    private setEntity(val: Type<T>) {
        this._entity = val;
    }

    private setEntityValue(e: T | undefined) {
        if (!e) {
            return;
        }
        this._entityValue = this.buildEntityValue(e);
    }

    private buildEntityValue(e: T | undefined) {
        if (!e) {
            return '';
        }
        const keys = isArray(this.name) ? this.name : [this.name];
        let value = '';
        for (const k of keys) {
            value += `${e[k] || ''}${this.separator}`;
        }

        return value;
    }
}
