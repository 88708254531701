import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';

@Component({
    selector: 'iw-cell-active-period',
    templateUrl: './iw-cell-active-period.component.html',
    providers: [buildCustomValueProvider(IwCellActivePeriodComponent)],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IwCellActivePeriodComponent {

    @Input() public row?: { active?: boolean; rolesFromKeycloak?: string[] };

    @Input() public value = '';

    constructor() {
    }

    public getClass() {
        if (!this.row) {
            return;
        }
        if (this.row.active || !!this.row.rolesFromKeycloak?.length) {
            return 'active-period';
        }
    }
}
