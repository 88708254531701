<div [ngClass]="className"
     [pTooltip]="tooltip"
     [tooltipPosition]="tooltipPosition"
     class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label"
         class="iw-form-group-label"
         for="item"
         title="{{label | translate}}">{{ label | translate }} <span *ngIf="required"
                                                                     class="required-indicator">*</span>
    </div>
    <div class="input-container">
        <div *ngIf="type === 'text'"
             [attr.data-suffix]="suffix"
             class="p-inputgroup iw-input">
            <ng-container *ngIf="!!icon">
                <span class="p-inputgroup-addon"><span class="fa fa-{{icon}}"></span></span>
            </ng-container>
            <input #control
                   (blur)="onBlur()"
                   (focus)="onFocus()"
                   [(ngModel)]="value"
                   [class.active-input]="entityTextfield && !isDisabled"
                   [disabled]="isDisabled"
                   [mask]="mask"
                   [max]="maxValue"
                   [maxlength]="maxlength"
                   [min]="minValue"
                   [ngClass]="inputClass"
                   [pattern]="pattern"
                   [placeholder]="placeholder | translate"
                   [prefix]="prefix"
                   [readOnly]="isReadOnly"
                   [step]="stepValue"
                   [type]="type"
                   autocomplete="no"
                   pInputText
                   width="100%">
        </div>
        <div *ngIf="type === 'number'"
             class="p-inputgroup iw-input">
            <ng-container *ngIf="!!icon">
                <span class="p-inputgroup-addon"><span class="fa fa-{{icon}}"></span></span>
            </ng-container>
            <p-inputNumber #control
                           (onBlur)="onBlur()"
                           (onFocus)="onFocus()"
                           [(ngModel)]="value"
                           [class.active-input]="entityTextfield && !isDisabled"
                           [disabled]="isDisabled || isReadOnly"
                           [maxFractionDigits]="decimals"
                           [max]="maxValue"
                           [minFractionDigits]="decimals"
                           [min]="minValue"
                           [ngClass]="inputClass"
                           [pattern]="pattern"
                           [placeholder]="placeholder | translate"
                           [step]="stepValue"
                           [suffix]="suffix"
                           autocomplete="no"
                           locale="en-US"></p-inputNumber>
        </div>
        <div *ngIf="type === 'password'"
             class="p-inputgroup iw-input">
            <ng-container *ngIf="!!icon">
                <span class="p-inputgroup-addon"><span class="fa fa-{{icon}}"></span></span>
            </ng-container>
            <p-password (onBlur)="onBlur()"
                        (onFocus)="onFocus()"
                        [(ngModel)]="value"
                        [class.active-input]="entityTextfield && !isDisabled"
                        [disabled]="isDisabled || isReadOnly"
                        [ngClass]="inputClass"
                        [pattern]="pattern"
                        [placeholder]="placeholder | translate"
                        autocomplete="no"
            ></p-password>
        </div>
        <div #TxtwarningMessage
             *ngIf="isInvalid && warningMessage">
            <small class="iw-input col-xs-12 padding-left-0"
                   style="color: #ff0000; font-size:10px"> {{ warningMessage | translate }} </small>
        </div>
    </div>
</div>
