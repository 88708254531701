import {Component, Input, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';

@Component({
    selector: 'iw-enum-textfield',
    templateUrl: 'iw-enum-textfield.component.html',
    providers: [buildCustomValueProvider(IwEnumTextFieldComponent)],
    standalone: false
})
export class IwEnumTextFieldComponent<T> extends BaseFormControl<keyof T> implements OnDestroy {

    public formatedValue = '';

    @Input() public inputEnum?: T;

    private _subs = new Subject();

    constructor() {
        super();
        this.valueChange
            .pipe(takeUntil(this._subs))
            .subscribe(() => this.formatedValue = this.formatter(this.value));
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    @Input() public formatter: (e?: keyof T) => string = (e) => {
        if (this.inputEnum && e) {
            return 'combo_' + this.inputEnum[e];
        }
        return '';
    };
}
