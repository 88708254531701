import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ToastService} from '../../toast/toast.service';
import {ErrorBlock} from './error.model';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private _toast: ToastService) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(catchError(async (error: HttpErrorResponse) => {
                if (error.status === 417) {
                    let errorObj = error?.error;

                    if (error.error instanceof Blob && error.error.type === 'application/json') {
                        const textContent = await error.error.text();
                        errorObj = JSON.parse(textContent);
                    } else if (typeof error.error === 'string') {
                        errorObj = JSON.parse(error.error);
                    }

                    this._toast.businessExceptionMessage(errorObj);
                } else if (error.status !== 404) {
                    this._toast.error('system_error', this.buildErrorBlock(request, error), true);
                }
                throw error;
            }));
    }

    private buildErrorBlock(request: HttpRequest<any>, error: HttpErrorResponse): string {
        const errorBlock: ErrorBlock = {
            requestUrl: this.getRequestUrl(request),
            requestMethod: request.method,
            requestBody: request.body,
            responseStatus: error.status,
            errorPayload: error.error ?? error
        };
        return btoa(JSON.stringify(errorBlock));
    }

    private getRequestUrl(request: HttpRequest<any>) {
        return window.location.protocol + '//' + window.location.host + request.url;
    }
}
