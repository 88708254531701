import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {ProfileService} from '@shared/profile/profile.service';

@Directive({
    selector: '[iwAccessGroups]',
    standalone: false
})
export class AccessGroupsDirective {

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private _profileService: ProfileService) {
        this.viewContainer.createEmbeddedView(this.templateRef);
    }

    @Input() /**
     * @input groupIds Array of group ids to have access to the component
     */ public set iwAccessGroups(groupIds: string[]) {
        this.updateView(groupIds);
    }

    private updateView(groupIds: string[]) {
        if (this.checkPermission(groupIds)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    private checkPermission(groupsId: string[]): boolean {
        this.viewContainer.clear();
        return this._profileService.checkUserPermission(groupsId);
    }
}
