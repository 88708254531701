import {Component, Input} from '@angular/core';
import {Ppper} from '@sam-base/models/placement';

import {BaseEntityDropdown, buildCustomValueProvider} from '../../../base';
import {SortDirection} from '../../../models';

/**
 * Component created regarding SAM-4582
 */
@Component({
    selector: 'iw-periode-dropdown',
    templateUrl: '../dropdown-template.html',
    providers: [buildCustomValueProvider(IwPeriodeDropdownComponent)],
    standalone: false
})
export class IwPeriodeDropdownComponent extends BaseEntityDropdown<Ppper> {
    @Input() public width?: string | number;

    public getSortProperty(): keyof Ppper {
        return 'datedebut';
    }

    public getSorDirection(): SortDirection {
        return 'desc';
    }

    protected getEntityType() {
        return Ppper;
    }

    protected buildLabel(e: Ppper): string {
        return e.texte || '';
    }
}
