import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';
import {ViewContainerRefService} from '../../core/services/view-container-ref.service';
import {ModalTextAreaComponent} from '../modal/modal-text-area/modal-text-area.component';

@Component({
    selector: 'iw-textarea',
    templateUrl: './iw-textarea.component.html',
    providers: [buildCustomValueProvider(IwTextareaComponent)],
    standalone: false
})
export class IwTextareaComponent extends BaseFormControl<string> {
    @Input() public allowMaximize = true;

    @Input() public width?: number;

    @Input() public maxLength = 10000;

    @Input() public warningMessage?: string;

    // Applies the 'active-input' class that changes field background color
    @Input() public multiselTextarea = false;

    @Input() public disabledInput = false;

    /** Hides the actual value and renders a valueToShow if passed */
    @Input() public valueMask = false;

    @Output() public doubleClick = new EventEmitter<void>();

    constructor(private _viewHostRef: ViewContainerRefService, private elRef: ElementRef) {
        super();
    }

    private _height?: string | number;

    @Input()
    public set height(v: undefined | string | number) {
        this._height = typeof v === 'number' ? v + 'px' : v;
    }

    private _valueToShow = '';

    public get valueToShow() {
        return this._valueToShow;
    }

    @Input()
    public set valueToShow(v: string) {
        this.valueMask = true;
        this._valueToShow = v;
    }

    public get styleValue() {
        return {
            height: this._height, display: this.valueMask ? 'none' : 'inline-block'
        };
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public onDoubleClick() {
        this.doubleClick.emit();

        if (!this.allowMaximize || this.isDisabled) {
            return;
        }

        this._viewHostRef.loadComponent({
            type: ModalTextAreaComponent, data: {
                value: this.value, onOk: (val: any) => this.value = val, state: this
            }
        });
    }
}
