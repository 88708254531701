import {Component, ElementRef, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Gecligrp} from '@sam-base/models/invoices/gecligrp';

@Component({
    selector: 'iw-gecligrp-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwGecligrpDropdownComponent)],
    standalone: false
})
export class IwGecligrpDropdownComponent extends BaseEntityDropdown<Gecligrp> {
    @Input() public width?: string | number;

    @Input() public warningMessage?: string;

    constructor(private elRef: ElementRef) {
        super();
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    protected getEntityType() {
        return Gecligrp;
    }

    protected buildLabel(e: Gecligrp): string {
        return e.libelle || '';
    }
}
