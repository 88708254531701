import {AfterViewInit, Component, EventEmitter, Input, Output, Type, ViewChild} from '@angular/core';
import {ToastService} from '@app/sam-base/core/toast';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {EntityMap, EntityStatusMap} from '@sam-base/components/iw-grid-column-config/entity-status-map';
import {lastValueFrom, Subscription} from 'rxjs';

import {loadColumnMetadataFromType} from '../../core/column';
import {loadMenuItems} from '../../core/context-menu/helpers';
import {ContextMenuEvent} from '../../core/context-menu/models';
import {FormHandlerService} from '../../core/form-handler';
import {GridProfile} from '../../core/grid-profile/models';
import {getEntityMetadata, getEntityName, isRestEntity, RestApiService, RestQueryParam} from '../../core/rest-api';
import {RestEntityQuery} from '../../core/rest-api/entity/res-entity-query';
import {convertToQueryOperation, RestQueryOperation} from '../../core/rest-api/models/rest-query.model';
import {IwActionService} from '../../core/store';
import {
    ContextMenuVisibleMode,
    IwGridColumn,
    IwGridOptions,
    MenuItem,
    RowClickEvent,
    SortDirection
} from '../../models';
import {IwContextMenuComponent} from '../iw-context-menu/iw-context-menu.component';
import {IwTableComponent} from '../iw-table/iw-table.component';

export interface SortInfo<T> {
    sortProp: keyof T;
    sortDir: 'asc' | 'desc';
}

/**
 * Grid for an entity that based on the [TYPE] automatically
 * defines the columns and loads the required items. It suports a
 * list of queries to filter results.
 *
 * The grid also has a subscription to the store and automatically reloads
 * it data, based on the entity store activity
 */
@Component({
    selector: 'iw-rest-grid',
    templateUrl: './iw-rest-grid.component.html',
    standalone: false
})
export class IwRestGridComponent<T> implements AfterViewInit {

    @Input() public disableContextMenu = false;
    /**  Forces a default sort column for the grid.
     *  A defaultSortDir input should be included if not ASC
     */
    @Input() public defaultSort?: keyof T;
    @Input() public defaultSortDir: 'asc' | 'desc' = 'asc';
    //    That matches the criteria
    @Input() public selectedData?: {
        name: Extract<keyof T, string | number> | undefined; value: any;
    };
    @Input() public showGridDetails = true;
    @Output() public columnsChange = new EventEmitter<number>();
    @Output() public selected = new EventEmitter<T[]>();
    @Output() public contextMenuClick = new EventEmitter<ContextMenuEvent<T>>();
    @Output() public rowDoubleClick = new EventEmitter<RowClickEvent<T>>();
    @Output() public sortInfo = new EventEmitter<SortInfo<T>>();
    @Input() public isDisabled = false;
    /** Enables raw table mode - hide headers and cell lines */
    @Input() public rawTableMode = false;
    /** If true, double click will open entity form */
    @Input() public autoFormClick = true;
    @Input() public hasFilter = false;
    @Input() public hasSortIcon = true;

    // Row with the given key and value to re-select
    // If the given key and value is not unique it will select the first
    @Input() public hasGridColumnMenu = true;
    @Input() public defaultSelected = false;
    @Input() public forceSelected = false;
    @Input() public innerWidth: 'auto' | string = 'auto';
    @Input() public scrollHeight?: string;
    @Input() public currentRows = 0;
    @Input() public selectMode: 'single' | 'multi' | 'checkbox' = 'single';
    @Input() public virtualScroll = false;
    @Input() public virtualScrollDelay = 250;
    @Input() public virtualScrollItemSize = 22;

    public contextMenuData?: ContextMenuEvent<T>;
    public contextMenuItems: MenuItem[] = [];
    @Output() public dataChanged = new EventEmitter<T[]>();
    @Output() public rowClicked = new EventEmitter<T>();
    public tableOptions: IwGridOptions<T> = {
        columns: [],
        data: [],
        lazyLoad: true,
        lazyLoadScroll: () => this.loadData(),
        lazyLoadReload: () => this.resetData(true)
    };
    @ViewChild('table', {static: false}) public grid?: IwTableComponent<T>;
    @ViewChild('menu', {static: false}) public menu?: IwContextMenuComponent;
    // Load when change another entity
    private _pk?: Extract<keyof T, string | number>; // Primary key
    private _ready = false;
    private _scrollClient?: RestEntityQuery<T>;
    private _subscription?: Subscription;

    constructor(private readonly _restService: RestApiService, private readonly _action: IwActionService<T>,
                private readonly _formService: FormHandlerService<string>,
                private readonly _toastService: ToastService) {
    }

    public get columns() {
        return this.tableOptions.columns;
    }

    public get entityPk() {
        return this._pk || 'id';
    }

    /**
     *  Forces the next sort of the table by the given property
     */
    @Input()
    public set sortProp(prop: string) {
        if (this.grid) {
            this.grid.sortProp = prop;
        }
    }

    /**
     *  Forces the next sort direction of the table by the given direction
     */
    @Input()
    public set sortDir(dir: SortDirection) {
        if (this.grid) {
            this.grid.sortDir = dir;
        }
    }

    private _customColumns: IwGridColumn<T>[] = [];

    @Input()
    public set customColumns(value: IwGridColumn<T>[]) {
        this.setCustomColumns(value);
    }

    private _type?: Type<T>; // Type of entity to load

    public get type() {
        return this._type;
    }

    @Input()
    public set type(v: Type<T> | undefined) {
        this.setType(v);
    }

    private _refreshType?: Type<any>; // Type of entity to

    public get refreshType() {
        return this._refreshType;
    }

    @Input()
    public set refreshType(v: Type<any> | undefined) {
        this.setRefreshType(v);
    }

    private _itemsPerPage = 30;

    public get itemsPerPage() {
        return this._itemsPerPage;
    }

    @Input()
    public set itemsPerPage(v: number) {
        this.setPageSize(v);
    }

    private _totalPages = 1; // Total number of pages

    public get totalPages() {
        return this._totalPages;
    }

    private _queryStatements: RestQueryParam<T, any>[] = [];

    public get queryStatements() {
        return this._queryStatements;
    }

    @Input()
    public set queryStatements(v: RestQueryParam<T, any>[]) {
        this.setQueryStatement(v);
    }

    public ngAfterViewInit() {
        this._ready = true;
        this.refresh();
    }

    public loadGridProfile(): GridProfile<T> {
        if (this.grid) {
            const p = this.grid.loadGridProfile();
            if (this._type) {
                p.entity = getEntityName(this._type) || '';
            }
            return p;
        }

        throw new Error('Grid not avaible!');
    }

    // eslint-disable-next-line complexity
    public async onContextMenu(event: ContextMenuEvent<T>) {
        this.contextMenuClick.emit(event);

        if (this._type && !this.disableContextMenu) {
            this.contextMenuData = event;
            const prop = event.column.prop || '';
            const currentVisibleMode: ContextMenuVisibleMode = event.selected.length > 1 ? 'multiple' : 'single';
            this.contextMenuItems = loadMenuItems(this._type, prop.toString());
            this.contextMenuItems = this.contextMenuItems
                .filter((e) => e.contextMenuVisibleMode === currentVisibleMode || e.contextMenuVisibleMode === 'all');
            if (this.contextMenuItems.length) {
                await this.showContextMenu(event.event);
            }
        }
    }

    public addParentEvents() {
        if (!this.grid) {
            return;
        }
        this.grid.registerEventListeners();
    }

    public async onRowDoubleClick(event: RowClickEvent<T>) {
        if (this.autoFormClick && this._type) {
            const item = new this._type(event.row);
            if (isRestEntity(item)) {
                this._formService.showFormDialog(item.$entity, undefined, e => ({
                    ...e,
                    isNew: false,
                    entityId: item.$getPk()
                }));
            }
        }
        this.rowDoubleClick.emit(event);
    }

    public onApplyRowFilter(event: IwGridColumn<T>) {
        const filterQueryIndex = this.findRowFilterInQuery(event, this.queryStatements);
        if (filterQueryIndex !== -1) {
            this.updateFilter(event, filterQueryIndex, this.queryStatements);
        } else {
            this.addFilter(event);
        }
    }

    public onSelection(data?: any) {
        this.hideContextMenu();
        if (typeof data !== 'undefined' && data.length) {
            this.selected.emit(data);
            this.selectedData = {
                name: this._pk,
                value: data[0][this._pk]
            };
        } else {
            this.selected.emit(data);
            this.selectedData = undefined;
        }
    }

    public selectIndex(index: number) {
        if (this.grid) {
            this.grid.selectIndex(index);
        }
    }

    public clearSelection() {
        if (this.grid) {
            this.grid.clearSelection();
        }
    }

    /** Reload table data */
    public async refresh() {
        if (this.grid) {
            await this.grid.refresh();
            this.selected.emit([]);
        }
    }

    /** Remove all data and reload */
    public async reset() {
        this._scrollClient = undefined;
        if (this.grid) {
            await this.grid.reset();
            await this.grid.refresh();
        }
    }

    public onDataChanged(data?: any) {
        this.dataChanged.emit(data);
    }

    onRowClick(row: T) {
        this.rowClicked.emit(row);
    }

    private findRowFilterInQuery(filter: IwGridColumn<T>, queryStatements: RestQueryParam<T, any>[]): number {
        return queryStatements.findIndex((statement: RestQueryParam<T, any>) => statement.prop === filter.prop);
    }

    private getSearchEntityEnumKey(event: IwGridColumn<T>): RestQueryOperation | undefined {
        if (!event.filterOperator) {
            return undefined;
        }
        const newOperation = convertToQueryOperation(event.filterOperator);
        if (!newOperation) {
            this._toastService.warning('cannot_filter_column');
        }
        return newOperation;
    }

    private updateFilter(event: IwGridColumn<T>, queryIndex: number, queryStatements: RestQueryParam<T, any>[]) {
        const statements: RestQueryParam<T, any>[] = getDeepCopy(queryStatements);
        const newOperation = this.getSearchEntityEnumKey(event);
        if (this.isFilterQueryEmpty(event)) {
            this.queryStatements.splice(queryIndex, 1);
        } else if (newOperation) {
            statements[queryIndex].operation = newOperation;
            statements[queryIndex].value = event.type === 'enum' && event.valueFormat ? EntityStatusMap
                    .getSelectedValues(event.valueFormat as keyof EntityMap, event.statusQuery || [])
                : event.filterQuery;
            this.queryStatements = statements;
        }
    }

    private addFilter(event: IwGridColumn<T>) {
        const newOperation = this.getSearchEntityEnumKey(event);
        if (newOperation && event.prop && !this.isFilterQueryEmpty(event)) {
            const statement: RestQueryParam<T, any> = {
                operation: newOperation,
                value: event.type === 'enum' && event.valueFormat ? EntityStatusMap
                        .getSelectedValues(event.valueFormat as keyof EntityMap, event.statusQuery || [])
                    : event.filterQuery,
                prop: event.prop
            };
            this.queryStatements.push(statement);
        }
    }

    private isFilterQueryEmpty(event: IwGridColumn<T>) {
        if (event.type === 'enum') {
            return event.statusQuery ? event.statusQuery.length === 0 : false;
        }
        return event.filterQuery === '';
    }

    private setQueryStatement(q?: RestQueryParam<T, any>[]) {
        if (!q) {
            this._queryStatements = [];
            return;
        }

        this._queryStatements = q;
        this.refresh();
    }

    /** Request more data to endpoint */
    private async loadData(): Promise<T[]> {
        if (!this._ready) {
            return [];
        }

        if (this._scrollClient) {
            const data = await lastValueFrom(this._scrollClient.scroll());
            this.currentRows = data.length ? (this.currentRows === 0 ? this._itemsPerPage + data.length : this.currentRows + data.length) : this.currentRows;
            return data;
        } else {
            return this.resetData();
        }
    }

    /** Reset scroll params */
    // eslint-disable-next-line complexity
    private async resetData(keepResults = false): Promise<T[]> {
        if (!keepResults) {
            this.currentRows = 0;
        }

        if (!this._ready) {
            return [];
        }

        if (this._scrollClient) {
            this._scrollClient = undefined;
        }

        if (this._type) {
            const p = this.loadGridProfile();
            this._scrollClient = this._restService
                .getEntityQuery(this._type, ...this._queryStatements);
            if (this.currentRows !== 0 && this.selectedData) {
                this._scrollClient.setPageSize(this.currentRows);
            } else {
                this._scrollClient.setPageSize(this._itemsPerPage);
            }

            // Set sort direction
            if (this.defaultSort && this.defaultSortDir) {
                this._scrollClient.setSort(this.defaultSortDir, this.defaultSort);
            } else if (p.columns.sortBy && p.columns.sordDir) {
                this._scrollClient.setSort(p.columns.sordDir, p.columns.sortBy);
                this.sortInfo.emit({
                    sortProp: p.columns.sortBy,
                    sortDir: p.columns.sordDir
                });
            }

            if (!keepResults || !this.selectedData) {
                this.deselectRows();
            }
            return (await lastValueFrom(this._scrollClient.scroll())).filter((val: any) => !val['dateDelet']);
        }

        this.deselectRows();
        return [];
    }

    private deselectRows() {
        if (this.grid && this.selectedData) {
            this.grid.deselectAll();
        }
    }

    /**
     * Defines the number of items per scroll
     *
     * @param pageSize items number
     */
    private async setPageSize(pageSize: number) {
        if (!pageSize || pageSize === this.itemsPerPage) {
            return;
        }

        this._itemsPerPage = pageSize;
        this.resetData();
    }

    private async showContextMenu(event: MouseEvent) {
        if (this.menu) {
            await this.menu.show(event);
        }
    }

    private async hideContextMenu() {
        if (this.menu) {
            await this.menu.hide();
        }
    }

    private async setType(type?: Type<T>) {
        if (type && type !== this._type) {
            this._type = type;
            this._pk = <any>getEntityMetadata(type).$pk;
            this.tableOptions.data = [];
            this.buildColumnList(type);
            this.updateEntitySubscription();
            await this.reset();
        }
    }

    private async setRefreshType(type?: Type<any>) {
        this._refreshType = type;
        this.updateEntitySubscription();
    }

    private buildColumnList(type: Type<T>) {
        const columns = this._customColumns.length > 0 ? this._customColumns : loadColumnMetadataFromType(type);
        this.tableOptions.columns = columns;
    }

    /** Add store subscription to reload when a value changes */
    private updateEntitySubscription() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }

        const t = this._refreshType || this._type;

        if (t) {
            this._subscription = this._action.entityChange(t)
                .subscribe(() => {
                    this.refresh();
                });
        }
    }

    /** Refreshes the table with the new custom columns */
    private setCustomColumns(value: IwGridColumn<T>[]) {
        this._customColumns = value;
        if (this._type) {
            this.buildColumnList(this._type);
            this.refresh();
        }
    }
}
