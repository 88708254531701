import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Type} from '@angular/core';
import {getDeepCopy} from '@sam-base/helpers/objects-parser';
import {AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent} from 'primeng/autocomplete';
import {Observable, of, Subject} from 'rxjs';
import {debounceTime, filter, map, takeUntil} from 'rxjs/operators';

import {BaseFormControl} from '../../base/base-form-control';
import {RestApiService, RestQueryParam} from '../../core/rest-api';

interface NewElem {
    value: string;
    type: 'new'
}

function unifyString(e?: string): string {
    if (!e || typeof e !== 'string') {
        return '';
    }

    return e.toLowerCase();
}

@Component({
    selector: 'iw-multi-select',
    templateUrl: './iw-multi-select.component.html',
    standalone: false
})
export class IwMultiSelectComponent<T, K> extends BaseFormControl<(K | T | NewElem)[]> implements OnInit, OnDestroy {

    @Input() public sugestionFilters: RestQueryParam<T, any>[] = [];
    @Input() public selected: (K & { isNew: boolean })[] = [];
    /** Options to use if no entity is defined */
    @Input() public defaultOptions: T[] = [];
    @Input() public allowNewEntries = true;
    @Output() public search = new EventEmitter<string>();
    @Output() public keyUp = new EventEmitter<KeyboardEvent>();
    @Output() public selectedFromList = new EventEmitter<(K | T | NewElem)[]>();
    public dropdownValues: T[] = [];
    public currentValue: (K | T | NewElem)[] = [];
    private _subs = new Subject();

    constructor(private readonly _restService: RestApiService) {
        super();
    }

    private _sourceType?: Type<T>;

    public get sourceType() {
        return this._sourceType;
    }

    /** Source entity to load data sugestions from RestAPI */
    @Input()
    public set sourceType(v: Type<T> | undefined) {
        this.setSource(v);
    }

    private _targetType?: Type<K>;

    public get targetType() {
        return this._targetType;
    }

    /** Entity to save results */
    @Input()
    public set targetType(v: Type<K> | undefined) {
        this.setTarget(v);
    }

    public ngOnInit() {
        this.search.pipe(takeUntil(this._subs), debounceTime(500))
            .subscribe(q => this.querySuggestions(q));

        this.keyUp.pipe(takeUntil(this._subs), debounceTime(500), filter(e => e.key === 'Enter'))
            .subscribe((e) => this.onEnterPress((e.target as HTMLInputElement).value));

        this.valueChange.pipe(takeUntil(this._subs))
            .subscribe((val) => this.currentValue = getDeepCopy(val) ?? []);
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    @Input() public textSource: (e: T) => string = (e: T) => typeof e === 'string' ? e : 'source';

    @Input() public textTarget: (e: K) => string = (e: K) => typeof e === 'string' ? e : 'target';

    /** Method to check if element <T> was already selected */
    @Input() public matchValues: (values: any[], v: T) => boolean = () => true;

    public renderText: (e: K | any) => string = (e: any) => {
        if (e && e.type === 'new') {
            return e.value;
        }

        return this.textTarget(e);
    };

    public onQueryChange({query}: AutoCompleteCompleteEvent) {
        this.search.emit(query);
    }

    public onKeyUp(e: KeyboardEvent) {
        this.keyUp.emit(e);
    }

    public removeValue(event: AutoCompleteSelectEvent) {
        if (!this.value) {
            return;
        }

        const placeHoldervalue: (K | T | NewElem)[] = getDeepCopy(this.value);

        const index = placeHoldervalue
            .findIndex(currValue => JSON.stringify(currValue) === JSON.stringify(event.value));

        if (index !== -1) {
            placeHoldervalue.splice(index, 1);
            this.selectedFromList.emit(placeHoldervalue);
        }
    }

    public addValue(event: AutoCompleteSelectEvent) {
        let placeHoldervalue = getDeepCopy(this.value);
        if (!placeHoldervalue) {
            placeHoldervalue = [event.value];
        } else {
            placeHoldervalue.push(event.value);
        }

        this.selectedFromList.emit(placeHoldervalue);
    }

    private onEnterPress(value: string) {
        if (this.control) {
            if (this.allowNewEntries && value) {
                this.addNewEntry(value);
            }
        }
    }

    private addNewEntry(value: string) {
        const elem: NewElem = {
            value: value + '',
            type: 'new'
        };
        let placeHolderValue = getDeepCopy(this.value);
        if (!placeHolderValue) {
            placeHolderValue = [elem];
        } else {
            placeHolderValue.push(elem);
        }

        this.selectedFromList.emit(placeHolderValue);
        this.clearValue();
    }

    private clearValue() {
        if (this.control) {
            // There are a Error in base component, control has the wrong type
            const elem: { value: string } | undefined = (<AutoComplete><any>this.control).multiInputEl?.nativeElement;
            if (elem) {
                elem.value = '';
            }
        }
    }

    private setSource(t?: Type<T>) {
        this._sourceType = t;
        if (t) {
            this.reloadSugestions();
        }
    }

    private setTarget(t?: Type<K>) {
        this._targetType = t;
    }

    private querySuggestions(query?: string) {
        if (!query) {
            this.reloadSugestions();
            return;
        }

        this.getSugestions()
            .pipe(map(e => e.filter(v => this.compare(query, v)))).subscribe(values => {
            this.dropdownValues = values;
        });
    }

    private getSugestionsRequest(): Observable<T[]> {
        const values = this.value || [];
        if (this._sourceType) {
            const type = this._sourceType;
            if (this.sugestionFilters.length) {
                const queryService = this._restService.getEntityQuery(type, ...this.sugestionFilters);
                queryService.setPageSize(1000); // Max possible results
                return queryService.scroll()
                    .pipe(map(e => e.filter(v => !this.matchValues(values, v))));
            } else {
                return this._restService.getEntityClient(type)
                    .getFilterList({
                        page: '0',
                        size: '1000',
                        sort: ''
                    })
                    .pipe(map(e => e.filter(v => !this.matchValues(values, v))));
            }
        }

        return of([]);
    }

    private getDefaultSugestions() {
        const values = this.value || [];
        return of(this.defaultOptions)
            .pipe(map(e => e.filter(v => !this.matchValues(values, v))));
    }

    private getSugestions() {
        return this._sourceType ? this.getSugestionsRequest() : this.getDefaultSugestions();
    }

    private reloadSugestions() {
        this.getSugestions().subscribe(values => {
            this.dropdownValues = values;
        });

    }

    /** Compare if query match results */
    private compare(query: string, v: T): boolean {
        const txt = unifyString(this.textSource(v));
        return txt.indexOf(unifyString(query)) !== -1;
    }
}
