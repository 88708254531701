import {Component, EventEmitter, Input, Output} from '@angular/core';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';

@Component({
    selector: 'iw-checkbox',
    templateUrl: './iw-checkbox.component.html',
    providers: [buildCustomValueProvider(IwCheckboxComponent)],
    standalone: false
})
export class IwCheckboxComponent extends BaseFormControl<boolean> {

    @Input() public required: boolean = false;
    
    @Input() public trueValue: any = true;

    @Input() public falseValue: any = false;

    @Output() public valueSelected = new EventEmitter<void>();

}
