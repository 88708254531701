import {Component, Type} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SortDirection} from '@app/sam-base/models';
import {Ppcctgrp} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-cctgroup-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwCctgroupDropdownComponent)],
    standalone: false
})
export class IwCctgroupDropdownComponent extends BaseEntityDropdown<Ppcctgrp> {

    public selectedValue = undefined;

    constructor() {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    public getSortProperty(): keyof Ppcctgrp {
        return 'cctgrpId';
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType(): Type<Ppcctgrp> {
        return Ppcctgrp;
    }

    protected buildLabel(e: Ppcctgrp) {
        return `${e.cctgrpId} | ${e.cctgrpLib}`;
    }
}
