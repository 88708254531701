import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SortDirection} from '@app/sam-base/models';
import {Sasd4emitype} from '@app/sam-base/models/common/sasd4emitype';

@Component({
    selector: 'iw-sasd4emitype-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwSasd4emitypeDropdownComponent)],
    standalone: false
})
export class IwSasd4emitypeDropdownComponent extends BaseEntityDropdown<Sasd4emitype> {

    @Input() public width?: string | number;

    public selectedValue = undefined;

    constructor() {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    public getSortProperty(): keyof Sasd4emitype {
        return 'emiType';
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType() {
        return Sasd4emitype;
    }

    protected buildLabel(e: Sasd4emitype): string {
        return e.titre ?? '';
    }

}
