import {Component, EventEmitter, OnInit} from '@angular/core';
import {HtmlElementStatus, IwGridColumn, ModalComponent} from '@app/sam-base/models';

import {ModalRapportDataOptions, SagsTitre, SalaryContent} from './modal-rapport-salary.options';

@Component({
    templateUrl: './modal-rapport-salary.component.html', styleUrls: ['./modal-rapport-salary.component.scss'],
    standalone: false
})
export class ModalRapportSalaryComponent implements ModalComponent<void, ModalRapportDataOptions>, OnInit {

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public cancelShow: HtmlElementStatus = {
        enabled: true, value: 'Cancel', visible: false
    };
    public value = '0';
    // Array where we store all saes rows by gs_ctype except type 6
    public types: any = [];
    // Array where we store all saep rows
    public sapes: any = [];
    // Array where we store gs_ctype == 6 (all totals)
    public type6: any = [];
    public titles: any = SagsTitre;

    /** Grid columns */
    public tableColumn: IwGridColumn<any>[] = this.getColumns();

    public tableSaepColumn: IwGridColumn<any>[] = this.getSaepColumns();

    public tableColumnType48D: IwGridColumn<any>[] = this.getColumnsType48D();

    private _options!: ModalRapportDataOptions;
    private _ok?: (result: void) => void;
    private _cancel?: (error?: any) => void;

    constructor() {
    }

    public get title() {
        return this._options.title;
    }

    public get confirmMessage(): string {
        return this.confirmMessage;
    }

    public get width() {
        return this._options.width;
    }

    public get height() {
        return this._options.height;
    }

    public get obj(): any {
        return this._options.obj.salary;
    }

    public ngOnInit(): void {
        this.buildTypes();
    }

    /**
     * Function to get the data from the types array for the given gs_ctype
     *
     * @param id given gs_ctype
     */
    public getTableVal(id: string) {
        const val = this.types.filter((elem: any) => elem.type === id);
        return val.length ? val[0].saes : undefined;
    }

    /**
     * Function to check if the given gs_ctype is present in
     * the types array to show/hide the group on the display
     *
     * @param id given gs_ctype
     */
    public existsKey(id: string): boolean {
        return this.types
            .findIndex((type: SalaryContent) => type.type === id) !== -1;
    }

    /**
     * Function to check if the given total es_id is present on the type6 array
     *
     * @param type given es_id
     */
    public exists(type: string): boolean {
        if (!this.type6) {
            return false;
        }
        return this.type6.some((el: any) => el.gs_id === type);
    }

    /**
     * Function to get the value from the type6 array for the given es_id
     *
     * @param type given es_id
     */
    public getValue(type: string): number {
        if (!this.type6) {
            return 0;
        }
        return this.type6.filter((el: any) => el.gs_id === type)[0].montant;
    }

    public getTitleByValue(value: string) {
        const keys = Object.keys(this.titles)
            .filter(x => this.titles[x] === value);
        return keys.length > 0 ? keys[0] : undefined;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok();
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public async setData(data: ModalRapportDataOptions): Promise<void> {
        this._options = data;

        if (!this._options.showCancel) {
            this.cancelShow = {
                ...this.cancelShow, enabled: false, visible: false
            };
        }
    }

    public registerOk(action: (result: void) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    /**
     * Function to mask values to have 2 decimals on the display
     *
     * @param value given value to mask
     */
    public maskMnt(value: number): string {
        const val = value.toString();
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2
        })
            .format(parseFloat(val));
    }

    private getColumns(): IwGridColumn<any>[] {
        return [{
            prop: 'gs_id', name: 'GS', index: 0
        }, {
            prop: 'texte', name: 'Keyword', index: 1
        }, {
            prop: 'pr_base', name: 'base', index: 2
        }, {
            prop: 'pr_taux', name: 'taux', index: 3
        }, {
            prop: 'montant', name: 'montant', index: 4
        }];
    }

    private getColumnsType48D(): IwGridColumn<any>[] {
        return [{
            prop: 'gs_id', name: 'GS', index: 0
        }, {
            prop: 'texte', name: 'Keyword', index: 1
        }, {
            prop: 'base', name: 'base', index: 2
        }, {
            prop: 'taux', name: 'taux', index: 3
        }, {
            prop: 'montant', name: 'montant', index: 4
        }];
    }

    private buildTypes(): void {
        this.obj['saes']?.forEach((elem: any) => {
            if (elem.gs_ctype !== '6') {
                const index = this.types
                    .findIndex((type: SalaryContent) => type.type === elem.gs_ctype);
                if (index === -1) {
                    // Creates the first object entry in types array
                    this.types.push({type: elem.gs_ctype, saes: [elem]});
                } else {
                    // Pushes the element if the type object already exists
                    this.types[index].saes.push(elem);
                }
            } else {
                // Pushes to the types6 array (totals)
                this.type6.push(elem);
            }
        });
    }

    private getSaepColumns(): IwGridColumn<any>[] {
        return [{
            prop: 'ep_id', name: 'id', index: 0
        }, {
            prop: 'texte', name: 'desig', index: 1
        }, {
            prop: 'montant', name: 'montant', index: 4
        }];
    }
}
