import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ListItem} from './list-item.model';

@Component({
    selector: 'iw-list-box', templateUrl: './list-box.component.html', styleUrls: ['./list-box.component.scss'],
    standalone: false
})
export class ListBoxComponent {

    @Input() public listFocus = false;
    @Input() public selectedOptions: ListItem<string>[] = [];
    @Input() public height?: string;
    @Output() public selectedOptionsChange = new EventEmitter<ListItem<string>[]>();
    @ViewChild('listBox', {static: true}) public listBox?: ElementRef;
    private _options: ListItem<string>[] = [];
    private _isDisabled = false;

    public get optionsList() {
        return this._options;
    }

    @Input()
    public set optionsList(v: ListItem<string>[]) {
        this._options = v;
        this.setDefaultOption();
    }

    public get disabled() {
        return this._isDisabled;
    }

    @Input()
    public set disabled(v: boolean) {
        this._isDisabled = v;
    }

    public setDefaultOption() {
        if (this.optionsList.length) {
            this.selectedOptions = [this.optionsList[0]];
            this.updateList();
            if (this.listBox && this.listFocus) {
                const listBoxHtmlElement: HTMLElement = this.listBox.nativeElement;
                listBoxHtmlElement.focus();
            }
        }
    }

    public updateList() {
        setTimeout(() => {
            this.selectedOptionsChange.emit(this.selectedOptions);
        }, 0);
    }
}
