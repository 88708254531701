import {Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwPlanningTooltipComponent} from '@app/sam-base/components/iw-planning-tooltip/iw-planning-tooltip.component';
import {FormHandlerService, IwEventHubService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {Ppemp} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PlacementForm} from '../../placement.forms';
import {Planning} from './planning-models';

@Component({
    selector: 'iw-planning', templateUrl: './planning.component.html',
    standalone: false
})
export class PlanningComponent extends BaseFormComponent<Planning> implements OnInit, OnDestroy {

    public mode: 'weekly' | 'monthly' = 'weekly';

    @Input() public startDate = IwDateHelper.dateFormatFromString(undefined, 'MM-DD-YYYY');

    @Input() public result = [];

    @Output() public closeDialogEvent = new EventEmitter();

    public get currentMonth() {
        return IwDateHelper.dateMonthString(this.startDate);
    }

    public get currentYear() {
        return IwDateHelper.dateYear(this.startDate);
    }

    public get currentWeek() {
        return `${this._translate.instant('week')} ${IwDateHelper
            .dateWeek(this.startDate)}`;
    }

    public get employeeName() {
        return this.result && this.result.length === 1 ? this._translate.instant('planning') + ' ' + ((this.result[0] as Ppemp).nom ?? this._translate.instant('undefined')) : this._translate.instant('planning');
    }

    private overlayRef?: OverlayRef;

    private _subscripts = new Subject();

    constructor(private readonly _translate: TranslateService, private readonly _events: IwEventHubService<string>, private readonly _formHandler: FormHandlerService<PlacementForm>, private overlayPositionBuilder: OverlayPositionBuilder, private overlay: Overlay) {
        super();
    }

    public ngOnInit() {
        this._events.forType('schedule_add')
            .pipe(takeUntil(this._subscripts))
            .subscribe((e) => {
                this.updateMonth();
            });
        this.mode = this.result.length > 1 ? 'monthly' : 'weekly';
    }

    public ngOnDestroy() {
        this._subscripts.next(undefined);
        this._subscripts.complete();
        super.ngOnDestroy();
    }

    public closeDialog() {
        this.closeDialogEvent.emit();
    }

    public setStartDatePlanning(date: string) {
        this.startDate = date;
    }

    public mouseOver(ev: MouseEvent) {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(<HTMLElement>ev.currentTarget)
            .withPositions([{
                originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top', offsetX: -20
            }]);
        this.overlayRef = this.overlay.create({positionStrategy});
        const tooltipPortal = new ComponentPortal(IwPlanningTooltipComponent);
        if (!this.overlayRef) {
            return;
        }
        this.overlayRef.attach(tooltipPortal);
    }

    public mouseOut(ev: MouseEvent) {
        if (!this.overlayRef) {
            return;
        }
        this.overlayRef.detach();
        this.overlayRef.dispose();
    }

    public openSE() {
        this._formHandler.showFormDialog(PlacementForm.ScheduleEvent, {
            height: '250px', parentId: (this.result[0] as Ppemp).empId, parentEntity: 'ppemp', isAvailability: false
        });
    }

    public triggerNavigation(month: number) {
        if (this.mode === 'weekly') {
            const date = new Date(this.startDate);
            date.setDate(date.getDate() + (month * 7));
            this.setStartDatePlanning(IwDateHelper.dateFormatFromString(date, 'MM-DD-YYYY'));
        } else {
            const date = new Date(this.startDate);
            date.setMonth(date.getMonth() + month);
            this.setStartDatePlanning(IwDateHelper.dateFormatFromString(date, 'MM-DD-YYYY'));
        }
    }

    public triggerMode(mode: ('weekly' | 'monthly')) {
        this.mode = mode;
    }

    private updateMonth() {
        this.setStartDatePlanning(IwDateHelper.dateFormatFromString(IwDateHelper.addToDate(1, 'day', this.startDate), 'MM-DD-YYYY'));
    }
}
