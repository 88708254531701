import {Component, ElementRef, Input} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Ppcha} from '@app/sam-base/models/placement';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {map} from 'rxjs/operators';

import {IwTextfieldListComponent} from './iw-textfield-list.component';

@Component({
    templateUrl: './iw-textfield-list.component.html',
    selector: 'iw-textfield-chantiers',
    providers: [buildCustomValueProvider(IwTextfieldChantiersComponent)],
    standalone: false
})
export class IwTextfieldChantiersComponent extends IwTextfieldListComponent<Ppcha> {
    public readonly listOption: ModalSelectTableOption<Ppcha, string>;

    @Input() public cliId?: string;

    constructor(modalService: ModalService, restService: RestApiService, elRef: ElementRef) {
        super(modalService, elRef, restService);
        this.propsToFilter = ['chantier'];
        this.listOption = {
            columns: [{prop: 'chantier'}],
            map: (e: Ppcha) => e.chantier || '',
            selection: 'single',
            fetchAction: () => restService
                .getEntityClient(Ppcha)
                .getRefData()
                .pipe(map(e => e.filter(v => v.cliId === this.cliId)
                    .sort((a, b) => (a.chantier || '') < (b.chantier || '') ? -1 : 1)))
        };
    }
}
