import {Component, ElementRef, EventEmitter, Output} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services';
import {FofouService} from '@app/sam-base/core/services/fofou.service';
import {Fofou} from '@app/sam-base/models/fornisseurs/fofou';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {map} from 'rxjs/operators';
import {IwTextfieldListComponent} from './iw-textfield-list.component';

@Component({
    templateUrl: './iw-textfield-list.component.html',
    selector: 'iw-textfield-fofou',
    providers: [buildCustomValueProvider(IwTextfieldFofouComponent)],
    standalone: false
})
export class IwTextfieldFofouComponent extends IwTextfieldListComponent<Fofou> {
    public readonly listOption: ModalSelectTableOption<Fofou, string>;

    @Output() public fofouChanged = new EventEmitter<Fofou>();

    constructor(modalService: ModalService, restService: RestApiService, elRef: ElementRef, private readonly _fofouService: FofouService) {
        super(modalService, elRef, restService);
        this.propsToFilter = ['fouId'];
        this.listOption = {
            columns: [{prop: 'fouId', name: 'no', width: 50}, {prop: 'nom'}], map: (e: Fofou) => {
                this.fofouChanged.emit(e);
                return e.fouId || '';
            }, selection: 'single', fetchAction: () => this._fofouService.getAllFofou()
                .pipe(map((l) => l.filter((e) => e.fouId)))
        };
    }
}
