import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Ppheusaid2} from '@app/modules/sam-main/placement/components/ppheusaid2/ppheusaid2.model';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {RestEntityClient} from '@app/sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {Sags} from '@app/sam-base/models';

import {ComboboxItem} from '@app/sam-base/models/components/combobox-item.model';
import {
    ModalNumberSubmitComponent
} from '@shared/widgets/modal-components/modal-number-submit/modal-number-submit.component';
import {
    ModalNumberSubmitOptions
} from '@shared/widgets/modal-components/modal-number-submit/modal-number-submit.options';
import {
    HourAddEvent,
    HoursPerDay,
    hoursTypesCombo,
    Ppheusaid2Hours,
    Ppheusaid2Input,
    Ppheusaid2Output
} from './iw-hours-grid.model';

@Component({
    selector: 'iw-hours-grid',
    templateUrl: './iw-hours-grid.component.html',
    styleUrls: ['./iw-hours-grid.component.scss'],
    standalone: false
})
export class IwHoursGridComponent extends BaseStoreFormComponent<Ppheusaid2> implements OnInit {

    @Input() public canAddHours = false;
    public selectedHour: Ppheusaid2Hours | undefined;
    public lhhmm: '1/60' | '1/100' = '1/100';
    public changingHoursFormat = false;
    public inputType: 'number' | 'time' = 'number';
    public countableIds = [
        'H100',
        'H125',
        'H150',
        'H200',
        'HPAU',
        'HS25'];
    public totalEmploye = 0;
    public totalClient = 0;
    public mntAvsEmp = 0;
    public mntHeuEmp = 0;
    public mntHeuCli = 0;
    public mntIndEmp = 0;
    public mntIndCli = 0;
    public hoursLabel = '';
    public employeHeader = '';
    public clientHeader = '';
    public coeffHeader = '';
    public hpauseArray: boolean[] = [];
    public sags: Sags[] = [];
    public hoursToAdd: ComboboxItem<string | undefined>[] = [];
    public selectedHourToAdd?: string;
    public currentTooltip = '';
    public hoursPerDay: HoursPerDay = {
        day1: 0,
        day2: 0,
        day3: 0,
        day4: 0,
        day5: 0,
        day6: 0,
        day7: 0
    };
    @Output() public ppheuSaidOutput = new EventEmitter<Ppheusaid2Output>();
    @Output() public addHourEvent = new EventEmitter<HourAddEvent>();
    @Input() public disableEdit = false;
    public totalHoursGrid = 0;
    private readonly _restClient: RestEntityClient<Sags>;

    constructor(private readonly _modalService: ModalService, private readonly _rest: RestApiService,
                store: IwStoreService) {
        super(store);
        this._restClient = this._rest.getEntityClient(Sags);
    }

    private _ppheuSaidInput?: Ppheusaid2Input;

    public get ppheuSaidInput(): Ppheusaid2Input | undefined {
        return this._ppheuSaidInput;
    }

    @Input()
    public set ppheuSaidInput(v: Ppheusaid2Input | undefined) {
        this._ppheuSaidInput = v;
        if (this._ppheuSaidInput?.heurepause) {
            this._ppheuSaidInput.heurepause.auto = this._hpauseAutoEnable;
        }
        this.populateTotalHours();
        this.removeExistingHours();
        this.calcAllLinesTotal();
    }

    private _hpauseAutoEnable = false;

    public get hpauseAutoEnable() {
        return this._hpauseAutoEnable;
    }

    @Input()
    public set hpauseAutoEnable(v: boolean) {
        this._hpauseAutoEnable = v;
        if (this.ppheuSaidInput?.heurepause) {
            this.ppheuSaidInput.heurepause.auto = v;
        }
        this.populateTotalHours();
        this.calcAllLinesTotal();
        this.fullfillHeader();
    }

    public ngOnInit() {
        this._restClient.getRefData()
            .subscribe((elems: Sags[]) => {
                this.sags = elems;
            });
        this.hoursToAdd = getDeepCopy(hoursTypesCombo);
        this.removeExistingHours();
    }

    public populateTotalHours() {
        for (let i = 1; i <= 7; i++) {
            if (this.ppheuSaidInput && this.ppheuSaidInput.heurepause.exists && this.ppheuSaidInput.heurepause.auto) {
                this.populateHpause(i);
            }
            this.totalHoursPerDay(i);
        }
        this.ppheuSaidInput?.gridHours.forEach((gridHour: Ppheusaid2Hours) => {
            gridHour.totalHours = this.totalHoursPerWeek(gridHour) as number;
        });
        this.hoursOutput();
    }

    public getNextDay(dayIncrement: number) {
        return IwDateHelper.dateFormatFromString(IwDateHelper.addToDate(dayIncrement, 'days', this.ppheuSaidInput?.datelundi), 'D');
    }

    // eslint-disable-next-line complexity
    public totalHoursPerWeek(gridHour: Ppheusaid2Hours, forceDecimal = false): string | number {
        if (this.lhhmm === '1/100' || gridHour.indorheu === 'I') {
            gridHour.totalHours = +(((Number(gridHour.day1Hour) || 0) + (Number(gridHour.day2Hour) || 0) + (Number(gridHour.day3Hour) || 0) + (Number(gridHour.day4Hour) || 0) + (Number(gridHour.day5Hour) || 0) + (Number(gridHour.day6Hour) || 0) + (Number(gridHour.day7Hour) || 0))).toFixed(2);
            return gridHour.totalHours;
        } else {
            let weekHourSum = IwDateHelper.getDuration('00:00');
            for (let day = 1; day < 8; day++) {
                const hour: any = {...gridHour};
                weekHourSum = IwDateHelper.addToDuration(hour['day' + day + 'Hour'], IwDateHelper.getDuration(weekHourSum));
            }
            if (forceDecimal) {
                return +(IwDateHelper.asHours(weekHourSum)).toFixed(2);
            }
            gridHour.totalHours = this.parseDecimalToHours(IwDateHelper.asHours(weekHourSum));
            return gridHour.totalHours;
        }
    }

    public totalHoursPerDay(day: number, forceDecimal = false) {
        this.calcMontants();
        const hoursAux: any = this.hoursPerDay;
        if (this.lhhmm === '1/100') {
            let daySum = 0;
            this.ppheuSaidInput?.gridHours.forEach((hour: any) => {
                if (hour.indorheu !== 'H') {
                    return;
                }
                daySum += Number(hour['day' + day + 'Hour']);
            });
            hoursAux['day' + day] = +(daySum).toFixed(2);
            return hoursAux['day' + day];
        } else {
            let daySum = IwDateHelper.getDuration('00:00');
            this.ppheuSaidInput?.gridHours.forEach((hour: any) => {
                if (hour.indorheu !== 'H') {
                    return;
                }
                daySum = IwDateHelper.addToDuration(hour['day' + day + 'Hour'], IwDateHelper.getDuration(daySum));
            });
            hoursAux['day' + day] = this.parseDecimalToHours(IwDateHelper.asHours(daySum));
            if (forceDecimal) {
                return +IwDateHelper.asHours(daySum)
                    .toFixed(2);
            }
            return hoursAux['day' + day];
        }
    }

    public totalHours(forceDecimal = false): string | number {
        if (this.lhhmm === '1/100') {
            let totalHoursSum = 0;

            for (let day = 1; day < 8; day++) {
                this.ppheuSaidInput?.gridHours.forEach((hour: any) => {
                    if (hour.indorheu !== 'H') {
                        return;
                    }
                    totalHoursSum += Number(hour['day' + day + 'Hour']);
                });
            }
            return +(totalHoursSum).toFixed(2);
        } else {
            let totalHoursSum = IwDateHelper.getDuration('00:00');
            for (let day = 1; day < 8; day++) {
                this.ppheuSaidInput?.gridHours.forEach((hour: any) => {
                    if (hour.indorheu !== 'H') {
                        return;
                    }
                    totalHoursSum = IwDateHelper.addToDuration(hour['day' + day + 'Hour'], IwDateHelper.getDuration(totalHoursSum));
                });
            }
            if (forceDecimal) {
                return +(IwDateHelper.asHours(totalHoursSum)).toFixed(2);
            }
            return this.parseDecimalToHours(IwDateHelper.asHours(totalHoursSum));
        }
    }

    public isDisabled(idx: number, gridHour: Ppheusaid2Hours) {
        if (this.noInput()) {
            return true;
        }
        return this.isPauseHourDisabled(idx, gridHour) || this.dayUndefined(idx) || this.disableEdit || !this.isAfterDateDebut(idx);
    }

    public async changeHoursFormat() {
        this.changingHoursFormat = true;
        switch (this.lhhmm) {
            case '1/60':
                this.lhhmm = '1/100';
                this.convertToNumber(this.ppheuSaidInput?.gridHours || []);
                this.inputType = 'number';
                break;
            case '1/100':
                this.lhhmm = '1/60';
                this.convertToTime(this.ppheuSaidInput?.gridHours || []);
                this.inputType = 'time';
                break;
        }
    }

    public resetLhmm() {
        this.changingHoursFormat = false;
    }

    public convertToTime(hours: Ppheusaid2Hours[]) {
        for (let day = 1; day < 8; day++) {
            hours.forEach((hour: any) => {
                if (hour.indorheu !== 'I') {
                    hour['day' + day + 'Hour'] = this.parseDecimalToTime(hour['day' + day + 'Hour']);
                }
                this.totalHoursPerWeek(hour);
            });
            this.totalHoursPerDay(day);
        }
    }

    public convertToNumber(hours: Ppheusaid2Hours[]) {
        for (let day = 1; day < 8; day++) {
            hours.forEach((hour: any) => {
                if (hour.indorheu !== 'I') {
                    hour['day' + day + 'Hour'] = this.parseTimeToDecimal(hour['day' + day + 'Hour']);
                }
                this.totalHoursPerWeek(hour);
            });
            this.totalHoursPerDay(day);
        }
    }

    public resetHours() {
        let resetValue: number | string;
        if (this.lhhmm === '1/100') {
            resetValue = 0;
        } else {
            resetValue = '00:00';
        }

        for (let day = 1; day < 8; day++) {
            this.ppheuSaidInput?.gridHours.forEach((hour: any) => {
                hour['day' + day + 'Hour'] = resetValue;
                this.totalHoursPerWeek(hour);
            });
            this.totalHoursPerDay(day);
        }

    }

    public async showLuveModal() {
        const options: ModalNumberSubmitOptions = {
            showCancel: true,
            title: 'repartirLuve',
            maxNumber: 119,
            minNumber: -119
        };
        try {
            const hour: any = await this._modalService.showModal(ModalNumberSubmitComponent, options);
            this.distributeLuve(hour);
        } catch (error) {
        }
    }

    public distributeLuve(hour: number) {
        let weeklyDays = 5;
        let hourDivision = hour / weeklyDays;
        const gridHour: any = this.ppheuSaidInput?.gridHours
            .find(ghour => ghour.grhId === 'H100');
        for (let day = 1; day < 6; day++) {
            // Checks if day is disabled
            if (this.isDisabled(day, gridHour)) {
                weeklyDays--;
                hourDivision = hour / weeklyDays;
                continue;
            }
            //
            gridHour['day' + day + 'Hour'] = hourDivision;
            this.totalHoursPerDay(day);
            this.updateHours(day, gridHour);
        }
    }

    public calcMntTotEmp(gridHour: Ppheusaid2Hours): number {
        // This function should run for each week
        let mntTotEmp = 0;
        const totalHours = this.totalHours(true) as number;
        if (gridHour.grhId === 'HPAU') {
            if (this.ppheuSaidInput?.heurepause.exists) {
                if (this.ppheuSaidInput.heurepause.pauseMode > 1) {
                    mntTotEmp = gridHour.mntemp * this.totalHoursGrid;
                }
            }
        } else {
            mntTotEmp = (this.totalHoursPerWeek(gridHour, true) as number) * gridHour.mntemp;
        }
        return mntTotEmp;
    }

    public calcMntTotCli(gridHour: Ppheusaid2Hours): number {
        // This function should run for each week
        return (this.totalHoursPerWeek(gridHour, true) as number) * gridHour.mntcli;
    }

    public calcMontants() {
        this.mntAvsEmp = 0;
        this.mntHeuEmp = 0;
        this.mntHeuCli = 0;
        this.mntAvsEmp = 0;
        this.mntIndEmp = 0;
        this.mntIndCli = 0;
        if (this.ppheuSaidInput?.gridHours.length === 0) {
            return 0;
        }
        // eslint-disable-next-line complexity
        this.ppheuSaidInput?.gridHours.forEach((hour: Ppheusaid2Hours) => {
            const gsIdSags = this.sags.filter(e => e.gsId === hour.gsId)[0] || [];
            const mntTotEmp = this.calcMntTotEmp(hour);
            const mntTotCli = this.calcMntTotCli(hour);
            switch (hour.indorheu) {
                case 'H':
                    this.mntAvsEmp += mntTotEmp;
                    this.mntHeuEmp += mntTotEmp;
                    if (hour.grhId !== 'HPAU') {
                        this.mntHeuCli += mntTotCli;
                    }
                    break;
                case 'I':
                    this.mntAvsEmp += mntTotEmp * (gsIdSags.baseAvs || 0);
                    this.mntIndEmp += mntTotEmp;
                    this.mntIndCli += mntTotCli;
                    break;
            }
        });

        this.mntAvsEmp = +(this.mntAvsEmp).toFixed(2);
        this.mntHeuEmp = +(this.mntHeuEmp).toFixed(2);
        this.mntHeuCli = +(this.mntHeuCli).toFixed(2);
        this.mntIndEmp = +(this.mntIndEmp).toFixed(2);
        this.mntIndCli = +(this.mntIndCli).toFixed(2);
        this.totalEmploye = +(this.mntHeuEmp + this.mntIndEmp).toFixed(2);
        this.totalClient = +(this.mntHeuCli + this.mntIndCli).toFixed(2);
    }

    // eslint-disable-next-line complexity
    public populateHpause(day: number) {
        if (this.ppheuSaidInput?.heurepause.exists) {
            if (this.ppheuSaidInput.heurepause.auto) {
                const hpau: any = this.ppheuSaidInput.gridHours
                    .find(hour => hour.grhId === 'HPAU');
                if (!hpau) {
                    return;
                }

                let decimalHpau;
                if (typeof hpau['day' + day + 'Hour'] === 'string') {
                    decimalHpau = this.parseTimeToDecimal(hpau['day' + day + 'Hour']);
                } else {
                    decimalHpau = hpau['day' + day + 'Hour'];
                }
                const dayHours = this.totalHoursPerDay(day, true) - decimalHpau;
                if (dayHours > 0) {
                    if (this.lhhmm === '1/100') {
                        hpau['day' + day + 'Hour'] = 0.25;
                    } else {
                        hpau['day' + day + 'Hour'] = '00:15';
                    }
                }
                if (dayHours < 0) {
                    if (this.lhhmm === '1/100') {
                        hpau['day' + day + 'Hour'] = -0.25;
                    } else {
                        hpau['day' + day + 'Hour'] = '-00:15';
                    }
                }
                if (dayHours === 0) {
                    if (this.lhhmm === '1/100') {
                        hpau['day' + day + 'Hour'] = 0;
                    } else {
                        hpau['day' + day + 'Hour'] = '00:00';
                    }
                }
            } else if (this.getActiveWorkedHoursForADay(day) === 0) {
                this.resetPauseHourForDay(day);
            }
        }
    }

    public updateHours(day: number, hour: Ppheusaid2Hours) {
        this.totalHoursPerWeek(hour);
        this.hoursOutput();
        // Changed call order so the sum of hpause is correctly done
        this.populateHpause(day);
        this.calcAllLinesTotal();
        this.totalHoursPerDay(day);
        this.fullfillHeader();
    }

    // eslint-disable-next-line complexity
    public fullfillHeader() {
        if (!this.selectedHour) {
            this.hoursLabel = '';
            this.employeHeader = '';
            this.clientHeader = '';
            this.coeffHeader = '';
            return;
        }
        if (this.selectedHour.totalHours !== 0) {
            const mnttotemp = +(this.calcMntTotEmp(this.selectedHour)).toFixed(2);
            const mnttotcli = +(this.calcMntTotCli(this.selectedHour)).toFixed(2);
            this.employeHeader = (this.selectedHour.grhId === 'HPAU' ? this.totalHoursGrid : this.selectedHour.totalHours) + ' x ' + this.selectedHour.mntemp + ' = ' + mnttotemp;
            this.clientHeader = this.selectedHour.totalHours + ' x ' + this.selectedHour.mntcli + ' = ' + mnttotcli;
            if (mnttotemp !== 0) {
                this.coeffHeader = (+(mnttotcli / mnttotemp).toFixed(3)).toString();
            }
        } else {
            this.employeHeader = this.selectedHour.mntemp.toString();
            this.clientHeader = this.selectedHour.mntcli.toString();
            if (this.selectedHour.mntemp !== 0) {
                this.coeffHeader = (+(this.selectedHour.mntcli / this.selectedHour.mntemp)
                    .toFixed(3)).toString();
            }
        }
        this.hoursLabel = this.selectedHour.libelle;
    }

    public hoursOutput() {
        const output: Ppheusaid2Output = {
            gridHours: this.ppheuSaidInput ? [...this.ppheuSaidInput.gridHours] : [],
            gridCalculations: {
                mntAvsEmp: this.mntAvsEmp,
                totCli: this.totalClient,
                totEmp: this.totalEmploye,
                totalDay1Hours: this.hoursPerDay.day1 as number,
                totalDay2Hours: this.hoursPerDay.day2 as number,
                totalDay3Hours: this.hoursPerDay.day3 as number,
                totalDay4Hours: this.hoursPerDay.day4 as number,
                totalDay5Hours: this.hoursPerDay.day5 as number,
                totalDay6Hours: this.hoursPerDay.day6 as number,
                totalDay7Hours: this.hoursPerDay.day7 as number,
                totalHours: this.totalHours() as number
            },
            timeformat: this.lhhmm
        };
        if (this.lhhmm === '1/100') {
            this.ppheuSaidOutput.emit(output);
        } else {
            /* eslint-disable max-len */
            let timeOutput = getDeepCopy(output);
            this.convertToNumber(timeOutput.gridHours);
            timeOutput = {
                ...timeOutput,
                totalDay1Hours: this.parseTimeToDecimal(this.hoursPerDay.day1 as string),
                totalDay2Hours: this.parseTimeToDecimal(this.hoursPerDay.day2 as string),
                totalDay3Hours: this.parseTimeToDecimal(this.hoursPerDay.day3 as string),
                totalDay4Hours: this.parseTimeToDecimal(this.hoursPerDay.day4 as string),
                totalDay5Hours: this.parseTimeToDecimal(this.hoursPerDay.day5 as string),
                totalDay6Hours: this.parseTimeToDecimal(this.hoursPerDay.day6 as string),
                totalDay7Hours: this.parseTimeToDecimal(this.hoursPerDay.day7 as string),
                totalHours: this.totalHours(true) as number
            };
            this.ppheuSaidOutput.emit(timeOutput);
        }
    }

    public weekHoursEnabled(): boolean {
        if (!this._ppheuSaidInput?.gridDays) {
            return true;
        }
        for (let i = 0; i < 5; i++) {
            if (!this._ppheuSaidInput?.gridDays[i]) {
                return false;
            }
        }
        return true;
    }

    public addHour() {
        if (this.selectedHourToAdd) {
            this.addHourEvent.emit({
                hourType: this.selectedHourToAdd,
                lhmm: this.lhhmm
            });
        }
    }

    // eslint-disable-next-line complexity
    public setTooltip(el?: HTMLElement, value?: string) {
        if (el && el.scrollWidth > el.clientWidth) {
            this.currentTooltip = value ?? '';
        }
    }

    public calcAllLinesTotal() {
        this._ppheuSaidInput?.gridHours.forEach(element => {
            if (element.grhId === 'HPAU') {
                for (let i = 1; i < 8; i++) {
                    const idx = 'day' + i + 'Hour' as keyof Ppheusaid2Hours;
                    this.hpauseArray[i - 1] = !!element[idx];
                }
            }
        });
        this.totalHoursGrid = 0;
        // eslint-disable-next-line complexity
        this._ppheuSaidInput?.gridHours.forEach(element => {
            if (element.grhId === 'HPAU') {
                return;
            }
            for (let i = 1; i < 8; i++) {
                const idx = 'day' + i + 'Hour' as keyof Ppheusaid2Hours;
                if ((element[idx] as number ?? 0) > 0 && this.hpauseArray[i - 1]) {
                    this.totalHoursGrid += Number(element[idx]);
                }
            }
        });
    }

    public clearTooltip() {
        this.currentTooltip = '';
    }

    private isAfterDateDebut(idx: number) {
        return IwDateHelper.addToDate(idx - 1, 'd', this.ppheuSaidInput?.datelundi)
            .isSameOrAfter(this.ppheuSaidInput?.datestart);
    }

    private dayUndefined(idx: number) {
        if (!this.ppheuSaidInput || !this.ppheuSaidInput.gridDays) {
            return false;
        }
        return !this.ppheuSaidInput.gridDays[idx - 1];
    }

    private noInput() {
        return !this.ppheuSaidInput || !this.ppheuSaidInput.gridDays;
    }

    private isPauseHourDisabled(day: number, gridHour: Ppheusaid2Hours): boolean {
        return gridHour.grhId === 'HPAU' && (this._hpauseAutoEnable || this.getActiveWorkedHoursForADay(day) === 0);
    }

    private resetPauseHourForDay(day: number) {
        if (!this.ppheuSaidInput || !this.ppheuSaidInput.gridDays) {
            return 0;
        }
        const hpau: any = this.ppheuSaidInput.gridHours
            .find(hour => hour.grhId === 'HPAU');
        hpau[`day${day}Hour` as keyof Ppheusaid2Hours] = 0;
    }

    private getActiveWorkedHoursForADay(day: number): number {
        if (!this.ppheuSaidInput || !this.ppheuSaidInput.gridDays) {
            return 0;
        }
        return this.ppheuSaidInput.gridHours.filter(gh => gh.indorheu === 'H' && gh.grhId !== 'HPAU')
            .reduce((accumulator,
                     currentValue) => accumulator + Number(currentValue[`day${day}Hour` as keyof Ppheusaid2Hours]), 0);
    }

    private parseDecimalToTime(num: number) {
        return ('0' + Math.floor(num) % 24).slice(-2) + ':' + ('0' + Math.round((num % 1) * 60)).slice(-2);
    }

    private parseTimeToDecimal(time: string) {
        const t = time.split(':');
        return +(((parseInt(t[0], 10) * 1 + parseInt(t[1], 10) / 60) * 10) / 10)
            .toFixed(2);
    }

    private parseDecimalToHours(num: number) {
        return (Math.floor(num)
            .toString()) + ':' + ('0' + Math.round((num % 1) * 60)).slice(-2);
    }

    private removeExistingHours() {
        this.ppheuSaidInput?.gridHours.forEach(hour => {
            if (hour.grhId) {
                const indexOfHour = this.hoursToAdd.findIndex(hr => hr.value === hour.grhId);
                if (indexOfHour !== -1) {
                    this.hoursToAdd.splice(indexOfHour, 1);
                }
            }
        });
    }
}
