import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SortDirection} from '@app/sam-base/models';
import {Sagssd4istype} from '@app/sam-base/models/common/sagssd4istype';

@Component({
    selector: 'iw-sagssd4istype-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwSagssd4istypeDropdownComponent)],
    standalone: false
})
export class IwSagssd4istypeDropdownComponent extends BaseEntityDropdown<Sagssd4istype> {

    @Input() public width?: string | number;

    public selectedValue = undefined;

    constructor() {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    public getSortProperty(): keyof Sagssd4istype {
        return 'titre';
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType() {
        return Sagssd4istype;
    }

    protected buildLabel(e: Sagssd4istype): string {
        return e.titre ?? '';
    }
}
