import {Component, ComponentRef} from '@angular/core';

import {EmailContractOptions} from './modal-contract-enum';

@Component({
    selector: 'iw-modal-contract.component', templateUrl: './modal-contract.component.html',
    standalone: false
})
export class ModalContractComponent {

    public empSelected = false;
    public cliSelected = false;
    public data: ModalData = {};
    // eslint-disable-next-line
    public __ref?: ComponentRef<ModalContractComponent>;
    public radioValues = [{label: 'employee', value: EmailContractOptions.Employee, checked: true}, {
        label: 'client', value: EmailContractOptions.Client, checked: false
    }];
    public radioOption = EmailContractOptions.Employee;

    public get modalTitle() {
        return this.data?.title ?? 'imprimer';
    }

    public changeEmpValue(val: any) {
        this.empSelected = val;
    }

    public changeCliValue(val: any) {
        this.cliSelected = val;
    }

    public onOk() {
        if (this.data.onOk) {
            this.data.onOk(this.radioOption);
        }
        this.destroy();
    }

    public onCancel() {
        if (this.data.onCancel) {
            this.data.onCancel();
        }
        this.destroy();
    }

    private destroy() {
        if (this.__ref) {
            this.__ref.destroy();
        }
    }
}

export interface ModalData {
    onOk?: (selected: number) => void;
    onCancel?: () => void;
    title?: string;
}
