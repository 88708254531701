import { HttpClient } from '@angular/common/http';
import {AfterContentInit, Component, EventEmitter} from '@angular/core';
import {environment} from '@root/environments/environment';
import {BaseFormComponent} from '@sam-base/base';
import {RestApiService} from '@sam-base/core';
import {FormKeys, ModalComponent} from '@sam-base/models';
import {Ppcliadr} from '@sam-base/models/placement';
import {Ppcliadropts} from 'src/app/modules/sam-main/placement/components/ppcliadr/ppcliadr.options';

@Component({
    templateUrl: './ppcliadr.component.html',
    standalone: false
})
export class PpcliadrComponent extends BaseFormComponent<Ppcliadr> implements ModalComponent<boolean, Ppcliadropts>, AfterContentInit {

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public isWriteMode = true;
    private _options = new Ppcliadropts();
    private _ok?: (result: boolean) => void;
    private _cancel?: (error?: any) => void;

    constructor(private _restService: RestApiService, private _http: HttpClient) {
        super();
    }

    public get cliadrId(): string | undefined {
        return this._options.cliadrId;
    }

    public get clientFormUuid(): string | undefined {
        return this._options.cliFormUuid;
    }

    public get cliId(): string | undefined {
        return this._options.cliId;
    }

    public ngAfterContentInit() {
        if (!this.cliadrId) {
            this.setNewAddress();
        } else {
            this._restService.getEntityClient(Ppcliadr)
                .getById(this.cliadrId)
                .subscribe((addr: Ppcliadr) => {
                    this.fillFormData(addr);
                });
        }
    }

    public async setData(data: Ppcliadropts): Promise<void> {
        this._options = data;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok(true);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public registerOk(action: (result: boolean) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public saveChanges() {
        const cliadrId: string | undefined = this.getFormValue('cliadrId');
        const addrData = this.getFormData();
        if (cliadrId) {
            this._http.put<Ppcliadr>(environment.backendURL + 'ppcliadr/' + cliadrId, this.getFormData())
                .subscribe(() => this.onPressOk());
        } else {
            if (!addrData) {
                return;
            }
            this._restService.getEntityClient(Ppcliadr)
                .create(addrData)
                .subscribe(() => this.onPressOk());
        }
    }

    public closeDialog() {
        this.onPressCancel();
    }

    protected getFormControlNames(): FormKeys<Ppcliadr> {
        return ['adrlib',
            'cliadrId',
            'prefixe',
            'nom',
            'adresse1',
            'adresse2',
            'adresse3',
            'npa',
            'lieu',
            'pays',
            'dtAnyModif',
            'userId',
            'cliId'];
    }

    protected getValidationType() {
        return Ppcliadr;
    }

    protected validateFields(e: Ppcliadr): boolean {
        return super.validateFields(e);
    }


    private setNewAddress() {
        this.setFormValue('cliId', this.cliId);
    }

}
