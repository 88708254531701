import {Component, ElementRef, Input, Type} from '@angular/core';
import {BaseEntityDropdown, buildCustomValueProvider} from '@app/sam-base/base';
import {SortDirection} from '@app/sam-base/models';
import {Ppcon} from '@app/sam-base/models/placement';


@Component({
    selector: 'iw-conseiller-ppcon-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwConseillerPpconDropdownComponent)],
    standalone: false
})
export class IwConseillerPpconDropdownComponent extends BaseEntityDropdown<Ppcon> {

    @Input()

    public warningMessage?: string;

    public selectedValue = undefined;

    constructor(private elRef: ElementRef) {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public getSortProperty(): keyof Ppcon {
        return 'nom';
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType(): Type<Ppcon> {
        return Ppcon;
    }

    protected buildLabel(e: Ppcon) {
        return `${e.nom} ${e.prenom}`;
    }
}
