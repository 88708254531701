import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioItem} from '@app/sam-base/models/components/radio-item.model';
import {ListItem} from '@shared/widgets/form-components/list-box/list-item.model';
import {sortBy} from 'lodash';

@Component({
    selector: 'iw-modal-category-selection',
    templateUrl: './modal-category-selection.component.html',
    styleUrls: ['./modal-category-selection.component.scss'],
    standalone: false
})
export class ModalCategorySelectionComponent<T> {

    @Input() public title = '';
    @Input() public selectedOrder?: keyof T;

    @Output() public pressOk = new EventEmitter<T[]>();

    @Output() public pressCancel = new EventEmitter<void>();
    public firstList: ListItem<T>[] = [];
    public secondList: ListItem<T>[] = [];
    public radioOptions: RadioItem<keyof T>[] = [];
    public display = true;
    public clickMode: 'cancel' | 'ok' = 'cancel';
    public customSortValue?: (list: ListItem<T>[]) => ListItem<T>[];
    private _lastSortKey?: keyof T;

    constructor() {
    }

    @Input()
    public set orderKeys(val: (keyof T)[]) {
        this.radioOptions = val.map(e => (<RadioItem<keyof T>>{
            label: '' + String(e), value: e
        }));
        this.selectedOrder = val[0];
    }

    private _input: T[] = [];

    public get input(): T[] {
        return this._input;
    }

    @Input()
    public set input(v: T[]) {
        this._input = v;
        this.updateLists();
    }

    private _output: T[] = [];

    public get output(): T[] {
        return this._output;
    }

    @Input()
    public set output(v: T[]) {
        this._output = v;
        this.updateLists();
    }

    private _label: (e: T) => string = (e: T) => '' + e;

    public get label() {
        return this._label;
    }

    @Input()
    public set label(value) {
        this._label = value;
        this.updateLists();
    }

    public customSort(sortKey?: keyof T) {
        if (sortKey === this._lastSortKey) {
            return;
        }
        this._lastSortKey = sortKey;

        if (!sortKey) {
            this.customSortValue = (list: ListItem<T>[]) => list;
            return;
        }

        this.customSortValue = (list: ListItem<T>[]) => sortBy(list, [e => e.value[sortKey]]);
    }

    public updateLists() {
        this.firstList = this.mapToListItem(this.input);
        this.secondList = this.mapToListItem(this.output);
        this._lastSortKey = <any>'';
        this.customSort(this.selectedOrder);
    }

    public setInput(list: ListItem<T>[]) {
        this._input = list.map(e => e.value);
    }

    public setOutput(list: ListItem<T>[]) {
        this._output = list.map(e => e.value);
    }

    public onPressOk() {
        this.clickMode = 'ok';
        this.display = false;
    }

    public onPressCancel() {
        this.clickMode = 'cancel';
        this.display = false;
    }

    public onBeforeHide() {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.clickMode === 'ok' ? this.pressOk.emit(this.secondList.map(e => e.value)) : this.pressCancel.emit();
    }

    private mapToListItem(list: T[]) {
        return list.map(e => <ListItem<T>>({
            label: this.label(e), value: e
        }));
    }
}
