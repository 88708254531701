import {Component, ElementRef, Input, OnInit, Type} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base';
import {IwTextFieldComponent} from '@app/sam-base/components';
import {EntitySelectService, getEntityMetadata, IwStoreService} from '@app/sam-base/core';
import {ObjectMap, TableSelectionMode} from '@app/sam-base/models';
import {Ppcli} from '@app/sam-base/models/placement';
import {isArray} from 'lodash';
import {filter} from 'rxjs/operators';

@Component({
    styleUrls: ['./multi-entity-textfield.component.scss'],
    templateUrl: './multi-entity-textfield.component.html',
    selector: 'iw-multi-entity-textfield',
    providers: [buildCustomValueProvider(MultiEntityTextfieldComponent)],
    standalone: false
})
export class MultiEntityTextfieldComponent<T> extends IwTextFieldComponent implements OnInit {

    @Input() public showIdField = true;

    @Input() public allowSearch = false;

    @Input() public inputClass?: string;

    @Input() public elasticEntity?: Type<T>;

    @Input() public filter?: ObjectMap<T>;

    @Input() public mode?: TableSelectionMode = 'single';
    @Input() public name: keyof T | (keyof T)[] = [];
    @Input() public separator = ' ';

    constructor(private readonly _store: IwStoreService, private readonly _select: EntitySelectService<T>, elRef: ElementRef) {
        super(elRef);
    }

    private _entityValue?: string;

    public get entityValue() {
        return this._entityValue;
    }

    private _entity: Type<T> = <any>Ppcli;

    public get entity() {
        return this._entity;
    }

    @Input()
    public set entity(val: Type<T>) {
        this.setEntity(val);
    }

    public onDoubleClick() {
        if (!this.allowSearch || !this._entity) {
            return;
        }
        this.value = 'aaaa';

        this._select.selectEntity({entity: this.entity, filter: this.filter, mode: this.mode});
        const that = this;
        const sub = this._store.entitySelect(this._entity)
            .pipe(filter(s => s.completed))
            .subscribe(s => {
                sub.unsubscribe();
                if (s.selected.length > 1) {
                    const sel = s.selected;
                    if (sel) {
                        this.setEntityValue(sel);
                        const ids = sel.map(elem => getEntityMetadata(elem)
                            .$getPk());
                        this.value = ids.join('; ');
                    } else {
                        this._entityValue = '';
                        this.value = '';
                    }
                } else {
                    const sel = s.selected[0];
                    if (sel) {
                        const id = getEntityMetadata(sel)
                            .$getPk();
                        this.setEntityValue(sel);
                        setTimeout(() => {
                            this.value = '' + id;
                        }, 0);
                    } else {
                        this._entityValue = '';
                        this.value = '';
                    }
                }
            });
    }

    private buildEntityValue(e: T | undefined) {
        if (!e) {
            return '';
        }
        const keys = isArray(this.name) ? this.name : [this.name];
        let value = '';
        for (const k of keys) {
            value += `${e[k] || ''}${this.separator}`;
        }

        return value;
    }

    private buildEntityValueArray(e: T[] | undefined) {
        if (!e) {
            return '';
        }
        const keys = isArray(this.name) ? this.name : [this.name];
        let value = '';
        e.forEach(elem => {
            for (const k of keys) {
                value += `${elem[k]}`;
            }
            value += '; ';
        });

        return value;
    }

    private setEntity(val: Type<T>) {
        this._entity = val;
    }

    private setEntityValue(e: T | T[] | undefined) {
        if (!e) {
            return;
        }
        this._entityValue = Array.isArray(e) ? this.buildEntityValueArray(e) : this.buildEntityValue(e);
    }
}
