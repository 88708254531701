import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {RestApiService, RestQueryOperation} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Bank} from '@app/sam-base/models/common';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';

import {IwTextfieldListComponent} from '../input-select/iw-textfield-list.component';

@Component({
    templateUrl: './iw-textfield-bank.component.html',
    selector: 'iw-textfield-bank',
    providers: [buildCustomValueProvider(IwTextfieldBankComponent)],
    standalone: false
})
export class IwTextfieldBankComponent extends IwTextfieldListComponent<Bank> {
    public readonly listOption: ModalSelectTableOption<Bank, string>;

    @Input() public iid: string = '';
    @Output() public selected: EventEmitter<any> = new EventEmitter();

    constructor(modalService: ModalService, elRef: ElementRef, restService: RestApiService) {
        super(modalService, elRef, restService);
        this.propsToFilter = ['bankOrInstitutionName'];
        this.listOption = {
            columns: [
                {
                    prop: 'iid',
                    type: 'number'
                },
                {prop: 'bic'},
                {prop: 'headOffice'},
                {prop: 'zipCode'},
                {prop: 'postalAddress'},
                {prop: 'phone'}],
            map: (e: Bank) => {
                this.selected.emit(e);
                return e.bankOrInstitutionName || '';
            },
            selection: 'single',
            type: Bank,
            query: [
                {
                    operation: RestQueryOperation.NotEquals,
                    prop: 'iid',
                    value: '0'
                }],
            size: 1000,
            hasFilter: true
        };
    }
}
