import {Pipe, PipeTransform} from '@angular/core';
import {dateTimeValue} from './iw-cell.helper';

@Pipe({
    name: 'iwDatetime',
    standalone: false
})
export class IwDatetimePipe implements PipeTransform {
    constructor() {
    }

    public transform<T>(value: Date | string | undefined): string {
        return dateTimeValue(value as Date);
    }
}
