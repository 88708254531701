import {
    ChangeDetectionStrategy, Component, Input, OnInit
} from '@angular/core';
import {
    buildCustomValueProvider
} from '@app/sam-base/base/build-value-provider';

@Component({
    selector: 'iw-cell-status-rapmisact',
    templateUrl: './iw-cell-status-rapmisact.component.html',
    providers: [buildCustomValueProvider(IwCellStatusRapmisactComponent)],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IwCellStatusRapmisactComponent implements OnInit {

    @Input() public row?: { misstatus: number };

    @Input() public color = ''; // No default color

    @Input() public value = '';

    constructor() {
    }

    public ngOnInit() {
        if (this.row && this.row.misstatus) {
            this.checkColor(this.row.misstatus);
        }
    }

    // eslint-disable-next-line complexity
    public checkColor(status: number) {
        switch (status) {
            case (0):
            case (1):
                this.color = '#008000';
                break;
            case 2:
                this.color = '#80ff80';
                break;
            case 3:
                this.color = '#ff8000';
                break;
            case 4:
                this.color = '#ff8080';
                break;
            default:
                break;
        }
    }
}
