import {Component, EventEmitter} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalComponent} from '@sam-base/models';
import {Ppclimis} from '@sam-base/models/placement';

import {RappreprtMisModalOptions} from './rappreprt-mis-modal-enum';

@Component({
    selector: 'iw-modal-contract.component', templateUrl: './rappreprt-mis-modal.component.html',
    standalone: false
})
export class RappreprtMisModalComponent implements ModalComponent<string[], RappreprtMisModalOptions> {

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();

    public missions?: Ppclimis[] = [];
    public selectedMissions?: Ppclimis[] = [];

    private _ok?: (result: string[]) => void;
    private _cancel?: (error?: any) => void;

    constructor(private _translate: TranslateService) {
    }

    public async setData(data: RappreprtMisModalOptions): Promise<void> {
        this.missions = data.missions?.map(mission => ({
            ...mission, misstatustext: this._translate.instant(mission.misstatustext ?? '')
        }));
        this.selectedMissions = data.selectedMissions;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok(this.selectedMissions?.map(value => value.misId ?? '') ?? []);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }


    public registerOk(action: (result: string[]) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

}
