import {IwRestEntity, IwRestEntityId} from '@sam-base/core';

@IwRestEntity('tt6ges', '', 'common')
export class TT6Ges {
    @IwRestEntityId() public nogestion?: string;
    public libgestion?: string;
    public dateCreat?: string;
    public dateTrack?: string;
    public modules?: string;
}
