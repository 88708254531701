import {Component, Type} from '@angular/core';
import {BaseColumnDetail} from '@app/sam-base/base/base-column-detail';
import {EntityNavigationService} from '@app/sam-base/core';
import {GepaieleService} from '@app/sam-base/core/services/gepaiele.service';
import {IwGridColumn} from '@app/sam-base/models';
import {FactureDetail} from '@app/sam-base/models/invoices/factureDetail';
import {Gefacliview} from '@app/sam-base/models/invoices/gefacliview';
import {Gepaicliview} from '@app/sam-base/models/invoices/gepaicliview';
import {Ppsuivi} from '@app/sam-base/models/placement';

@Component({
    templateUrl: './entity-detail-facture.component.html',
    standalone: false
})
export class EntityDetailFactureComponent<T> extends BaseColumnDetail<T> {
    public entity: Type<any> = Ppsuivi;

    public noData = false;

    public detailsList: FactureDetail[] = [];

    public columns: IwGridColumn<any>[] = [{
        index: 0, prop: 'pay_id', type: 'string', name: 'no'
    }, {
        index: 1, prop: 'date_pay', type: 'date', name: 'date'
    }, {
        index: 2, prop: 'montant', name: 'montant'
    }, {
        index: 3, prop: 'fac_no', name: 'facNo'
    }, {
        index: 4, prop: 'compte', name: 'compte'
    }, {
        index: 5, prop: 'lettrage', name: 'L'
    }, {
        index: 4, prop: 'origine', name: 'O'
    }];

    constructor(private readonly _gepaieleService: GepaieleService, private _navigationService: EntityNavigationService) {
        super();
    }

    public async instaciate(row: T) {
        const gefacli = row as Gefacliview;

        if (gefacli.facId) {
            this._gepaieleService
                .getPaieles(gefacli.facId)
                .subscribe((list: FactureDetail[]) => {
                    if (list.length) {
                        this.detailsList = list;
                    } else {
                        this.noData = true;
                    }
                });
        }
    }

    public openPaiement(event: any): void {
        this._navigationService.navigateToEntityForm(Gepaicliview, event.row.pay_id || '', undefined, 'edit', true);
    }

    public getEntityName() {
        return 'gefacliview';
    }

}
