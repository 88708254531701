import {Component, Input} from '@angular/core';
import {BaseFormControl} from '@app/sam-base/base/base-form-control';

/**
 * Default editor component
 * Created in SAM-4207
 */

@Component({
    selector: 'iw-editor', templateUrl: './iw-editor.component.html', styles: [`
    ::ng-deep .hidden-toolbar .p-editor-toolbar{
      display :none;
    }
  `],
    standalone: false
})
export class IwEditorComponent extends BaseFormControl<string> {

    @Input() public label = '';

    @Input() public labelWidth = '';

    @Input() public labelHeight = '165px';
}
