import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    IwTableComponent
} from '@app/sam-base/components/iw-table/iw-table.component';
import {
    IjTimesheetsService
} from '@app/sam-base/core/services/ij-timesheets.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {
    ModalComponent
} from '@app/sam-base/models/components/modal-component.model';
import {Ijtemprapportview} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-ij-timesheets',
    templateUrl: './ij-timesheets.component.html',
    styleUrls: ['./ij-timesheets.component.scss'],
    standalone: false
})
export class IjTimesheetsComponent implements ModalComponent<void, undefined>, OnInit, OnDestroy {
    public entity = Ijtemprapportview;
    public isLoading = false;
    public selectedReports: Ijtemprapportview[] = [];

    @ViewChild('ijrapsgrid') public rapsGrid?: IwTableComponent<Ijtemprapportview>;

    public interijobActive$ = false;

    private _ok?: (result: void) => void;
    private _cancel?: (error?: any) => void;

    private subscriptions = new Subject();

    constructor(private _ijTimesheetsService: IjTimesheetsService, private _toast: ToastService, private _store: IwStoreService) {
    }

    public ngOnInit() {
        this._store.getLicenseOption('interijobwebaccess')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((active: boolean) => {
                this.interijobActive$ = active;
            });
        this.refreshTable();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public async setData(): Promise<void> {
        return;
    }

    public refreshTable() {
        if (this.interijobActive$) {
            this.isLoading = true;
            this._ijTimesheetsService.importTempTimesheets()
                .subscribe(() => {
                    this.rapsGrid?.refresh();
                    this.isLoading = false;
                }, () => this.isLoading = false);
        }
    }

    public setSelected(sel: Ijtemprapportview[]) {
        this.selectedReports = [...sel];
    }

    public onPressOk() {
        if (this._ok) {
            this._ok();
        }
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
    }

    public registerOk(action: (result: void) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public importRapportsToPlacement() {
        this.isLoading = true;
        this._ijTimesheetsService
            .importTempTimesheets2Placement(this.selectedReports)
            .subscribe(() => {
                this._ijTimesheetsService.getTimesheetsToImport();
                this.rapsGrid?.refresh();
                this.isLoading = false;
            }, (err) => {
                this._toast.error(err.error.message);
                this.isLoading = false;
            });
    }

}
