import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ijtemprapportview')
export class Ijtemprapportview {
    @IwRestEntityId() @IwColumn({name: 'id'}) public ijRapId?: string;
    @IwColumn({
        name: 'datelundi',
        type: 'date'
    }) public dateLundi?: string;
    @IwColumn({
        name: 'semaine',
        type: 'number'
    }) public weekNumber?: number;
    @IwColumn({
        name: 'secId',
        type: 'string'
    })
    public secId?: string;
    @IwColumn({
        name: 'ageId',
        type: 'string'
    })
    public ageId?: string;

    @IwColumn({
        name: 'mis_id',
        type: 'string'
    }) public misId?: string;
    @IwColumn({
        name: 'rapNo',
        type: 'string'
    }) public rapNo?: string;
    public clichaId?: string;
    public chantier?: string;
    public comment?: string;
    @IwColumn({
        name: 'client',
        type: 'string'
    }) public client?: string;
    @IwColumn({
        name: 'employee',
        type: 'string'
    }) public employee?: string;
    public finmission?: string;
    public finmotif?: string;
    public result?: string;
    @IwColumn({
        name: 'conseiller',
        type: 'string'
    }) public conseiller?: string;
    public conId?: string;
}
