import {Component, EventEmitter, Input, Output, Type} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppshcalcmode} from '@app/sam-base/models/common/ppshcalcmode';

@Component({
    selector: 'iw-shcalcmode-combo-box',
    templateUrl: './shcalcmode-combo-box.component.html',
    providers: [buildCustomValueProvider(ShcalcmodeComboBoxComponent)],
    standalone: false
})
export class ShcalcmodeComboBoxComponent extends BaseEntityDropdown<Ppshcalcmode> {

    @Input() public selectedValue = undefined;

    @Output() public selectedChange = new EventEmitter<Ppshcalcmode>();

    constructor() {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    public emitSelected(e: Ppshcalcmode) {
        this.selectedChange.emit(e);
    }

    public getEntityType(): Type<Ppshcalcmode> {
        return Ppshcalcmode;
    }

    public buildLabel(e: Ppshcalcmode) {
        return `${e.titre}`;
    }
}
