import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {HelpMenuItem} from './help-menu.model';

@Component({
    selector: 'iw-help-menu',
    templateUrl: 'iw-help-menu.component.html',
    standalone: false
})
export class IwHelpMenuComponent {

    public items: HelpMenuItem[] = [];

    constructor(private _translate: TranslateService) {
    }

    @Input()
    public set version(v: string) {
        this.updateVersionNumberInMenu(v);
    };

    @Input()
    public set menuItems(it: HelpMenuItem[]) {
        this.addItemsToMenu(it);
    }

    private addItemsToMenu(items: HelpMenuItem[]): void {
        this.items = items;
        this.translateItems();
    }

    private translateItems() {
        this.items.forEach(item => {
            this.translateLabels(item);
            item.items?.forEach(it => {
                this.translateLabels(it);
            });
        });
    }

    private translateLabels(item: HelpMenuItem) {
        // Prevents translation if translate is explicitly set to false
        if (item.translate === false) {
            return;
        }
        // Translates second labels
        item.label = this._translate.instant(item.label ?? '');
    }

    /**
     * Updates the version number in the menu.
     *
     * @param {string} version - The new version number.
     * @return {void}
     */
    private updateVersionNumberInMenu(version: string) {
        this.items = this.items.filter(item => item.label !== 'appVersion');
        this.items.push({
            label: 'appVersion',
            items: [
                {
                    label: version,
                    translate: true,
                }]
        });
        this.translateItems();
    }
}
