import {Component, ElementRef, Input} from '@angular/core';

import {BaseFormControl, buildCustomValueProvider} from '@app/sam-base/base';

@Component({
    selector: 'iw-input-avs-mask',
    templateUrl: './iw-input-avs-mask.component.html',
    providers: [buildCustomValueProvider(IwInputAvsMaskComponent)],
    standalone: false
})
export class IwInputAvsMaskComponent<T> extends BaseFormControl<string> {

    @Input() public suffix?: string;

    @Input() public placeholder = 'avs_default_placeholder';

    @Input() public tooltip?: string;

    @Input() public tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';

    @Input() public warningMessage?: string;

    constructor(private elRef: ElementRef) {
        super();
    }

    public get isUnknown(): boolean {
        return this.value === 'UNKNOWN';
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }
}
