import {Component, Input, OnInit} from '@angular/core';
import {EmailContractOptions} from '@app/sam-base/components/modal/modal-confirm-dialog/modal-contract-enum';
import {ModalContractComponent} from '@app/sam-base/components/modal/modal-confirm-dialog/modal-contract.component';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {
    canDeleteEntityAccessRoles,
    canModifyEntityAccessRoles,
    canPrintEntityAccessRoles,
    GridProfile,
    IwEventOpenModalPayload,
    ModalService,
    RestApiService,
    RestEntityClient,
    ViewContainerRefService
} from '@app/sam-base/core';
import {CommandeService} from '@app/sam-base/core/services/commande.service';
import {EntityDeleteService} from '@app/sam-base/core/services/entity-delete.service';
import {MissionCalculationsService} from '@app/sam-base/core/services/mission-calculations.service';
import {PpconService} from '@app/sam-base/core/services/ppcon.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ENTITIES, Ppaco, Ppcde, Ppcli, Ppemp, Ppmis} from '@app/sam-base/models/placement';
import {MissionContracts} from '@app/sam-base/models/placement/mission-contracts';
import {PpempService} from '@modules/sam-main/placement/services/ppemp.service';

import {Actions, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {FactureService} from '@sam-base/core/services/facture.service';
import {GeperService} from '@sam-base/core/services/geper.service';
import {RappelsService} from '@sam-base/core/services/rappel.service';
import {SaemsService} from '@sam-base/core/services/saems.service';
import {SapardedService} from '@sam-base/core/services/saparded.service';
import {SetLoading} from '@sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@sam-base/core/toast';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {Gefachdr} from '@sam-base/models/invoices/gefachdr';
import {Gerplhdr} from '@sam-base/models/invoices/gerplhdr';
import {Saemi, Saparded} from '@sam-base/models/salary';
import {Saems} from '@sam-base/models/salary/saems';
import {ProfileService} from '@shared/profile/profile.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormEvents, IwEvents, NavigationEvents} from '../../../core/events/actions';
import {StoreEvents} from '../../../core/events/actions/store.actions';
import {IwEventHubService} from '../../../core/events/core/iw-event-hub.service';
import {isRestEntity} from '../../../core/rest-api/entity/rest-entity-guards';
import * as globalActions from '../../../core/store/actions/global-form.actions';
import {IwStoreService} from '../../../core/store/iw-store.service';
import {GlobalFormState} from '../../../core/store/models/global-form.state';

type Step = 0 | 1 | -1 | 2;

// eslint-disable-next-line complexity
function isStep(n: unknown): n is Step {
    return (typeof n === 'number') && !isNaN(n) && (n === 0 || n === 1 || n === -1 || n === 2);
}

@Component({
    selector: 'iw-side-menu',
    templateUrl: './iw-side-menu.component.html',
    standalone: false
})
export class IwSideMenuComponent<T> implements OnInit {

    public navBackDisabled = true;
    public navFowardDisabled = true;
    public allowPrint = false;
    public disableEdit = false;
    public disableEditPrintTemplate = false;
    public showDelete = false;
    public showEdit = false;
    public showList = false;
    public showEditPrintTemplate = false;
    public isEditModePrintTemplate = false;
    public isEditMode = false;
    public isNewEntity = false;
    public isValid = false;
    public modifyEntityRoles: string[] = [];
    public deleteEntityRoles: string[] = [];
    public printEntityRoles: string[] = [];
    public createMis = false;
    public firstAcoMnt = 0;
    public img?: string;
    private subscriptions = new Subject();
    private readonly _restClient: RestEntityClient<Ppmis>;
    private readonly _restAcoClient: RestEntityClient<Ppaco>;
    private _sub?: Subscription;
    private _subs = new Subject();
    private gefachdrFactors: any;

    constructor(private readonly _store: IwStoreService, private readonly _eventHub: IwEventHubService<IwEvents>,
                private _viewHostRef: ViewContainerRefService, private _entityDeleteService: EntityDeleteService,
                private readonly mission: MissionCalculationsService, private readonly _modalService: ModalService,
                private _translate: TranslateService, private readonly _commandeService: CommandeService,
                private readonly _events: IwEventHubService<string>, private readonly _ppconService: PpconService,
                private readonly _rest: RestApiService, private _actions: Actions, private _toastService: ToastService,
                private _profileService: ProfileService, private readonly _sapardedService: SapardedService,
                private readonly _saemsService: SaemsService, private readonly _gefachdrService: FactureService,
                private readonly _geperService: GeperService,
                private readonly _ppempService: PpempService,
                private readonly _rappelService: RappelsService) {
        this._restClient = this._rest.getEntityClient(Ppmis);
        this._restAcoClient = this._rest.getEntityClient(Ppaco);
        this._events.forType('enteteValues')
            .pipe(takeUntil(this._subs))
            .subscribe((data: any) => {
                this.gefachdrFactors = data.payload.gefachdrFactors;
            });
        this._events.forType('loadEmpProfilePicture')
            .pipe(takeUntil(this._subs))
            .subscribe((data: any) => {
                this.loadProfilePicture();
            });
    }

    private _uuid = '';

    public get uuid() {
        return this._uuid;
    }

    @Input()
    public set uuid(v: string) {
        this.setUuid(v);
    }

    private _state?: GlobalFormState<T>;

    public get state() {
        return this._state;
    }

    public ngOnInit() {
        if (this.state?.navProfile?.entity === 'ppclimis') {
            this._restClient.getById(this.state.entityId + '')
                .subscribe((mis: Ppmis) => {
                    this.createMis = !!mis.creating;
                });
            this.subscribeToSaveEffect();
        } else if (this.state?.navProfile?.entity === 'ppaco') {
            this._restAcoClient.getById(this.state.entityId + '')
                .subscribe((aco: Ppaco) => {
                    this.firstAcoMnt = aco.montant ?? 0;
                });
        }
        this.loadProfilePicture();
    }

    public setUuid(uuid: string) {
        this._uuid = uuid;
        if (this._sub) {
            this._sub.unsubscribe();
        }
        this._sub = this._store.globalForm<T>(this._uuid)
            .state
            .subscribe(e => this.onStateUpdate(e));
    }

    public triggerNavigation(step: number) {
        if (isStep(step)) {
            this._store.dispatch(this.getNavEvent(step));
            this.loadProfilePicture();
        }
    }

    public goToEntityGrid() {
        this._eventHub.emit(NavigationEvents.ToEntity);
    }

    // eslint-disable-next-line complexity
    public openPrint() {
        if (this.isMission(this._state?.entity)) {
            this._viewHostRef.loadComponent({
                type: ModalContractComponent,
                data: {
                    title: 'imprimer',
                    onOk: (radioValue: EmailContractOptions) => {
                        if (radioValue === EmailContractOptions.Employee) {
                            this._eventHub.emit<IwEventOpenModalPayload<Ppmis>>(FormEvents.openPrintModal, {
                                report: 'CM',
                                entityId: this._state?.entityId,
                                entityName: MissionContracts.mission,
                                entity: this.state?.entity!
                            });
                        }
                        if (radioValue === EmailContractOptions.Client) {
                            this._eventHub.emit<IwEventOpenModalPayload<Ppmis>>(FormEvents.openPrintModal, {
                                report: 'CC',
                                entityId: this._state?.entityId,
                                entityName: MissionContracts.location,
                                entity: this.state?.entity!
                            });
                        }
                    }
                }
            });
        } else if (this.state?.entityType === Ppcde) {
            this._eventHub.emit<IwEventOpenModalPayload<Ppcde>>(FormEvents.openPrintModal, {
                report: 'ppcde1',
                entityId: this._state?.entityId,
                entityName: 'Ppcde',
                entity: this.state?.entity!
            });
        } else if (this.state?.entityType === Gerplhdr) {
            this._store.dispatch(new globalActions.SetLoading(this.uuid, true));
            this._rappelService.downloadGerplhdrDoc(this.state.entityId as number).subscribe({
                next: (res: any) => {
                    this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
                },
                error: (err: any) => {
                    this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
                }
            });
        } else if (this.state?.entityType === Ppemp) {
            this._eventHub.emit<IwEventOpenModalPayload<T>>(FormEvents.openPrintModal, {
                report: 'ppemp',
                entityId: this._state?.entityId,
                entityName: this.state?.navProfile?.entity!,
                entity: this.state?.entity!
            });
        } else if (this.state?.entityType === Ppcli) {
            this._ppconService.hasAuthorizedConseiller(this.state?.navProfile?.entity || '', this._state?.entityId.toString() || '', this.state?.entity)
                .subscribe(async (val: boolean) => {
                    if (val) {
                        this._eventHub.emit<IwEventOpenModalPayload<T>>(FormEvents.openPrintModal, {
                            report: 'ppcli',
                            entityId: this._state?.entityId,
                            entityName: this.state?.navProfile?.entity || '',
                            entity: this.state?.entity!
                        });
                    } else {
                        const options: ModalMessageOptions = {
                            message: [],
                            showCancel: false,
                            title: this._translate.instant('print'),
                            confirmMessage: this._translate.instant('print_no_cons'),
                            okDisabled: false
                        };
                        await this._modalService
                            .showModal(ModalMessageComponent, options);
                    }
                });
            const entityTypeMatch = ENTITIES.find(type => type === this.state?.entityType);
            this._eventHub.emit<IwEventOpenModalPayload<T>>(FormEvents.openPrintModal, {
                report: entityTypeMatch ? this.state?.entityType.name! : 'unknown_report',
                entityId: this._state?.entityId,
                entityName: this.state?.navProfile?.entity!,
                entity: this.state?.entity!
            });

        }
    }

    public setEditMode() {

        if (this._state?.entityType === Gefachdr ||
            this._state?.entityType === Saems) {

            var messageConfirm: string | undefined;
            if (this._state?.entityType === Gefachdr) {
                const gefachdr = this._state?.entity as Gefachdr;
                if (gefachdr.published === true) {
                    messageConfirm = "invoice_already_published_confirm";
                }
            }
            if (this._state?.entityType === Saems) {
                const saems = this._state?.entity as Saems;
                if (saems.published === true) {
                    messageConfirm = "fiche_sal_already_published_confirm";
                }
            }
            if (messageConfirm) {
                const options: ModalMessageOptions = {
                    message: [],
                    showCancel: true,
                    title: this._translate.instant('enregistrer'),
                    confirmMessage: this._translate.instant(messageConfirm)
                };
                this._modalService.showModal(ModalMessageComponent, options)
                    .then(() => this._store.dispatch(new globalActions.SetEditMode(this.uuid)))
                    .catch(() => "do nothing")
                return;
            }
        }
        this._store.dispatch(new globalActions.SetEditMode(this.uuid));
    }

    public setEditModePrintTemplate() {
        this._store.dispatch(new globalActions.SetEditModePrintTemplate(this.uuid));
    }

    public setReadMode() {
        if (this.isNewEntity) {
            this._store.dispatch(new globalActions.DestroyForm(this.uuid));
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.isMissionInCreation(this._state?.entity) ? this.deleteEntity() : this._store.dispatch(new globalActions.SetReadMode(this.uuid));

            this._events.emit(StoreEvents.FormEntityStoreCancelEdit);
        }
    }

    public async saveChanges() {
        if (this.isMission(this._state?.entity) || this.isMissionInCreation(this._state?.entity)) {
            this.verifyEmployeeAvailable();
        } else if (this.isPpaco(this._state)) {
            const ppaco = this._state?.entity as Ppaco;
            this.triggerPpacoValidation(ppaco);
        } else if (this.state?.entityType === Saparded) {
            this._store.dispatch(new globalActions.SetLoading(this.uuid, true));
            if (this.isNewEntity) {
                this.verifyCanAddDeduction();
            } else {
                this._store.dispatch(new globalActions.SaveEntity(this.uuid));
            }
            // } else if(this._state?.entityType === Gefachdr) {
            //   this.verifyGefachdr(this.state?.entity || new Gefachdr());
            //   this.setReadMode();
        } else if (this._state?.entityType === Saemi) {
            this._store.dispatch(new globalActions.SetLoading(this.uuid, true));
            setTimeout(() => {
                this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
                this._store.dispatch(new globalActions.SaveEntity(this.uuid));
            }, 1000);
            this._events.emit('saemi_closed');
        } else {
            // Workaround to let the form store the changes on state
            this._store.dispatch(new globalActions.SetLoading(this.uuid, true));
            setTimeout(() => {
                this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
                this._store.dispatch(new globalActions.SaveEntity(this.uuid));
            }, 1000);
        }
    }

    public async verifyGefachdr(gefachdr: Gefachdr) {
        this._store.dispatch(new globalActions.SaveEntity(this.uuid));

        // const dateCptaFromCall = (await lastValueFrom(this.getCpta(gefachdr))).dateCpta ?? '';
        //
        // const checkFacDate = await lastValueFrom(this.getFacDate(gefachdr));
        //
        // if (!gefachdr.dateFac) {
        //   this._modalService.showModal(ModalMessageComponent,
        //     this.mandatoryDateOption());
        //   this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
        //   return;
        // } else if ((this.isNewEntity || gefachdr.dateCpta !== dateCptaFromCall) && !checkFacDate) {
        //   this._modalService.showModal(ModalMessageComponent, this.incorrectPeriodOption());
        //   this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
        //   return;
        //
        // // } else if (gefachdr.cptaRef === undefined) {
        // //   this._modalService.showModal(ModalMessageComponent, this.incorrectDateOption());
        // //   this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
        // //   return;
        // }
        //
        // this._gefachdrService.update(gefachdr).subscribe(gefachdr => {
        //
        // });
        //
        // if (gefachdr.mntRabais) {
        //   if (gefachdr.mntBrut) {
        //     if (gefachdr.mntRabais < 0 || gefachdr.mntRabais > gefachdr.mntBrut) {
        //       this._modalService.showModal(ModalMessageComponent, this.incorrectRabaisOption());
        //       this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
        //     }
        //   }
        //   return;
        // } else if (gefachdr.facType === 'FA') {
        //   if (gefachdr.eleCount === 0) {
        //     this._modalService.showModal(ModalMessageComponent, this.incorrectFAElementOption());
        //     this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
        //     return;
        //   }
        //   if (gefachdr.mntBrut === 0) {
        //     this._modalService.showModal(ModalMessageComponent, this.nullMontantOption());
        //     this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
        //     return;
        //   }
        // } else if (gefachdr.nbOfPrint) {
        //   if (gefachdr.facOrnc === 'F' && gefachdr.nbOfPrint < 1) {
        //     this._gefachdrService.bvrCalcNoRef(gefachdr.facNo).subscribe();
        //   }
        // }
        //this._gefachdrService.factorsDataChanged(this.gefachdrFactors).subscribe();

    }

    public verifyCanAddDeduction() {

        if (this.state?.entityType === Saparded) {

            this._sapardedService.canAddDeduction(this.state.entity || new Saparded())
                .subscribe({
                    next: async (canAdd: boolean) => {
                        if (canAdd) {
                            this._store.dispatch(new globalActions.SaveEntity(this.uuid));
                        } else {
                            const options: ModalMessageOptions = {
                                message: ['cannot_add_saparded'],
                                showCancel: false,
                                title: this._translate.instant('saparded')
                            };
                            try {
                                await this._modalService
                                    .showModal(ModalMessageComponent, options);
                                this._store
                                    .dispatch(new globalActions.SetLoading(this.uuid, false));
                            } catch (error) {
                                this._store
                                    .dispatch(new globalActions.SetLoading(this.uuid, false));
                            }
                        }
                    },
                    error: () => this._store
                        .dispatch(new globalActions.SetLoading(this.uuid, false))
                });

        }


    }

    // eslint-disable-next-line complexity
    public verifyEmployeeAvailable() {

        if (this.isMission(this._state?.entity)) {
            const misId = this._state?.entity.misId ?? '';
            const empId = this._state?.entity.empId ?? '';
            const isCalculating = this._state?.entity.isCalculating ?? false;

            if (isCalculating) {
                // eslint-disable-next-line max-len
                this._toastService.warning(this._translate.instant('please_wait_end_of_calculation_before_save'));
                return;
            }
            this._store.dispatch(new globalActions.SetLoading(this.uuid, true));
            this.mission.getEmployeeAvailabilityForCurrentMission(misId, empId)
                .subscribe({
                    next: async availability => {
                        /**
                         * Verify if employee availability  is 100%
                         */
                        this._store.dispatch(new globalActions.SaveEntity(this.uuid));
                        if (availability !== 100) {
                            const options: ModalMessageOptions = {
                                message: [],
                                showCancel: false,
                                title: this._translate.instant('enregistrer'),
                                confirmMessage: this._translate.instant('saveMission')
                            };
                            try {
                                await this._modalService
                                    .showModal(ModalMessageComponent, options);
                            } catch (error) {
                                this._store
                                    .dispatch(new globalActions.SetLoading(this.uuid, false));
                            }
                        }
                        this.verifyCommandIsSatisfied();
                    },
                    error: () => this._store
                        .dispatch(new globalActions.SetLoading(this.uuid, false))
                });

        }


    }

    public async deleteEntity() {
        if (!(await this._entityDeleteService
            .openEntityDeleteDialog(this._state?.entity))) {
            return;
        }
        if (this.state?.entityType === Saems) {
            this._store.dispatch(new globalActions.SetLoading(this.uuid, true));
            const saems = this._state?.entity as Saems;
            this._saemsService.deleteSaems(saems.empId, saems.salId)
                .subscribe({
                    next: () => {
                        this._store.dispatch(new globalActions.DestroyForm(this.uuid));
                    },
                    error: err => {
                    }
                });
            this._store.dispatch(new globalActions.SetLoading(this.uuid, false));
        } else {
            this._store.dispatch(new globalActions.DeleteEntity(this.uuid));
            this._store.dispatch(new globalActions.DestroyForm(this.uuid));
        }
    }

    private loadProfilePicture() {
        if (this.state?.navProfile?.entity !== 'ppemp' && this.state?.navProfile?.entity !== 'saempview') return;
        if (!this.state?.entityId) return;
        this._ppempService.loadProfilePicture(this.state?.entityId + '').subscribe(pic => {
            this.img = pic;
        });
    }

    private verifyCommandIsSatisfied(): void {
        this._commandeService
            .isCommandeSatisfied(this._state?.entityId.toString() || '')
            .subscribe(async (res: boolean) => {
                if (res) {
                    this.checkCommandClosed();
                }
            });
    }

    private checkCommandClosed(): void {
        const options: ModalMessageOptions = {
            message: [],
            showCancel: true,
            title: this._translate.instant('commande'),
            confirmMessage: this._translate.instant('commande_satisfied'),
            okDisabled: false
        };
        if (!this._state?.entity) {
            return;
        }
        const mis: Ppmis = this._state?.entity;
        this._commandeService
            .isCommandeClosed(mis.cdeId || '')
            .subscribe(async (isClosed: boolean) => {
                try {
                    if (!isClosed) {
                        await this._modalService
                            .showModal(ModalMessageComponent, options);
                        this._commandeService.closeCommande(mis.cdeId || '')
                            .subscribe((cde: Ppcde) => {
                                this._events.emit('commande_closed', mis.cdeId);
                            });
                    }
                } catch (error) {
                }
            });
    }

    private onStateUpdate(state: GlobalFormState<T>) {
        this._state = state;

        const entityName = this.state?.navProfile?.entity ?? GridProfile.fromType(this.state?.entityType!).entity;

        this.modifyEntityRoles = canModifyEntityAccessRoles(entityName);
        this.deleteEntityRoles = canDeleteEntityAccessRoles(entityName);
        this.printEntityRoles = canPrintEntityAccessRoles(entityName);
        this.allowPrint = (!!state.diagSideMenu.showPrint && entityName !== 'ppclimis') || (entityName === 'ppclimis' && !this.createMis);

        this.showEdit = !!state.diagSideMenu.showEdit;
        this.showDelete = !!state.diagSideMenu.showDelete;
        this.showList = !!state.diagSideMenu.showList;

        this.disableEdit = state.readonly;

        this.isValid = state.isValid;

        this.isNewEntity = state.isNew;
        this.isEditMode = state.editMode === 'edit';
        if (state.useNavigation) {
            this.updateNav(state);
        }
    }

    private updateNav(state: GlobalFormState<T>) {
        const id = state.entityId;
        const min = 0;
        const max = state.navValues.length - 1;

        const current = state.navValues.findIndex(e => e === id);
        this.navBackDisabled = current <= min || this.isEditMode;
        this.navFowardDisabled = current >= max || this.isEditMode;
    }

    private getNavEvent(step: Step) {
        switch (step) {
            case -1:
                return new globalActions.NavigationPrev(this.uuid);
            case 0:
                return new globalActions.NavigationFirst(this.uuid);
            case 1:
                return new globalActions.NavigationNext(this.uuid);
            case 2:
                return new globalActions.NavigationLast(this.uuid);
        }
    }

    private getFacDate(gefachdr: Gefachdr): Observable<boolean> {
        return this._geperService.checkFacDate(gefachdr.salId);
    }

    private getCpta(gefachdr: Gefachdr): Observable<Gefachdr> {
        return this._gefachdrService.getByfacId(gefachdr.facId);
    }

    private mandatoryDateOption(): ModalMessageOptions {
        return {
            message: ['date_obligatoire'],
            showCancel: false,
            title: this._translate.instant('factureNC')
        };
    };

    private salaryDeleteOptions(): ModalMessageOptions {
        return {
            message: ['salary_not_deleted'],
            showCancel: false,
            title: this._translate.instant('salary')
        };
    };

    private nullMontantOption(): ModalMessageOptions {
        return {
            message: ['montant_est_nul'],
            showCancel: false,
            title: this._translate.instant('factureNC')
        };
    }

    private incorrectFAElementOption(): ModalMessageOptions {
        return {
            message: ['facture_na_pas_d_elements'],
            showCancel: false,
            title: this._translate.instant('factureNC')
        };
    }

    private incorrectRabaisOption(): ModalMessageOptions {
        return {
            message: ['rabais_incorrect'],
            showCancel: false,
            title: this._translate.instant('factureNC')
        };
    }

    private incorrectDateOption(): ModalMessageOptions {
        return {
            message: ['date_incorrete'],
            showCancel: false,
            title: this._translate.instant('factureNC')
        };
    }

    private incorrectPeriodOption(): ModalMessageOptions {
        return {
            message: ['periode_incorrecte_fermée'],
            showCancel: false,
            title: this._translate.instant('factureNC')
        };
    }


    private isMissionInCreation(e?: unknown): boolean {
        return !!(this.isMission(e) && e.creating);
    }

    private isMission(e: unknown): e is Ppmis {
        return isRestEntity(e) && e.$entity === 'ppmis';
    }

    private isPpaco(e?: GlobalFormState<T>) {
        return e?.entityType === Ppaco;
    }


    // eslint-disable-next-line complexity
    private async triggerPpacoValidation(aco: Ppaco) {
        const userHasAcompteAdminRights = await this._profileService.checkUserPermission([SamUserRole.SAM_PLACEMENT_ACOMPTE_ADMIN]);
        if ((aco.montant && aco.amoutAllowed && aco.montant > aco.amoutAllowed && this._state?.isNew) || (aco.montant && aco.amoutAllowed && aco.montant > (aco.amoutAllowed + this.firstAcoMnt) && !this._state?.isNew) || !aco.montant || !aco.amoutAllowed) {
            const options: ModalMessageOptions = {
                message: [this._translate.instant('ppaco_amount_exceeded')],
                title: '',
                showCancel: true,
                okDisabled: !userHasAcompteAdminRights
            };

            try {
                await this._modalService.showModal(ModalMessageComponent, options);
                this._store.dispatch(new SetLoading(this.uuid, true));
                this._store.dispatch(new globalActions.SaveEntity(this.uuid));
            } catch (e) {
                this._store.dispatch(new SetLoading(this.uuid, false));
            }
        } else {
            this._store.dispatch(new SetLoading(this.uuid, true));
            this._store.dispatch(new globalActions.SaveEntity(this.uuid));
        }


    }

    private subscribeToSaveEffect() {
        this._actions
            .pipe(takeUntil(this.subscriptions), ofType(actions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS))
            .subscribe((v) => {
                const uuid = v['uuid'] as string || '';

                if (uuid !== this.uuid) {
                    return;
                }
                this._restClient.getById(this.state?.entityId + '')
                    .subscribe((data: Ppmis) => {
                        this.createMis = !!data.creating;
                        this._store.globalForm<T>(this._uuid)
                            .state
                            .subscribe(e => this.onStateUpdate(e));
                    });
            });
    }
}
