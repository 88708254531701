import {Component, EventEmitter, OnInit} from '@angular/core';
import {PpconService} from '@app/sam-base/core/services/ppcon.service';
import {ComboboxItem, ModalComponent} from '@app/sam-base/models';
import {Ppcon} from '@app/sam-base/models/placement';

import {ModalConsChangeOptions} from './modal-cons-change.options';

@Component({
    templateUrl: './modal-cons-change.component.html',
    standalone: false
})
export class ModalConsChangeComponent implements ModalComponent<string, ModalConsChangeOptions>, OnInit {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public consDropdownOptions: ComboboxItem<string>[] = [];
    public selectedConseiller?: string;

    private _options!: ModalConsChangeOptions;
    private _ok?: (result: string) => void;
    private _cancel?: (error?: any) => void;

    constructor(private _ppconService: PpconService) {
    }

    public get title() {
        return this._options.title;
    }

    public get conId() {
        return this._options.conId;
    }

    public ngOnInit() {
        this.setDropdownValues();
    }

    public onPressOk() {
        if (this._ok && this.selectedConseiller) {
            this._ok(this.selectedConseiller);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public async setData(data: ModalConsChangeOptions): Promise<void> {
        this._options = data;
    }

    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    private setDropdownValues() {
        this._ppconService.getFullConseillerList()
            .subscribe((cons: Ppcon[]) => {
                this.consDropdownOptions = this.mapAndSortConseillers(cons);
                if (this.consDropdownOptions.length) {
                    this.selectedConseiller = this.consDropdownOptions[0].value;
                }
            });
    }

    private mapAndSortConseillers(cons: Ppcon[]): ComboboxItem<string>[] {
        return (cons
            .filter((con: Ppcon) => con.conId !== this.conId)
            .map((con: Ppcon) => ({
                name: `${con.nom ?? ''} ${con.prenom ?? ''}`,
                value: con.conId ?? ''
            }))
            .sort((a, b) => a.name.localeCompare(b.name)));
    }
}
