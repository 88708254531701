import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {
    EntityDelete,
    IwEventHubService,
    IwStoreService,
    RestApiService,
    RestEntityClient,
    RestQueryOperation,
    RestQueryParam
} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys, ObjectMap} from '@app/sam-base/models';
import {RadioItem} from '@app/sam-base/models/components/radio-item.model';
import {Ppclimis, ScheduleEvent} from '@app/sam-base/models/placement';
import {IwTimeSlotsComponent} from '@shared/widgets/iw-components/iw-time-slots/iw-time-slots.component';
import {IwWeekdaysPickerComponent} from '@shared/widgets/iw-components/iw-weekday-picker/iw-weekday-picker.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {MatchingService} from '../../services/matching.service';

export type CalendarType = 'flexible' | 'regular';

@Component({
    selector: 'iw-schedule-event',
    templateUrl: './schedule-event.component.html',
    standalone: false
})
export class ScheduleEventComponent extends BaseStoreFormComponent<ScheduleEvent> implements OnInit, OnDestroy {

    public calendarTypeOptions: RadioItem<CalendarType>[] = [
        {
            label: 'calendar_flexible',
            value: 'flexible',
            checked: false
        },
        {
            label: 'calendar_regular',
            value: 'regular',
            checked: true
        }];
    /** Query to filter results */
    public query: RestQueryParam<ScheduleEvent, any>[] = [];
    @ViewChild('scheduleEventGrid') public grid?: IwRestGridComponent<ScheduleEvent>;
    @ViewChild('weekdayPicker', {static: false}) public weekdayPicker?: IwWeekdaysPickerComponent;
    @ViewChild('timeSlots') public timeSlotsPicker?: IwTimeSlotsComponent;
    public entity = ScheduleEvent;
    public schedule?: (1 | 0)[];
    public weekdays?: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
    public selectedRow?: ScheduleEvent;
    public hasAbs = false;
    public absCode: string | undefined = '';
    public entityPpclimis = Ppclimis;
    public filterPpclimis?: ObjectMap<Ppclimis>;
    public minEndDate: Date | null = null;
    private readonly _restClient: RestEntityClient<ScheduleEvent>;
    private subscriptions = new Subject();

    constructor(store: IwStoreService, rest: RestApiService, private _matchingService: MatchingService,
                private readonly _events: IwEventHubService<string>, private fb: FormBuilder) {
        super(store);
        this._restClient = rest.getEntityClient(ScheduleEvent);
    }

    public get parentId(): string | undefined {
        return this.getData('parentId') || '';
    }

    public get parentEntity(): 'ppemp' | 'ppmis' | 'ppcde' | undefined {
        return this.getData('parentEntity') ?? 'ppemp';
    }

    /** To indicate if describes an absence or avaiability */
    public get isAvailability() {
        return this.getData('isAvailability') ?? false;
    }

    /** When true you can only CRUD one entry */
    public get singleEntryMode() {
        return this.getData('parentEntity') !== 'ppemp';
    }

    public get parentUuid(): string {
        return this.getData('formUuid') ?? '';
    }

    /** Component total height */
    public get height() {
        return this.getData('height') || '200px';
    }

    public get btnDeleteDisabled() {
        return !this.selectedRow?.id;
    }

    public get btnEditDisabled() {
        return !this.selectedRow?.id;
    }

    public get isReady() {
        return this.canSave();
    }

    private _calendarType: CalendarType = 'regular';

    public get calendarType(): CalendarType {
        return this._calendarType;
    }

    public set calendarType(type: CalendarType) {
        this._calendarType = type;
    }

    public ngOnInit() {
        const id = this.parentId;
        if (typeof id === 'string') {
            this.query = [
                {
                    operation: RestQueryOperation.Equals,
                    prop: 'parentId',
                    value: id
                }];
        }

        this.subscribeAbsType();

        if (this.singleEntryMode) {
            this.setSingleEntry();
        }
        this.setRequired();
        this.filterPpclimis = {dateFlexible: 'true'};

        // Listen to dateStart changes
        this.formGroup.get('dateStart')?.valueChanges.subscribe((dateStart) => {
            if (dateStart) {
                this.minEndDate = new Date(dateStart); // Set the minimum end date
            } else {
                this.minEndDate = null;
            }

            const dateEndControl = this.formGroup.get('dateEnd');
            if (dateEndControl?.value && dateEndControl.value < dateStart) {
                dateEndControl.setValue(null); // Reset dateEnd if it's earlier
            }
        });

        /*
        this.formGroup = this.fb.group({
            dateStart: [
                '',
                Validators.required],
            dateEnd: [
                '',
                Validators.required],
        }, {validators: this.dateRangeValidator});

        this.formGroup.get('dateEnd')?.valueChanges.subscribe(value => {
            if (!value) {
                this.formGroup.get('dateEnd')?.clearValidators();
            } else {
                this.formGroup.get('dateEnd')?.setValidators(Validators.required);
            }
            this.formGroup.get('dateEnd')?.updateValueAndValidity();
        });
        */
    }

    /*
    private dateRangeValidator(form: FormGroup) {
        const dateStart = form.get('dateStart')?.value;
        const dateEnd = form.get('dateEnd')?.value;
        // No dateEnd, no check
        if (!dateEnd) {
            return null;
        }
        return dateEnd && dateStart && new Date(dateEnd) < new Date(dateStart)
            ? {dateRangeInvalid: true}
            : null;
    }

    public get isDateRangeInvalid() {
        return this.formGroup.errors?.dateRangeInvalid;
    }
    */

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public onCalendarTypeChange(type: CalendarType) {
        this._calendarType = type;
        this.setFormValue('flexible', (type === 'flexible'));
        this.setRequired();
        this.clearOtherCalendarType();
    }

    /** On item select */
    public onSelect(item: ScheduleEvent[]) {
        const row = item[0];
        const id = row?.id;
        if (id) {
            this.selectedRow = item[0];
            this.fillEntireForm();
            this._store.dispatch(new actions.NavigationEntity(this.uuid, id));
        } else {
            this.clearEntireForm();
        }
    }

    public onAdd() {
        this.clearEntireForm();
        if (this.uuid) {
            this._store.dispatch(new actions.SetNewMode(this.uuid));
        }
        this.clearSelection();
    }

    public onEdit() {
        if (this.uuid) {
            this._store.dispatch(new actions.SetEditMode(this.uuid));
        }
    }

    public getFormData() {
        const weekday = this.getWeekdayFormValue();
        const schedule = this.schedule;
        const parentId = this.parentId;
        const parentType = this.parentEntity;
        const absType = this.getFormValue('absType');
        const formData = super.getFormData();
        const id = this.selectedRow?.id;

        const baseData = {
            parentId,
            parentType,
            absType,
            schedule,
            weekday,
            id
        };

        const updatedData = this._restClient
            .construct({...formData, ...baseData});

        return updatedData;
    }

    public onSave() {
        this.saveChanges();
        const sub = this._store.globalForm<ScheduleEvent>(this.uuid)
            .isLoading
            .subscribe(l => {
                if (l) {
                    return;
                }
                this.clearEntireForm();
                this.setRequired();
                this._store.dispatch(new actions.SetWaitModeWithData(this.uuid));
                sub.unsubscribe();
                this._events.emit('schedule_add');
            });
    }

    public onCancel() {
        if (this.uuid) {
            this._store.dispatch(new actions.SetWaitModeWithData(this.uuid));
        }

        this.clearEntireForm();
    }

    public onDelete() {
        if (this.selectedRow?.id && this.uuid) {
            this._store.dispatch(new EntityDelete(ScheduleEvent, this.selectedRow.id));
            this._store.dispatch(new actions.SetWaitModeWithData(this.uuid));
            this.clearForm();
            this.weekdayPicker?.reset();
            this.timeSlotsPicker?.reset();
            this.selectedRow = undefined;

            this._matchingService
                .updateNeedSummary(this.selectedRow, this.parentUuid);
            this._events.emit('schedule_add');
        }
    }

    public onClose() {
        if (this.singleEntryMode) {
            this._matchingService
                .updateNeedSummary(this.selectedRow, this.parentUuid);
        }
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public setSchedule(value: (1 | 0)[]) {
        this.schedule = value;
        this.onFormChange();
    }

    public setWeekdays() {
        this.weekdays = this.weekdayPicker?.extractSelections();
        this.onFormChange();
    }

    public getDaysTitle() {
        if (this.isAvailability) {
            return 'select_days_available_title';
        } else {
            return 'select_days_unavailable_title';
        }
    }

    public getHoursTitle() {
        if (this.isAvailability) {
            return 'select_hours_available_title';
        } else {
            return 'select_hours_unavailable_title';
        }
    }

    public isFlexMission() {
        return this.getFormValue('absType') === 'M';
    }

    protected getValidationType() {
        return ScheduleEvent;
    }

    protected validateFields(e: ScheduleEvent): boolean {
        return super.validateFields(e);
    }

    protected setRequired(): void {
        this.formGroup.controls['dateStart'].setValidators(Validators.required);
        this.formGroup.controls['dateStart'].updateValueAndValidity();
        if (this.parentEntity === 'ppemp') {
            this.formGroup.controls['dateEnd'].setValidators(Validators.required);
            this.formGroup.controls['dateEnd'].updateValueAndValidity();
        }
        if (this.getFormValue('flexible')) {
            this.setFormValue('weeklyHours', 40);
            this.formGroup.controls['weeklyHours'].setValidators(Validators.required);
            this.formGroup.controls['weeklyHours'].updateValueAndValidity();
            this.setFormValue('ocupation', 100);
            this.formGroup.controls['ocupation'].setValidators(Validators.required);
            this.formGroup.controls['ocupation'].updateValueAndValidity();
        } else {
            this.setFormValue('weeklyHours', undefined);
            this.formGroup.controls['weeklyHours'].clearValidators();
            this.formGroup.controls['weeklyHours'].updateValueAndValidity();
            this.setFormValue('ocupation', undefined);
            this.formGroup.controls['ocupation'].clearValidators();
            this.formGroup.controls['ocupation'].updateValueAndValidity();
            this.setFormValue('additionalText', undefined);
        }
    }

    protected getFormControlNames(): FormKeys<ScheduleEvent> {
        return [
            'weekday',
            'schedule',
            'dateStart',
            'dateEnd',
            'absType',
            'dateModif',
            'parentId',
            'parentType',
            'id',
            'flexible',
            'weeklyHours',
            'ocupation',
            'flexMisid',
            'additionalText'];
    }

    private subscribeAbsType() {
        this.formGroup.controls['absType']
            .valueChanges.pipe(takeUntil(this.subscriptions))
            .subscribe(() => {
                const absValue = this.getFormValue('absType');
                if (absValue && absValue !== 'M') {
                    this.hasAbs = true;
                    this.timeSlotsPicker?.selectAllHours();
                }
            });
    }

    private getWeekdayFormValue() {
        if (this.calendarType === 'flexible' || !this.weekdayPicker) {
            return [
                0,
                1,
                2,
                3,
                4,
                5,
                6] as (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
        }
        return this.weekdayPicker.extractSelections();
    }

    private hasDates() {
        return !!this.getFormValue('dateStart') && !!this.getFormValue('dateEnd');
    }

    private hasSchedule() {
        return !!this.schedule?.filter(e => e !== 0).length && !!this.weekdays?.length;
    }

    private isFilled() {
        return this.hasSchedule() || (this.getFormValue('weeklyHours') !== null && this.getFormValue('weeklyHours') !== undefined &&
            this.getFormValue('ocupation') !== null && this.getFormValue('ocupation') !== undefined);
    }

    // eslint-disable-next-line complexity
    private canSave() {
        if (this.isAvailability) {
            return this.isFilled() && !!this.getFormValue('dateStart');
        } else {
            return !!this.weekdays?.length && this.hasSchedule() && this.hasDates();
        }
    }

    private setSingleEntry() {
        const parentId = this.parentId;
        const parentEntity = this.parentEntity;
        this.setLoading(true);
        if (!parentId || !parentEntity) {
            return;
        }

        this._matchingService
            .getScheduleEvent(parentId, parentEntity)
            .subscribe((res: ScheduleEvent[]) => {
                this.selectedRow = res[0];
                if (!this.selectedRow || !this.selectedRow.id) {
                    this.setLoading(false);
                    return;
                }
                this.calendarType = this.selectedRow?.flexible ? 'flexible' : 'regular';

                this._store.dispatch(new actions.NavigationEntity(this.uuid, this.selectedRow.id));
                this.fillEntireForm();
            });
    }

    private setLoading(loadingState: boolean) {
        this._store.dispatch(new actions.SetLoading(this.uuid, loadingState));
    }

    private clearSelection() {
        setTimeout(() => {
            if (this.grid) {
                this.grid.clearSelection();
            }
        }, 0);
    }

    private clearEntireForm() {
        if (!this.singleEntryMode) {
            this.clearForm();
            this.hasAbs = false;
            this.absCode = undefined;
            this.setFormValue('absType', undefined);
            this.weekdayPicker?.reset();
            this.timeSlotsPicker?.reset();
            this.selectedRow = undefined;
        }

        if (this.singleEntryMode) {
            this.setSingleEntry();
        }
    }

    private clearOtherCalendarType() {
        if (!this.singleEntryMode) {
            return;
        }

        if (this.calendarType === 'flexible') {
            this.weekdayPicker?.reset();
            this.timeSlotsPicker?.reset();
        } else if (this.calendarType === 'regular') {
            this.setFormValue('weeklyHours', undefined);
            this.setFormValue('ocupation', undefined);
        }
    }

    private fillEntireForm() {

        if (!this.selectedRow) {
            return;
        }

        const schedule = this.selectedRow?.schedule;
        const weekday = this.selectedRow?.weekday;

        if (schedule) {
            this.timeSlotsPicker?.setSelectedHours(schedule);
        }
        if (weekday) {
            this.weekdayPicker?.setSelections(weekday);
        }
    }
}
