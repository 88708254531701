import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PpempService} from '@modules/sam-main/placement/services/ppemp.service';

import {buildCustomValueProvider} from '../../base/build-value-provider';
import {MouseOverDetails} from './mouse-over-details.model';

@Component({
    selector: 'iw-mouse-over-details',
    templateUrl: './mouse-over-details.component.html',
    providers: [buildCustomValueProvider(IwMouseOverDetailsComponent)],
    standalone: false
})
export class IwMouseOverDetailsComponent implements OnInit {

    @Input() public show = true;

    @Input() public showBusy = true;

    @Input() public details?: MouseOverDetails;

    @Input() public loading = false;

    @Output() public loadingChange = new EventEmitter<boolean>();

    public imgSrc?: string;
    public ajustTop = 6;

    constructor(private readonly _ppempService: PpempService) {
    }

    public async ngOnInit() {
        this.imgSrc = '../../../../assets/default_avatar.png';
        await this.setLoading(true);
        await this.checkImgSrc();
    }

    public async checkImgSrc() {
        await this.setLoading(false);
        if (!this.details || !this.details.empId) {
            return;
        }

        this._ppempService.loadProfilePicture(this.details.empId).subscribe(e => this.imgSrc = e);
    }

    public setLoading(v: boolean) {
        return new Promise<void>(r => {
            if (v !== this.loading) {
                this.loading = !!v;
                this.loadingChange.emit(v);
            }
            r();
        });
    }
}
