import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SortDirection} from '@app/sam-base/models';
import {Gltvadef} from '@app/sam-base/models/accounting/gltvadef';

@Component({
    selector: 'iw-gltvadef-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwGltvadeftypeDropdownComponent)],
    standalone: false
})
export class IwGltvadeftypeDropdownComponent extends BaseEntityDropdown<Gltvadef> {

    @Input() public width?: string | number;
    public selectedValue = undefined;

    constructor() {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    @Input()
    public set types(v: string[]) {
        if (v.length) {
            // Filter by type
            this.resultFilters = v ? (e: Gltvadef) => v.includes(e.tvaCode ?? '') && (e.tvaDue || true) : (_: Gltvadef) => true;
        } else {
            // Show all sagstypes
            this.resultFilters = e => true;
        }
    }

    public getSortProperty(): keyof Gltvadef {
        return 'tvaCode';
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    public getExtraProperties(): (keyof Gltvadef)[] {
        return ['tvaCode'];
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType() {
        return Gltvadef;
    }

    protected buildLabel(e: Gltvadef): string {
        return e.tvaCode ?? '';
    }
}
