import {Component, ElementRef, Input} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Lookups} from '@app/sam-base/models/common';
import {map} from 'rxjs/operators';

import {IwTextfieldListComponent} from './iw-textfield-list.component';

@Component({
    templateUrl: './iw-textfield-list.component.html',
    selector: 'iw-textfield-action',
    providers: [buildCustomValueProvider(IwTextfieldActionComponent)],
    standalone: false
})
export class IwTextfieldActionComponent extends IwTextfieldListComponent<Lookups> {

    @Input() public lkname?: string;

    constructor(elRef: ElementRef, modalService: ModalService, restService: RestApiService) {
        super(modalService, elRef, restService);
        this.propsToFilter = ['cvalue'];
        this.listOption = {
            columns: [{prop: 'cvalue', name: ''}],
            map: (e: Lookups) => e.cvalue || '',
            selection: 'single',
            fetchAction: () => restService
                .getEntityClient(Lookups)
                .getRefData()
                .pipe(map(l => l.filter(e => e.lkupname === this.lkname)
                    .sort((a, b) => (a.cvalue || '') < (b.cvalue || '') ? -1 : 1)))
        };
    }
}
