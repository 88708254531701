import {Component, ElementRef, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {RestApiService} from '@sam-base/core';
import {Ppsecteur} from '@sam-base/models/placement';

@Component({
    selector: 'iw-secteur-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwSecteurDropdownComponent)],
    standalone: false
})
export class IwSecteurDropdownComponent extends BaseEntityDropdown<Ppsecteur> {
    @Input() public width?: string | number;

    @Input() public warningMessage?: string;

    constructor(private elRef: ElementRef, private readonly restService: RestApiService) {
        super();
        this.hasEmptyChoice = true;
        this.emptyChoice = {
            secLib: '-'
        };
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    protected getEntityType() {
        return Ppsecteur;
    }

    protected buildLabel(e: Ppsecteur): string {
        return `${e.secLib ? e.secLib : ''}`;
    }
}
