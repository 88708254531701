import {Component, ElementRef, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SortDirection} from '@app/sam-base/models';
import {Ppcct} from '@app/sam-base/models/placement/ppcct';

@Component({
    selector: 'iw-ctt-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwCttDropdownComponent)],
    standalone: false
})
export class IwCttDropdownComponent extends BaseEntityDropdown<Ppcct> {
    @Input() public width?: string | number;
    public selectedValue = undefined;

    constructor(private elRef: ElementRef) {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    @Input()
    public set status(v: string[]) {
        if (v.length) {
            this.resultFilters = v ? (e: Ppcct) => v.includes(e.cctstatus ?? '') : (_: Ppcct) => true;
        } else {
            this.resultFilters = (e) => true;
        }
    }

    public getSortProperty(): keyof Ppcct {
        return 'cctId';
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType() {
        return Ppcct;
    }

    protected buildLabel(e: Ppcct): string {
        return e.cctId + '  |  ' + e.cctLib;
    }
}
