import {Component, Input, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ContextMenu} from 'primeng/contextmenu';

import {BaseEventMenuComponent} from '../../base/base-event-menu.component';
import {IwEventHubService} from '../../core/events';

@Component({
    selector: 'iw-context-menu',
    templateUrl: 'iw-context-menu.component.html',
    standalone: false
})
export class IwContextMenuComponent extends BaseEventMenuComponent {

    @Input() public contextMenuEnabled = true;
    @Input() public popup = false;

    @Input() public appendTo = 'body';

    @ViewChild('menu', {static: true}) public _menu?: ContextMenu;

    constructor(eventsHub: IwEventHubService<string>, translation: TranslateService) {
        super(eventsHub, translation);
    }

    public async show(event: MouseEvent) {
        if (this._menu) {
            this._menu.show(event);
        }
        return Promise.resolve();
    }

    public async hide() {
        if (this._menu) {
            this._menu.hide();
        }
        return Promise.resolve();
    }

    public async toggle(event: MouseEvent) {
        if (this._menu) {
            this._menu.toggle(event);
        }
        return Promise.resolve();
    }
}
