import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';

@Component({
    selector: 'iw-cell-status-color',
    templateUrl: './iw-cell-status.component.html',
    providers: [buildCustomValueProvider(IwCellStatusColorComponent)],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IwCellStatusColorComponent<T> implements OnInit {

    @Input() public row?: T;

    @Input() public color = '#80ff80'; // Default color

    @Input() public value: any;
    @Input() colorMapper: ((value: any) => string) | undefined;

    constructor() {
    }

    public ngOnInit() {
        this.checkColor(this.row!);
    }

    public checkColor(row: T) {
        if (this.colorMapper) {
            this.color = this.colorMapper(row);
        }
    }
}
