import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SortDirection} from '@app/sam-base/models';

import {Sacs06def} from '@app/sam-base/models/common/sacs06def';

@Component({
    selector: 'iw-sacs06def-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwSacs06defDropdownComponent)],
    standalone: false
})
export class IwSacs06defDropdownComponent extends BaseEntityDropdown<Sacs06def> {

    @Input() public width?: string | number;
    public selectedValue = undefined;

    constructor() {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    @Input()
    public set types(v: string[]) {
        if (v.length) {
            this.resultFilters = v ? (e: Sacs06def) => this.filterContainsCs06Type(v, e.cs06Type) : (_: Sacs06def) => true;
        } else {
            this.resultFilters = e => true;
        }
    }

    public getSortProperty(): keyof Sacs06def {
        return 'titre';
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType() {
        return Sacs06def;
    }

    protected buildLabel(e: Sacs06def): string {
        return e.titre || '';
    }

    private filterContainsCs06Type(filter: string[], cs06Type?: string) {
        for (const value of filter) {
            if (cs06Type?.includes(value)) {
                return true;
            }
        }
        return false;
    }
}
