import {EmailContact} from '@modules/sam-main/placement/components/mail-composer/mail-composer-models';

export enum CommunicationType {
    PPCLIATT_GENERIC = 'PPCLIATT_GENERIC',
    PPCLI_GENERIC = 'PPCLI_GENERIC',
    PPEMP_GENERIC = 'PPEMP_GENERIC',
    SAEMS_BS = 'SAEMS_BS',
    GEFACHDR_FAC = 'GEFACHDR_FAC',
    PPMIS_CM = 'PPMIS_CM',
    PPMIS_CL = 'PPMIS_CL',
    GECLI_GENERIC = 'GECLI_GENERIC',
    GECLIATT_GENERIC = 'GECLIATT_GENERIC',
    SAEMP_GENERIC = 'SAEMP_GENERIC',
    SAEMP_BS_CURYEAR = 'SAEMP_BS_CURYEAR',
    SAEMP_LAA_BS = 'SAEMP_LAA_BS',
    SAEMP_PGM_BS = 'SAEMP_PGM_BS',
    SEND_OPEN_INVOICES = 'SEND_OPEN_INVOICES',
    AGI_EMAIL = 'AGI_EMAIL',
    ACA_EMAIL = 'ACA_EMAIL',
    GERPLHDR_RPL = 'GERPLHDR_RPL'
}

export interface MailConfigRequest {
    communicationType: CommunicationType;
    entityIds: string[];
}

export interface MailConfigResponse {
    smtpConfigId: number;
    replyTo: EmailContact[];
    bcc: EmailContact[];
    cc: EmailContact[];
    subject: string;
    body: string;
    attachments: Attachment[];
    signature: string;
    suiviAllowed: boolean;
    generateSuivi: boolean;
}

export interface EmailRequest {
    smtpConfigId: number;
    communicationType: CommunicationType;
    replyTo: string;
    to: EmailContact[];
    cc: EmailContact[];
    bcc: EmailContact[];
    subject: string;
    body: string;
    attachments: Attachment[];
    htmlSignature: string;
    entityIds: string[];
    sendSuivi: boolean;
}

// old school
export interface MailResponse {
    sendUsers: string[];
    cc: string[];
    bcc: string[];
    signature: string;
    preview: string;
}


export interface Attachment {
    fileName: string;
    fileContent: string;
    resourcePath: string;
    resourceUrl: string;
}

export interface MailSend {
    from: string;
    emailSubject?: string;
    to?: string[];
    cc?: string[];
    bcc?: string[];
    emailBody: string;
    attachments?: Attachment[];
    htmlSignature?: string;
    idList?: string[];
    entityType?: string;
    sendSuivi?: boolean;
    dateRappel?: string;
    conId?: string;
    cdeId?: string;
    entityId?: string;
    cliId?: string;
}
