import {Component, EventEmitter} from '@angular/core';
import {ModalComponent} from '@app/sam-base/models';
import {HtmlElementStatus} from '@app/sam-base/models/html-element-status.model';

import {ModalMessageOptions} from './modal-message.options';

@Component({
    selector: 'iw-modal-message', templateUrl: './modal-message.component.html',
    standalone: false
})
export class ModalMessageComponent implements ModalComponent<void, ModalMessageOptions> {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public okDisabled: HtmlElementStatus = {
        enabled: true, value: 'Ok', visible: true
    };
    public cancelShow: HtmlElementStatus = {
        enabled: true, value: 'Cancel', visible: true
    };

    private _options!: ModalMessageOptions;
    private _ok?: (result: void) => void;
    private _cancel?: (error?: any) => void;

    public get message() {
        return this._options.message;
    }

    public get title() {
        return this._options.title;
    }

    public get confirmMessage() {
        return this._options.confirmMessage;
    }

    public get alertsMessage() {
        return this._options.alertsMessage;
    }

    public get width() {
        return this._options.width;
    }

    public get textarea() {
        return this._options.textarea;
    }

    public get imgPath() {
        return this._options.imgPath;
    }

    public get style() {
        return this._options.style;
    }

    public get multiline() {
        return this._options.multiline;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok();
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public async setData(data: ModalMessageOptions): Promise<void> {
        this._options = data;
        if (this._options.okDisabled) {
            this.okDisabled = {
                ...this.okDisabled, enabled: false, visible: true
            };
        }

        if (!this._options.showCancel) {
            this.cancelShow = {
                ...this.cancelShow, enabled: false, visible: false
            };
        }

        if (this._options.cancelMessage) {
            this.cancelShow = {
                ...this.cancelShow, value: this._options.cancelMessage
            };
        }

        if (this._options.okMessage) {
            this.okDisabled = {
                ...this.okDisabled, value: this._options.okMessage
            };
        }
    }

    public registerOk(action: (result: void) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }
}
