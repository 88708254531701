import {Component, EventEmitter, Input, Output, Type} from '@angular/core';
import {GridProfile} from '@app/sam-base/core/grid-profile/models';
import {EsQueryStatement, getEntityMetadata, isRestEntity} from '@app/sam-base/core/rest-api';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem} from '@app/sam-base/models';
import {DynamicPrintService} from '@core/print/dynamic-print.service';
import {PrintEntity, PrintFormat} from '@core/print/models/print-entity';
import {PrintTemplate} from '@core/print/models/print-template.model';
import {PrintService} from '@core/print/print.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'iw-print-entity', templateUrl: './iw-print-entity.component.html',
    standalone: false
})
export class IwPrintEntityComponent<T> {
    @Input() public showPrintButton = true;
    @Input() public showBusy = true;
    /** Shows the dropdown to choose templates */
    @Input() public hasTemplateCombo = true;
    @Input() public extraStatements: EsQueryStatement<T>[] = [];
    @Input() public loading = false;
    @Output() public loadingChange = new EventEmitter<boolean>();
    public templates: ComboboxItem<PrintTemplate>[] = [];
    public formatOptions: ComboboxItem<PrintFormat>[] = [{
        name: 'PDF', value: 'pdf'
    }, {
        name: 'Excel', value: 'xls'
    }];
    public entityName = '';
    public selectedFormat: PrintFormat = 'pdf';
    public selectedTemplate?: PrintTemplate;

    constructor(private readonly _printService: PrintService, private readonly _toastService: ToastService, private readonly _translate: TranslateService) {
    }

    private _profile?: GridProfile<T>;

    public get profile() {
        return this._profile;
    }

    @Input()
    public set profile(p: GridProfile<T> | undefined) {
        this.setProfile(p);
    }

    private _entity?: Type<T> | T;

    public get entity() {
        return this._entity;
    }

    @Input()
    public set entity(e: Type<T> | T | undefined) {
        this.setEntity(e);
    }

    private _entityId?: string | number;

    public get entityId() {
        return this._entityId;
    }

    public getTemplateLabel() {
        return (e: PrintTemplate) => e.templateName;
    }

    public setLoading(v: boolean) {
        return new Promise<void>(r => {
            if (v !== this.loading) {
                this.loading = !!v;
                this.loadingChange.emit(v);
            }
            r();
        });
    }

    public onPrint() {
        if (this.selectedTemplate) {
            const useDynamic = this.selectedTemplate.templateName === DynamicPrintService.TEMPLATE_NAME;

            const prt: PrintEntity<T> = <any>{
                entity: this._entity,
                entityId: this._entityId,
                extraStatements: this.extraStatements,
                format: this.selectedFormat,
                group: useDynamic ? this._profile : undefined,
                params: {},
                template: this.selectedTemplate
            };
            return this.print(prt);
        } else {
            this._toastService.error('no_default_profile');
        }
        return Promise.resolve(false);
    }

    public async reloadTemplates() {
        await this.setLoading(true);
        this.templates = this.loadTemplates(this.entityName);
        this.selectedTemplate = this.templates[0] && this.templates[0].value;
        await this.setLoading(false);
    }

    private async print(p: PrintEntity<T>) {
        try {
            await this.setLoading(true);
            const success = await this._printService.print(p);
            await this.setLoading(false);
            if (success) {
                this._toastService.success('SUCCESS_PRINT_GRID');
            }
            return success;
        } catch {
            return false;
        }
    }

    private setEntity(e?: T | Type<T>) {
        this._entity = e;
        if (isRestEntity(e)) {
            const metadata = getEntityMetadata(e);
            this.entityName = metadata.$entity;
            this._entityId = metadata.$getPk();
        }
    }

    private setProfile(p?: GridProfile<T>) {
        this._profile = p;
        if (p) {
            this.entityName = p.entity;
        }
    }

    private loadTemplates(entityName?: string): ComboboxItem<PrintTemplate>[] {
        const list: ComboboxItem<PrintTemplate>[] = [];

        if (entityName) {
            const isDynamic = !!this._profile;
            const res = this._printService.loadTemplates(isDynamic);

            for (const e of res) {
                const template = <ComboboxItem<PrintTemplate>>{
                    name: this._translate.instant(e.reportName), value: e
                };
                list.push(template);
            }
        }

        return list;
    }
}
