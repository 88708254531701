import {Component, Input, OnInit} from '@angular/core';
import {EmpPlanningService} from '@app/modules/sam-main/placement/services/emp-planning.service';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {TranslateService} from '@ngx-translate/core';
import moment, {Moment} from 'moment';

import {Planning, PlanningDay, PlanningDayView} from '../../planning-models';

@Component({
    selector: 'iw-planning-days',
    templateUrl: './planning-days.component.html',
    styleUrls: ['./planning-days.component.scss'],
    standalone: false
})
export class PlanningDaysComponent implements OnInit {
    public weekdays: string[] = [];
    public monthdays: string[] = [];

    public planningMonth: PlanningDayView[] = [];
    @Input() public mode: 'weekly' | 'monthly' = 'monthly';
    private _startDate = '';

    constructor(public readonly planningService: EmpPlanningService, private _translate: TranslateService) {
    }

    @Input()
    public set planningStartDate(v: string) {
        this._startDate = v;
        if (this.mode === 'weekly') {
            this.iniatilizeWeeklyMode(this._startDate);
        } else {
            this.iniatilizePlanning(this._startDate);
        }
    }

    private _employees = [];

    @Input()
    public set employees(v: any) {
        this._employees = v;
    }

    public ngOnInit() {
        // if (this.mode === 'weekly') {
        //     this.iniatilizeWeeklyMode(this._startDate);
        // } else {
        //     this.iniatilizePlanning(this._startDate);
        // }
    }

    private iniatilizeWeeklyMode(date: string = '') {
        this.weekdays = [];
        this.monthdays = [];
        this.planningMonth = [];
        const w: string[] = [
            this._translate.instant('sunday'),
            this._translate.instant('monday'),
            this._translate.instant('tuesday'),
            this._translate.instant('wednesday'),
            this._translate.instant('thursday'),
            this._translate.instant('friday'),
            this._translate.instant('saturday')];
        let weekdayIndex = IwDateHelper.dateWeekDay(date);
        const leaves: { day: string; planning: PlanningDay | undefined }[] = [];
        for (let i = 0; i < 7; i++) {
            const currentDay = IwDateHelper.addToDate(i, 'day', date);
            this.monthdays.push(IwDateHelper.dateFormatFromString(currentDay, 'DD.MM'));
            leaves.push({
                day: IwDateHelper.dateFormatFromString(currentDay, 'DD.MM'),
                planning: undefined
            });
            if (w.length === weekdayIndex) {
                weekdayIndex = 0;
            }
            this.weekdays.push(w[weekdayIndex++]);
        }
        if (this._employees && !!this._employees.length) {
            this.fillEmployeeForWeeklyMode(IwDateHelper.getMoment(date), IwDateHelper.addToDate(6, 'day', date), leaves);
        }
    }

    private fillEmployeeForWeeklyMode(firstDay: Moment, lastDay: Moment, leaves: {
        day: string; planning: PlanningDay | undefined
    }[]) {
        const list = this.extractEmpIdsList(this._employees);
        this.requestPlaning(list, firstDay, lastDay)
            .subscribe((planning) => {
                this.planningMonth = [];
                if (planning) {
                    const missions: (PlanningDay | undefined)[] = [];
                    planning[0].days.forEach((day) => {
                        if (day?.values && !!day?.values.length) {
                            day?.values.forEach((valueOfDay) => {
                                const leavesIndex = leaves.findIndex((leave) => leave.day === IwDateHelper.dateFormatFromString(day.date, 'DD.MM'));
                                if (valueOfDay.code === 'ppmis') {
                                    missions.push(day);
                                } else {
                                    missions.push(undefined);
                                    leaves[leavesIndex] = {
                                        day: IwDateHelper.dateFormatFromString(day.date, 'DD.MM'),
                                        planning: day
                                    };
                                }
                            });
                        } else {
                            missions.push(undefined);
                        }
                    });

                    if (missions.length > 7) {
                        const missions1 = missions.filter((value, index) => missions.indexOf(value) === index);
                        const title: string[] = [];
                        missions1.forEach((mis) => {
                            mis?.values.forEach((val) => {
                                if (val.code !== 'ppmis') {
                                    return;
                                }
                                title.push(val.title);
                            });
                        });
                        const titles = title.filter((value, index) => title.indexOf(value) === index);
                        titles.forEach((planningTitle) => {
                            const gg: (PlanningDay | undefined)[] = [];
                            missions1.forEach((m) => {
                                const k = m?.values.find((val) => val.title === planningTitle);
                                if (k) {
                                    const planningDay: PlanningDay = {
                                        date: new Date(m?.date ?? ''),
                                        values: [k]
                                    };
                                    gg.push(planningDay);
                                }
                                if (k === undefined) {
                                    gg.push(undefined);
                                }
                            });
                            this.planningMonth.push({
                                name: planningTitle,
                                id: '',
                                days: gg
                            });
                        });
                    } else {
                        this.planningMonth.push({
                            name: missions[0]?.values[0].title ?? 'Mission',
                            id: missions[0]?.values[0]?.parentId ?? '',
                            days: missions
                        });
                    }
                    const finalLeaves: (PlanningDay | undefined)[] = [];
                    leaves.forEach(leave => {
                        finalLeaves.push(leave.planning);
                    });
                    this.planningMonth.push({
                        name: 'Leaves',
                        id: '',
                        days: finalLeaves
                    });
                }
            });
    }

    private iniatilizePlanning(date: string = '') {
        this.monthdays = [];
        this.weekdays = [];
        this.planningMonth = [];
        const firstDay = moment(date)
            .startOf('month');
        const lastDay = moment(date)
            .endOf('month');
        const w: string[] = [
            'D',
            'L',
            'M',
            'M',
            'J',
            'V',
            'S'];
        let weekdayIndex = firstDay.day();
        for (let i = 1; i <= lastDay.date(); i++) {
            this.monthdays.push(i.toString());
            if (w.length === weekdayIndex) {
                weekdayIndex = 0;
            }
            this.weekdays.push(w[weekdayIndex++]);
        }
        if (this._employees && !!this._employees.length) {
            this.fillEmployees(firstDay, lastDay);
        }
    }

    private fillEmployees(firstDay: Moment, lastDay: Moment) {
        const list = this.extractEmpIdsList(this._employees);
        this.requestPlaning(list, firstDay, lastDay)
            .subscribe((p) => {
                p?.forEach((planningDay) => {
                    if (planningDay) {
                        this.iniatilizePlanningDays(planningDay, lastDay);
                    }
                });
            });
    }

    private iniatilizePlanningDays(planningMonthh: Planning, lastDay: Moment) {
        const pn = planningMonthh.name;
        const mondth: (PlanningDay | undefined)[] = [];
        for (let i = 1; i <= lastDay.date(); i++) {
            mondth.push(planningMonthh.days.find((day) => new Date(day.date).getDate() === i));
        }
        this.planningMonth.push({
            name: pn,
            id: planningMonthh.empId,
            days: mondth
        });
    }

    private extractEmpIdsList(list: any): string[] {
        const empIdsList: string[] = [];
        list.forEach((emp: any) => {
            empIdsList.push(emp.empId);
        });
        return empIdsList;
    }

    private requestPlaning(list: string[], start: Moment, end: Moment) {
        const FORMAT = 'YYYY-MM-DD';
        return this.planningService.getPlanning(list, start.format(FORMAT), end.format(FORMAT));
    }
}
