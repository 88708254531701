import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormHandlerService, IwStoreService} from '@app/sam-base/core';
import * as globalActions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, HtmlElementStatus, ModalComponent} from '@app/sam-base/models';
import {SalaryForm} from '@modules/sam-main/salary/salary.forms';
import {TranslateService} from '@ngx-translate/core';
import {
    EncaissementCreationMode,
    ManualDtaChoicemodal,
    ModalManualdtaOptions
} from '@shared/widgets/modal-components/modal-manualdta/modal-manualdta.options';
import {take} from 'rxjs';

@Component({
    selector: 'iw-modal-textarea',
    templateUrl: './modal-manualdta.component.html',
    standalone: false
})
export class ModalManualdtaComponent implements ModalComponent<ManualDtaChoicemodal | undefined, ModalManualdtaOptions>, OnInit {
    @Output() public pressOk = new EventEmitter<ManualDtaChoicemodal>();
    @Output() public pressCancel = new EventEmitter<void>();
    public optionPeriode: ComboboxItem<EncaissementCreationMode>[] = [
        <ComboboxItem<EncaissementCreationMode>>{
            value: EncaissementCreationMode.CAMT,
            name: 'CAMT'
        },
        <ComboboxItem<EncaissementCreationMode>>{
            name: this._translateService.instant('encaissement_manual'),
            value: EncaissementCreationMode.MANUAL
        }];
    public choicemodal: ManualDtaChoicemodal | undefined;
    public data!: ModalManualdtaOptions;
    public btnOk: HtmlElementStatus = {
        enabled: true,
        value: 'Ok',
        visible: true
    };
    public loading = false;
    private _options!: ModalManualdtaOptions;
    private _ok?: (result: ManualDtaChoicemodal | undefined) => void;
    private _cancel?: (error?: any) => void;

    constructor(private readonly _store: IwStoreService,
                private readonly _formHandlerService: FormHandlerService<string>,
                private readonly _translateService: TranslateService) {
    }


    public setData(data: any): Promise<void> {
        this._options = data;
        this.choicemodal = data;
        return data;
    }

    public onPressOk() {
        if (this._ok) {
            // FIXME this shit
            const entityName = this.choicemodal?.mode === EncaissementCreationMode.CAMT ? 'encaissement_manual' : 'gefacliview';
            const salaryFormType = this.choicemodal?.mode === EncaissementCreationMode.CAMT ? SalaryForm.EncaissementManual : SalaryForm.Encaissement;

            this.getFormAndShowFormDialog(entityName, salaryFormType)
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public registerOk(action: (result?: ManualDtaChoicemodal) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public ngOnInit() {
        this.choicemodal = {mode: EncaissementCreationMode.CAMT};
    }

    public onEmpChange() {
        this.checkCreateFicheSalaryPossible();
    }

    public onPeriodChange() {
        this.checkCreateFicheSalaryPossible();
    }

    private getFormAndShowFormDialog(entityName: string, salaryFormType: SalaryForm) {
        this._store.getFormByEntityName(entityName)
            .pipe(take(1))
            .subscribe(uuid => {
                if (uuid) {
                    this._store.dispatch(new globalActions.DestroyForm(uuid));
                }

                this._formHandlerService.showFormDialog(salaryFormType, undefined, (s) => ({
                    ...s,
                    isNew: true,
                    editMode: 'edit',
                    useNavigation: false,
                    loading: false
                }));
            });
    }

    private checkCreateFicheSalaryPossible() {

    }
}
