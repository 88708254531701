import {Component, Input} from '@angular/core';
import {PlacementForm} from '@app/modules/sam-main/placement/placement.forms';
import {EntityNavigationService, FormHandlerService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {Ppclimis, Ppemp} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';

import {PlanningComponentService} from '../../../../../../../core/services/planning-component.service';
import {PlanningDay, PlanningDayView} from '../../planning-models';

@Component({
    selector: 'iw-planning-day',
    templateUrl: './planning-day.component.html',
    styleUrls: ['./planning-day.component.scss'],
    standalone: false
})
export class PlanningDayComponent {
    @Input() public day?: PlanningDay;

    @Input() public label?: string;

    @Input() public widthLabel?: string;

    @Input() public enableClick = false;

    @Input() public mode: 'weekly' | 'monthly' = 'monthly';

    @Input() public sourcePlaning?: PlanningDayView;

    public showEmpPlanning = true;
    public employee?: Ppemp;

    public get labelName() {
        return this.label ?? '';
    }

    public get isMission() {
        return this.day?.values.some((value) => value?.code === 'ppmis');
    }

    public get title() {
        return this.getTitle();
    }

    public get schedule() {
        return this.day ? this.day?.values[0].schedule : this.defaultSchedule;
    }

    // eslint-disable-next-line complexity
    public get style() {
        let style = {};
        let color;
        switch (this.day?.values[0]?.code) {
            case '0':
                color = '#054F77';
                break;
            case '1':
                color = 'rgba(51,51,51,0.5)';
                break;
            case '2':
                color = 'rgba(247, 146, 50, 0.5)';
                break;
            case '3':
                color = 'rgba(246, 145, 178, 0.5)';
                break;
            case '4':
                color = 'rgba(101, 73, 130, 0.5)';
                break;
            case '5':
                color = 'rgba(204, 204, 204, 0.5)';
                break;
            case '6':
                color = 'rgba(204, 204, 204, 0.5)';
                break;
            case '7':
                color = '#FF0000';
                break;
            case 'ppmis':
                color = 'rgba(32, 201, 82, 0.5)';
                break;
            case 'M':
                color = '#F56100';
                break;
            default:
                color = '#FFFFFF';
                break;
        }
        if (this.day === undefined) {
            color = '#FFFFFF';
        }

        if (this.day?.values[0]?.flexible) {
            color = '#FFE960';
        }

        style = {
            'background-color': color, 'width': this.widthLabel ?? '35px', 'height': '35px'
        };

        if (this.mode === 'weekly') {
            style = {
                'background-color': color, 'width': '7px'
            };
        }
        return style;
    }

    public titleInfo: string[] = [];
    public defaultSchedule: (1 | 0)[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    constructor(private readonly _entityService: EntityNavigationService, private readonly _formHandler: FormHandlerService<PlacementForm>, private readonly _translate: TranslateService, private readonly _planning: PlanningComponentService) {
    }

    public getTitle(): string {
        this.titleInfo = [];
        this.day?.values.forEach((info) => {
            if (info.code === 'ppmis') {
                const endDate = IwDateHelper.dateFormat(info.endDate) !== 'Invalid date' ? IwDateHelper.dateFormat(info.endDate) : undefined;
                this.titleInfo.push(info.title + '\n' + this._translate.instant('datedebut') + ':' + IwDateHelper.dateFormat(info.startDate) + '\n' + (!!endDate ? (this._translate.instant('datefin') + ':' + endDate + '\n') : '') + info.totalHours + 'h');
            } else {
                this.titleInfo.push(info.title);
            }
        });
        return this.titleInfo.join('\n');
    }

    // eslint-disable-next-line complexity
    public openEvent() {
        if (!this.enableClick) {
            return;
        }
        if (this.isMission) {
            const ppmis = this.day?.values.find((value) => value.code === 'ppmis');
            this._entityService.navigateToEntityForm(Ppclimis, ppmis?.parentId || '', undefined, undefined, true);
        } else if (this.labelName?.indexOf('Mission -') !== -1) {
            const parentId = this.labelName.split(' - ')[1];
            this._entityService.navigateToEntityForm(Ppclimis, parentId || '', undefined, undefined, true);
        } else if (!!this.day?.values[0].parentId) {
            this._formHandler.showFormDialog(PlacementForm.ScheduleEvent, {
                height: '250px', parentId: this.day?.values[0].parentId, parentEntity: 'ppemp', isAvailability: false
            });
        } else if (!!this.sourcePlaning?.id) {
            this._planning.show(this.sourcePlaning.id);
        }
    }
}
