import {Message} from 'primeng/api';

export type ToastLevel = 'info' | 'warn' | 'error' | 'success';

export enum ToastDuration {
    forever = 0, short = 2000, normal = 3500, long = 7000,
}

export interface ToastMessage extends Message {
    isSystem?: boolean;
    isOperation?: boolean;
    isHtml?: boolean,
    isBusinessException?: boolean,
}

export const TOAST_EVENT = 'GLOBAL_TOAST';

export function isToast(t: unknown): t is ToastMessage {
    // eslint-disable-next-line no-null/no-null
    if (typeof t === 'object' && t !== null) {
        return typeof (<any>t).detail === 'string';
    }

    return false;
}

export type ToastPosition =
    'top-right'
    | 'top-left'
    | 'bottom-left'
    | 'bottom-right'
    | 'top-center'
    | 'bottom-center'
    | 'center';

export const SYSTEM_TOAST_KEY = 'system-toast';
export const DEFAULT_TOAST_KEY = 'toast';
export const CUSTOM_TOAST_KEY = 'toast-custom';
export const OPERATION_TOAST_KEY = 'operation-toast-key';
export const BUSINESS_EXCEPTION_TOAST_KEY = 'business-exception-toast-key';
