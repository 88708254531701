import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {IwGridColumn} from '@app/sam-base/models';
import {TranslateService} from '@ngx-translate/core';
import {toFixedNumber} from '@sam-base/helpers';
import {convertBinaryHours} from '@sam-base/helpers/placement-matching';
import {IwColumnDataType} from '@sam-base/models';
import {NgxMaskPipe} from 'ngx-mask';

import {getMask} from '../masks/phone-number';
import {MatchingHelpers} from '../services';
import {dateDebutValue, dateTimeValue, dateValue} from './iw-cell.helper';

@Pipe({
    name: 'iwCell',
    standalone: false
})
export class IwCellPipe implements PipeTransform {

    public valueMap: any = {
        translate: (value: string) => this._translate.instant(value),
        status: (value: string) => this._translate.instant(value),
        date: (value: Date) => dateValue(value),
        dateTime: (value: Date) => dateTimeValue(value),
        dateDebut: (value: Date) => dateDebutValue(value),
        docTranslate: (value: string) => this._translate.instant(`docType_${value}`),
        weekDay: (value: (0 | 1 | 2 | 3 | 4 | 5 | 6)[]) => this.weekDayValue(value),
        timeslot: (value: (0 | 1)[]) => this.timeslotValue(value),
        phonenumber: (value: string) => this.phonenumberValue(value),
        mnt: (value: number) => this.formatAmountToCurrency(value),
        enum: (value: any, column: IwGridColumn<any>) => this._matchingHelpers.convertEnumValue(value, column)
    };

    constructor(private _translate: TranslateService, private currencyPipe: CurrencyPipe,
                private readonly _matchingHelpers: MatchingHelpers // Talvez meter num ficheiro não serviço
    ) {
    }

    formatAmountToCurrency(value: number): string {
        let formattedAmount = this.currencyPipe.transform(value, 'CHF', 'code', '1.2-2');
        return formattedAmount ? formattedAmount.replace('CHF', '').trim() : '';
    }

    public transform<T>(value: any, type: IwColumnDataType, column?: IwGridColumn<T>): string | number | undefined {
        try {
            return this.valueMap[type](value, column);
        } catch (_) {
            // DEBUG('No type found for the column. Printing default value');
            if (this.canShowNumberScale(column)) {
                return this.getNumberScale(value, column);
            }
            return value;
        }
    }

    public canShowNumberScale<T>(column?: IwGridColumn<T>) {
        return column && column.decimals;
    }

    public getNumberScale<T>(value?: number, column?: IwGridColumn<T>) {
        if (!column?.decimals) {
            return value;
        }
        return toFixedNumber(value, column.decimals);
    }

    private weekDayValue(value: (0 | 1 | 2 | 3 | 4 | 5 | 6)[]) {
        if (!value) {
            return;
        }
        return this._matchingHelpers.convertNumericWeekdays(value);
    }

    private timeslotValue(value: (0 | 1)[]) {
        if (!value) {
            return;
        }
        return convertBinaryHours(value);
    }

    private phonenumberValue(value: string) {
        const mask = getMask(value);
        if (mask) {
            return NgxMaskPipe.prototype.transform(value, mask);
        }
        return value;
    }
}
