import {Component, EventEmitter, Input, OnDestroy, Output, Type, ViewChild} from '@angular/core';
import {DocumentManagementService} from '@core/document-management/document-management.service';
import {TranslateService} from '@ngx-translate/core';
import {IwRestGridComponent} from '@sam-base/components';
import {ContextMenuEvent} from '@sam-base/core/context-menu/models/event.model';
import {IwEventHubService} from '@sam-base/core/events';
import {RestQueryOperation, RestQueryParam} from '@sam-base/core/rest-api';
import {ToastService} from '@sam-base/core/toast';
import {RowClickEvent} from '@sam-base/models';
import {Ppwxdocs} from '@sam-base/models/placement';
import {DocumentEntityRef} from '@sam-base/models/placement/document-entity-ref';
import {PlacementEvent} from '@sam-base/models/placement/events.models';
import {FileUpload} from 'primeng/fileupload';
import {of, Subject, takeUntil} from 'rxjs';
import {catchError, debounceTime} from 'rxjs/operators';


@Component({
    selector: 'iw-doc-management',
    templateUrl: 'document-management.component.html',
    styleUrls: ['document-management.component.scss'],
    standalone: false
})
export class DocumentManagementComponent implements OnDestroy {
    @Input() public isReadonly = false;
    @Input() public forceActive = false;
    public type: Type<Ppwxdocs> = Ppwxdocs;
    public displayRenameDialog = false;
    @ViewChild('docGrid', {static: true}) public docGrid?: IwRestGridComponent<Ppwxdocs>;
    @ViewChild('fileUploader', {static: true}) public fileUploader?: FileUpload;
    @Output() public uploadedDoc = new EventEmitter<Ppwxdocs>();
    @Output() public refreshEventTriggered = new EventEmitter<void>();
    public doccategory = 'DFT';
    public queryStatements: RestQueryParam<Ppwxdocs, string>[] = [
        {
            operation: RestQueryOperation.Equals,
            prop: 'parentid',
            value: '_'
        }];
    public documentSelected: Ppwxdocs = {};
    public hasFile = false;
    private subscriptions = new Subject();

    constructor(private _docManagService: DocumentManagementService,
                private _eventHubService: IwEventHubService<string>, private _toastService: ToastService,
                private _translate: TranslateService) {
        this._eventHubService
            .forType<ContextMenuEvent<Ppwxdocs>>(PlacementEvent.RENAME_DOCUMENT)
            .pipe(takeUntil(this.subscriptions))
            .subscribe((event => {
                if (event.payload) {
                    this.documentSelected = {...event.payload.value};
                    if (!this.displayRenameDialog) {
                        this.displayRenameDialog = true;
                    }
                }
            }));

        this._eventHubService
            .forType<ContextMenuEvent<Ppwxdocs>>(PlacementEvent.DELETE_DOCUMENT)
            .pipe(debounceTime(300))
            .subscribe(() => this.refreshGrid());
    }

    private _entityId = '';

    public get entityId() {
        return this._entityId;
    }

    @Input()
    public set entityId(v: string) {
        this.setEntity(v);
    }

    private _entityRef: DocumentEntityRef = DocumentEntityRef.Client;

    public get entityRef() {
        return this._entityRef;
    }

    @Input()
    public set entityRef(v: DocumentEntityRef) {
        this.setEntityRef(v);
    }

    ngOnDestroy(): void {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public setEntity(id: string) {
        this._entityId = id;
        this.updateStatements();
    }

    public setEntityRef(ref: DocumentEntityRef) {
        this._entityRef = ref;
        this.updateStatements();
    }

    public onRowSelect(event: any) {
        this.documentSelected = event;
    }

    public onRowDoubleClick(event: RowClickEvent<Ppwxdocs>) {
        if (event && event.row.wxdocId) {
            this._docManagService.getUrlForDocument(event.row.wxdocId, event.row.libelle ?? 'download');
        }
    }

    public uploadDocument({files}: { files: File[] }) {
        if (files && files.length && files[0].name) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append('file', file);
            formData.append('parentType', this.entityRef);
            formData.append('entityId', this.entityId);
            formData.append('libelle', file.name);
            formData.append('category', this.doccategory);
            this._docManagService.uploadDocument(formData)
                .pipe(catchError(() => {
                    const message = this._translate.instant('error_upload_document');
                    this._toastService.error(message);
                    return of(false);
                }))
                .subscribe((doc: Ppwxdocs) => {
                    this.uploadedDoc.emit(doc);
                    this.refreshGrid(doc)
                });
        }
    }

    public onSave() {
        const id = this.documentSelected.wxdocId;
        const doc: Ppwxdocs = {
            libelle: this.documentSelected.libelle,
            doccategory: this.documentSelected.doccategory
        };
        if (id && doc) {
            this._docManagService.updateDocumentName(id, doc)
                .pipe(catchError(() => {
                    const message = this._translate.instant('error_update_document');
                    this._toastService.error(message);
                    return of(false);
                }))
                .subscribe((document: Ppwxdocs) => this.refreshGrid(document));
        }
        this.displayRenameDialog = false;
    }

    public onCancel() {
        this.displayRenameDialog = false;
    }

    /*
    * Called when a file is added on file uploader
    */
    public fileSelected() {
        this.hasFile = true;
    }

    /*
    * Called when a file is removed on file uploader
    */
    public fileUnselected() {
        this.hasFile = false;
    }

    private refreshGrid(doc?: Ppwxdocs) {
        if (!this.docGrid) {
            return;
        }
        this.docGrid.refresh();
        if (doc) {
            this._docManagService.documentUpdateStream.next(doc);
        }
        if (!this.fileUploader) {
            return;
        }
        this.fileUploader.clear();
        this.refreshEventTriggered.emit();
    }

    private updateStatements() {
        if (!this._entityId) {
            return;
        }

        this.queryStatements = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'parentid',
                value: this._entityId
            },
            {
                operation: RestQueryOperation.Equals,
                prop: 'partype',
                value: this._entityRef
            }];
    }
}
