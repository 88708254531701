import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';

import {buildCustomValueProvider} from '../../base/build-value-provider';
import {GridProfile} from '../../core/grid-profile/models';
import {RestApiService} from '../../core/rest-api';
import {IwStoreService, selectGridProfiles} from '../../core/store';
import {EntityDropdownComponent} from '../entity-dropdown/entity-dropdown.component';

@Component({
    selector: 'iw-grid-profile-dropdown',
    templateUrl: './grid-profile-dropdown.component.html',
    providers: [buildCustomValueProvider(IwGridProfileDropdownComponent)],
    standalone: false
})
export class IwGridProfileDropdownComponent<T> extends EntityDropdownComponent<GridProfile<T>> {
    public display = 'name';

    @Input() public entityName?: string;

    @Input() public advSearchMode = false;

    constructor(apiService: RestApiService, private readonly _store: IwStoreService, private _translate: TranslateService) {
        super(apiService);
        this.entityType = GridProfile;
    }

    /** Function developed in: https://samredesign.atlassian.net/browse/SAM-4290
     * covering direct prefix for Gridprofile labels with
     * no translation whatsoever
     */
    public getLabelValue(p: GridProfile<T>): string {
        // eslint-disable-next-line max-len
        return (`${p.isSystem ? 'System' : 'Custom'} - ${p.name ? this._translate.instant(p.name) : ''}`);
    }

    protected loadDropdownOptions(): Observable<GridProfile<T>[]> {
        if (!this._entityService) {
            return of([]);
        }

        const entity = this.entityName || '';

        return this._store.pipe(selectGridProfiles(entity, this.advSearchMode));
    }
}
