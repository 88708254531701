import {IwColumn, IwRestEntity, IwRestEntityId} from '@sam-base/core';

export function invoiceReminderColorMapper(row: GerplhdrView): string {
    if (row.noRappel === 1) {
        return '#f3f300'
    }
    if (row.noRappel === 2) {
        return '#fa4c00';
    }
    if (row.noRappel || 0 >= 3) {
        return '#a00101';
    }

    return "#C0C0C0";
}

@IwRestEntity('gerplhdrview')
export class GerplhdrView {
    @IwRestEntityId() @IwColumn({
        hidden: true,
        type: 'number'
    })
    public rplhdrId?: number;
    @IwColumn()
    public ageId?: string;
    public cliId?: string;
    @IwColumn()
    public cliNom?: string;
    public rplAdrId?: string;
    public rplAttn?: string;
    @IwColumn({
        type: 'number',
        name: 'norappel',
        align: 'right'
    })
    public noRappel?: number;
    @IwColumn({
        type: 'mnt',
        align: 'right',
    })
    public solde?: number;
    @IwColumn({
        type: 'date',
    })
    public dateRpl?: string;
    public datePrint?: string;
    public dateMail?: string;
    @IwColumn({
        name: 'sendingStatus',
        prop: 'sendingStatus',
        type: 'enum',
        enumPrefix: 'gerplhdr.sendingStatus'
    })
    public sendingStatus?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateTrack?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userModif?: string;
    public userDelet?: string;
}
