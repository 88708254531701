import {WARNING} from '@app/sam-base/core/logger';

import {SearchEntityOperator, searchOperators} from './search-entity-operator.model';

export enum RestQueryOperation {
    Equals = 'eq',
    NotEquals = 'neq',
    GreaterThan = 'gt',
    GreaterOrEqual = 'gte',
    LessThan = 'lt',
    LessOrEqual = 'lte',
    In = 'in',
    NotIn = 'nin',
    Between = 'btn',
    Like = 'like',
    NotLike = 'nlike',
    EqualsTo = 'eq',
    NotInclude = 'nin',
    Before = 'd_bef',
    After = 'd_af',
    On = 'a_on',
}

export interface RestQueryParam<T, K> {
    prop: keyof T;
    operation: RestQueryOperation;
    value: K | K[];
}

/**
 * Converts the search entity operator filter into a rest query operator
 *
 * @param queryOperation Filter query operation name
 */
export function convertToQueryOperation(queryOperation: SearchEntityOperator): RestQueryOperation | undefined {
    const key = getSearchOperatorKey(queryOperation);
    const restQueryOperation = RestQueryOperation[key as keyof typeof RestQueryOperation];
    if (!restQueryOperation) {
        WARNING('This rest query operation is not yet implemented!', key);
        return undefined;
    }
    return restQueryOperation;
}

/**
 * Iterates over search entity operators to get the current filter type
 *
 * @param queryOperation Filter query operation name
 */
function getSearchOperatorKey(queryOperation: SearchEntityOperator) {
    for (const opKey in searchOperators) {
        if (searchOperators[opKey] === queryOperation) {
            return opKey;
        }
    }
}
