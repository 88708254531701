import {Component, ElementRef, EventEmitter, Input, OnInit} from '@angular/core';
import {BaseFormControl} from '@sam-base/base';
import {buildCustomValueProvider} from '@sam-base/base/build-value-provider';
import {PpconService} from '@sam-base/core';
import {ComboboxItem} from '@sam-base/models';
import {Ppcon} from '@sam-base/models/placement';

@Component({
    selector: 'iw-conseiller-dropdown',
    templateUrl: './iw-conseiller-dropdown.component.html',
    providers: [buildCustomValueProvider(IwConseillerDropdownComponent)],
    standalone: false
})
export class IwConseillerDropdownComponent extends BaseFormControl<Ppcon> implements OnInit {
    public options: ComboboxItem<string>[] = [];
    @Input() public warningMessage?: string;
    @Input() public width?: string | number;
    @Input() public labelWidth?: string;
    public selectedValue = undefined;

    constructor(private elRef: ElementRef, private readonly ppconservice: PpconService) {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    public get isDisabled(): boolean {
        return this._isDisabled;
    }

    @Input()
    public set isDisabled(value: boolean) {
        this._isDisabled = value;
        this.loadConseiller();
    }

    ngOnInit(): void {
        this.loadConseiller();
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined)
            this.value = undefined;
    }

    public change(event: EventEmitter<Ppcon | number>) {
        this.valueChange.emit(this.selectedValue);
    }

    protected buildLabel(e: Ppcon): string {
        return `${e.nom} ${e.prenom}`;
    }

    private loadConseiller(): void {
        this.options = [];

        const loadConseillers = this.isDisabled // Use inherited isReadOnly directly
            ? this.ppconservice.getAllConseillers()
            : this.ppconservice.getLoggedUserConseillers();

        loadConseillers.then((conseillers) => {
            if (conseillers) {
                this.options = conseillers.map(conseiller => ({
                    name: this.buildLabel(conseiller),
                    value: conseiller.conId!
                }));
            }
        });
    }
}
