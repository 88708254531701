import {Component, EventEmitter, Output} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {FormKeys, ModalComponent} from '@app/sam-base/models';

import {Signature} from './signature-models';

@Component({
    templateUrl: './signature.component.html', styleUrls: ['./signature.component.scss'],
    standalone: false
})
export class SignatureComponent extends BaseFormComponent<Signature> implements ModalComponent<string, string> {

    public msgText?: string = '';

    @Output() public resSelected: EventEmitter<string> = new EventEmitter();
    public isLoading = false;
    private _onOk?: (result: string) => void;
    private _onCancel?: (error?: any) => void;

    constructor() {
        super();
    }

    public onCancel() {
        if (this._onCancel) {
            this._onCancel();
        }
    }

    public onOk() {
        if (this._onOk) {
            this._onOk(this.msgText ?? '');
        }
    }

    public async setData(data: string): Promise<void> {
        this.msgText = data;
    }

    public registerOk(action: (result: string) => void): void {
        this._onOk = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._onCancel = action;
    }

    /**
     * Set value to use in email body text
     *
     * @param ev string
     */
    public setMsgText(ev: string) {
        this.msgText = ev;
    }

    /**
     * Send email and generate suivi's
     */
    // eslint-disable-next-line complexity
    public async onSendClick() {
        this.resSelected.emit(this.msgText);
    }

    public closeForm() {
        if (this._onCancel) {
            this._onCancel();
        }
    }


    protected getFormControlNames(): FormKeys<Signature> {
        return ['signatureBody'];
    }
}
