<iw-modal-form [btnCancel]="false"
               [btnOk]="false"
               [height]="400"
               [title]="'ijReports' | translate"
               [width]="1150">
    <div class="row ij-container">
        <iw-busy [ajustLeft]="5"
                 [ajustTop]="5"
                 [isBusy]="isLoading"></iw-busy>
        <div #cboShCalcModeLabel
             class="iw-label col-xs-2 col-xs-offset-9">{{ 'syncReports' | translate }}
        </div>
        <div class="col-xs-1">
            <iw-button #btnRefreshTable
                       (press)="refreshTable()"
                       iconClass="fa fa-sync"></iw-button>
        </div>
        <div class="col-xs-12"
             style="height: 200px;">
            <iw-rest-grid #ijrapsgrid
                          (selected)="setSelected($event)"
                          [autoFormClick]="false"
                          [defaultSelected]="true"
                          [hasFilter]="true"
                          [hasGridColumnMenu]="false"
                          [hasSortIcon]="true"
                          [selectMode]="'checkbox'"
                          [type]="entity"></iw-rest-grid>
        </div>
        <div class="row col-xs-12 padding-top-20">
            <iw-button #import2Placement
                       (press)="importRapportsToPlacement()"
                       [isDisabled]="!selectedReports.length"
                       [text]="'importToPlacement' | translate"
                       class="col-xs-offset-7 col-xs-3"></iw-button>
            <iw-button #fermer
                       (press)="onPressOk()"
                       [text]="'fermer' | translate"
                       class="col-xs-2"></iw-button>
        </div>
    </div>
</iw-modal-form>
