import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {ElementRefService} from '../../core/dom';

/**
 * Default button component
 */

@Component({
    selector: 'iw-button',
    templateUrl: './iw-button.component.html',
    standalone: false
})
export class IwButtonComponent {
    @Input() public text = '';

    @Input() public tooltip = '';

    @Input() public iconClass = '';
    @Input() public iconPos: "left" | "right" | "top" | "bottom" = 'left';

    @Input() public isDisabled = false;
    @Output() public press = new EventEmitter<MouseEvent>();
    @ViewChild('component', {static: true}) public elem?: ElementRef;

    constructor(private readonly _elemRefService: ElementRefService) {
    }

    public get className() {
        return {
            'btn-text': this.text || this.width
        };
    }

    public get styleValue() {
        return {
            width: this._width,
            height: this._height
        };
    }

    private _width?: string;

    public get width() {
        return this._width;
    }

    @Input()
    public set width(v: undefined | string | number) {
        this._width = typeof v === 'number' ? v + 'px' : v;
    }

    private _height?: string;

    public get height() {
        return this._height;
    }

    @Input()
    public set height(v: undefined | string | number) {
        this._height = typeof v === 'number' ? v + 'px' : v;

    }

    /**
     * @param event click event
     * @param forceFocus if True, only emit press if component has focus
     */
    public onClick(event: MouseEvent, forceFocus = true) {
        if (forceFocus && this.elem) {
            const thisButtonElem = this._elemRefService
                .querySelector(this.elem, '.p-button');
            const hasFocus = document.activeElement === thisButtonElem;
            if (!hasFocus) {
                return;
            } // If button doesn't have focus return
        }

        if (!this.isDisabled) {
            this.press.emit(event);
        }
    }
}
