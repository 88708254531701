// TODO: replace component for runtime component load + decorator
import {
    Component, ComponentFactoryResolver, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef
} from '@angular/core';

import {BaseColumnDetail} from '../../../../base/base-column-detail';
import {EntityDetailProviderService} from '../../../../core/services/entity-detail.service';
import {IwGridColumn} from '../../../../models';

@Component({
    selector: 'iw-table-details', templateUrl: './iw-table-details.component.html', styles: [':host { display: none; }'],
    standalone: false
})
export class IwTableDetailsComponent<T> implements OnInit {

    @Input() public row?: T;

    @Input() public column?: IwGridColumn<T>;

    @ViewChild('template', {static: true}) public template?: TemplateRef<any>;

    constructor(private viewContainerRef: ViewContainerRef, private _entityDetailProvider: EntityDetailProviderService, private _componetFactory: ComponentFactoryResolver) {
    }

    public ngOnInit() {
        if (this.template) {
            // Render template out of host
            this.viewContainerRef.createEmbeddedView(this.template);
            this.loadDetail();
        }
    }

    private loadDetail() {
        if (!this.row) {
            return;
        }

        const comp = this._entityDetailProvider.getDetailComponent(this.row);
        if (!comp) {
            return;
        }

        const refFactory = this._componetFactory
            .resolveComponentFactory<BaseColumnDetail<unknown>>(comp);
        const ref = this.viewContainerRef
            .createComponent<BaseColumnDetail<unknown>>(refFactory);

        if (this.template) {
            this.template.createEmbeddedView(ref);
            ref.instance.instaciate(this.row);
        }
    }
}
