import {Injectable} from '@angular/core';
import {Ppacattestview, Ppagiview, Ppclicdes, Ppclimis, Pprapview} from '@app/sam-base/models/placement/index';
import {Gecliview} from '@sam-base/models/invoices/gecliview';
import {Gefacliview} from '@sam-base/models/invoices/gefacliview';
import {Gepaicliview} from '@sam-base/models/invoices/gepaicliview';
import {GerplhdrView} from '@sam-base/models/invoices/gerplhdrview';

import {Ppclicdfs} from '@sam-base/models/placement/ppclicdfs';
import {Saempview, Sapardedview} from '@sam-base/models/salary';
import {Saemsview} from '@sam-base/models/salary/saemsview';
import {filter, take} from 'rxjs/operators';
import {EntityNavigationService} from '../../services/entity-navigation.service';
import {AppState, IwStoreService, NavigationFirst, NavigationLast, NavigationNext, NavigationPrev} from '../../store';
import {NavigationEvents} from '../actions';
import {IwEventHandler} from '../core';

@Injectable()
export class NavigationEventHandlers {

    public constructor(private _store: IwStoreService, private _navService: EntityNavigationService) {
    }

    @IwEventHandler(NavigationEvents.ToStart)
    public onNavToStart(state: AppState) {
        if (state.dialogState.formId) {
            this._store.dispatch(new NavigationFirst(state.dialogState.formId));
        }
    }

    @IwEventHandler(NavigationEvents.ToEnd)
    public onNavToEnd(state: AppState) {
        if (state.dialogState.formId) {
            this._store.dispatch(new NavigationLast(state.dialogState.formId));
        }
    }

    @IwEventHandler(NavigationEvents.Next)
    public onNavNext(state: AppState) {
        if (state.dialogState.formId) {
            this._store.dispatch(new NavigationNext(state.dialogState.formId));
        }
    }

    @IwEventHandler(NavigationEvents.Prev)
    public onNavPrev(state: AppState) {
        if (state.dialogState.formId) {
            this._store.dispatch(new NavigationPrev(state.dialogState.formId));
        }
    }

    @IwEventHandler(NavigationEvents.ToEntity)
    public onNavToEntity(state: AppState) {
        const focusId = state.dialogState.formId;
        if (focusId) {
            // TODO: DEPRECATED: remove this after store clean
            this._store
                .navigationEntity(focusId)
                .state
                .pipe(filter(e => !!e), take(1))
                .subscribe(e => {
                    if (e) {
                        this._navService.navigateToGrid(e.entity, e.profile, e.uuid);
                    }
                });

            this._store
                .globalForm(focusId)
                .state
                // eslint-disable-next-line complexity
                .pipe(take(1))
                .subscribe(e => {
                    let entityType;
                    if (e.navProfile?.entity === 'ppclimis') {
                        entityType = Ppclimis;
                    }
                    if (e.navProfile?.entity === 'pprapview') {
                        entityType = Pprapview;
                    }
                    if (e.navProfile?.entity === 'ppclicdes') {
                        entityType = Ppclicdes;
                    }
                    if (e.navProfile?.entity === 'ppclicdfs') {
                        entityType = Ppclicdfs;
                    }
                    if (e.navProfile?.entity === 'ppagiview') {
                        entityType = Ppagiview;
                    }
                    if (e.navProfile?.entity === 'ppacattestview') {
                        entityType = Ppacattestview;
                    }
                    if (e.navProfile?.entity === 'sapardedview') {
                        entityType = Sapardedview;
                    }
                    if (e.navProfile?.entity === 'saempview') {
                        entityType = Saempview;
                    }
                    if (e.navProfile?.entity === 'gecliview') {
                        entityType = Gecliview;
                    }
                    if (e.navProfile?.entity === 'saemsview') {
                        entityType = Saemsview;
                    }
                    if (e.navProfile?.entity === 'gefacliview') {
                        entityType = Gefacliview;
                    }
                    if (e.navProfile?.entity === 'gepaicliview') {
                        entityType = Gepaicliview;
                    }
                    if (e.navProfile?.entity === 'gerplhdrview') {
                        entityType = GerplhdrView;
                    }
                    this._navService.navigateToGrid(entityType ?? e.entityType, e.navProfile, e.uuid);
                });
        }
    }
}
