/* Directive to host dynamic loaded components */
import {ChangeDetectorRef, ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef} from '@angular/core';

import {ViewContainerRefService} from '../services/view-container-ref.service';

@Directive({
    selector: '[iwContainerHost]',
    standalone: false
})
export class ContainerDirective implements OnInit {

    @Input() public replaceContainer = false;

    private _hostName = 'root';

    constructor(public componentFactoryResolver: ComponentFactoryResolver, public viewContainerRef: ViewContainerRef, private _viewContainerService: ViewContainerRefService, private _changesDetector: ChangeDetectorRef) {
    }

    public get iwContainerHost() {
        return this._hostName;
    }

    @Input()
    public set iwContainerHost(val: string) {
        if (val !== this._hostName) {
            const oldName = this.name;
            this._hostName = val;

            if (oldName !== 'root') {
                this._viewContainerService.unregisterContainer(oldName);
            }
        }
    }

    public get name() {
        return this._hostName;
    }

    public ngOnInit() {
        this._viewContainerService.registerContainer(this, this.replaceContainer);
    }

    public triggerRender() {
        this._changesDetector.detectChanges();
    }
}
