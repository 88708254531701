<iw-modal-form (pressCancel)="onPressCancel()"
               (pressOk)="onPressOk()"
               [btnCancel]="cancelShow"
               [btnOk]="okDisabled"
               [height]="350"
               [title]="title"
               [width]="width">
    <ng-container>
        <iw-table #rappelsTable
                  (rowDoubleClick)="onRowDoubleClick($event)"
                  [columns]="tableColumns"
                  [data]="content"
                  [isFilterEnable]=false
                  class="col-xs-9 rappelsTable"></iw-table>
    </ng-container>
</iw-modal-form>
