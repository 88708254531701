import {Component, EventEmitter, Input, Output} from '@angular/core';
import {sortBy} from 'lodash';

import {ListItem} from '../list-box/list-item.model';

@Component({
    selector: 'iw-selection-lists',
    templateUrl: './selection-lists.component.html',
    styleUrls: ['./selection-lists.component.scss'],
    standalone: false
})
export class SelectionListsComponent<T> {

    @Input() public firstList: ListItem<T>[] = [];

    @Input() public secondList: ListItem<T>[] = [];
    @Output() public firstListChange = new EventEmitter<ListItem<T>[]>();
    @Output() public secondListChange = new EventEmitter<ListItem<T>[]>();
    @Output() public listChange = new EventEmitter<void>();
    public firstListCurrent: ListItem<T>[] = [];
    public secondListCurrent: ListItem<T>[] = [];
    private _isDisabled = false;

    constructor() {
    }

    public get disabled() {
        return this._isDisabled;
    }

    @Input()
    public set disabled(v: boolean) {
        this._isDisabled = v;
    }

    private _customSort?: (list: ListItem<T>[]) => ListItem<T>[];

    @Input()
    public set customSort(val: (list: ListItem<T>[]) => ListItem<T>[]) {
        this._customSort = val;
        this.update();
    }

    public passAllToFirst() {
        this.firstList = this.firstList.concat(this.secondList);
        this.secondList = [];
        this.update();
    }

    public passAllToSecond() {
        this.secondList = this.secondList.concat(this.firstList);
        this.firstList = [];
        this.update();
    }

    public passSelectedToSecond() {
        this.secondList = this.secondList.concat(this.firstListCurrent);
        this.firstListCurrent = [];
        this.firstList = this.firstList.filter(e => !this.secondList.includes(e));
        this.update();
    }

    public passSelectedToFirst() {
        this.firstList = this.firstList.concat(this.secondListCurrent);
        this.secondListCurrent = [];
        this.secondList = this.secondList.filter(e => !this.firstList.includes(e));
        this.update();
    }

    private update() {
        this.sort();
        this.firstListChange.emit(this.firstList);
        this.secondListChange.emit(this.secondList);
        this.listChange.emit();
    }

    private sort() {
        this.firstList = this.getSortedList(this.firstList);
        this.secondList = this.getSortedList(this.secondList);
    }

    private getSortedList(list: ListItem<T>[]) {
        return this._customSort ? this._customSort(list) : this.sortList(list);
    }

    private sortList(list: ListItem<T>[]): ListItem<T>[] {
        return <any>sortBy(list, [(o) => o.label]);
    }
}
