import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter} from '@angular/core';
import {ModalComponent} from '@app/sam-base/models';
import {Lookups} from '@app/sam-base/models/common/lookups';
import {ListItem} from '@shared/widgets/form-components/list-box/list-item.model';

@Component({
    selector: 'iw-lookups-selection',
    templateUrl: './lookups-selection.component.html',
    standalone: false
})
export class LookupsSelectionComponent implements ModalComponent<string, { list: string[]; lkupname: string }> {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();

    public selectionList: string[] = [];

    public input: ListItem<string>[] = [];

    public output: ListItem<string>[] = [];

    public data?: { list: string[]; lkupname: string };

    private _ok?: (result: string) => void;
    private _cancel?: (error?: any) => void;

    constructor(private _http: HttpClient) {
    }

    public async setData(data: { list: string[]; lkupname: string }): Promise<void> {
        this.selectionList = data.list;
        this.updateLists(data.lkupname);
    }

    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onPressOk(event: ListItem<string>[]) {
        const selections = event.map(e => e.label.split('|')[1].trim())
            .join(';');
        if (this._ok) {
            this._ok(selections);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    private updateLists(lkname: string) {
        const input: ListItem<string>[] = [];
        const output: ListItem<string>[] = [];

        // TODO: use rest api
        this._http.get<any>('/api/lookups?size=1000')
            .subscribe((res) => {
                res._embedded.lookups
                    .filter((el: { lkupname: string }) => el.lkupname === lkname)
                    .forEach((e: Lookups) => {
                        if (e.cvalue && !this.selectionList.includes(e.cvalue)) {
                            input.push({
                                label: e.cid + ' | ' + e.cvalue,
                                value: e.cvalue
                            });
                        }
                        if (e.cvalue && this.selectionList.includes(e.cvalue)) {
                            output.push({
                                label: e.cid + ' | ' + e.cvalue,
                                value: e.cvalue
                            });
                        }
                    });

                this.output = output;
                this.input = input;
            });
    }
}
