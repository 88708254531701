import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {PlanningComponentService} from '@app/core/services/planning-component.service';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {FormKeys} from '@app/sam-base/models';
import {AdvMatchingParams} from '@app/sam-base/models/adv-matching-params';
import {EsAdvancedMatch} from '@app/sam-base/models/es-advanced-match';
import {Ppemp, Ppkw} from '@app/sam-base/models/placement';
import {DocumentEntityRef} from '@app/sam-base/models/placement/document-entity-ref';
import {IwTimeSlotsComponent} from '@shared/widgets/iw-components/iw-time-slots/iw-time-slots.component';
import {IwWeekdaysPickerComponent} from '@shared/widgets/iw-components/iw-weekday-picker/iw-weekday-picker.component';
import {
    LookupsSelectionComponent
} from '@shared/widgets/modal-components/lookups-selection/lookups-selection.component';
import {isArray} from 'lodash';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PpkwMatchingComponent} from '../ppkw-matching/ppkw-matching.component';

@Component({
    selector: 'iw-adv-search',
    templateUrl: 'advanced-search.component.html',
    standalone: false
})
export class AdvancedSearchComponent extends BaseFormComponent<AdvMatchingParams> implements OnDestroy {

    @Input() public result = [];
    public entityRef: DocumentEntityRef = DocumentEntityRef.Client;
    @Output() public advancedSearchApplied = new EventEmitter<any>();
    @ViewChild('kwMatching', {static: true}) public kwMatching?: PpkwMatchingComponent;
    @ViewChild('weekdayPicker', {static: true}) public weekdayPicker?: IwWeekdaysPickerComponent;
    @ViewChild('timeslotsPicker', {static: true}) public timeslotsPicker?: IwTimeSlotsComponent;
    public showPlanningPanel = false;
    public showEmployePlanning = false;
    public planningEmployee?: Ppemp;
    public isMatchingModeOn = false;
    private _subscripts = new Subject();

    constructor(private _modalService: ModalService, private readonly _planningComponent: PlanningComponentService) {
        super();

        this._planningComponent
            .displayChange
            .pipe(takeUntil(this._subscripts))
            .subscribe((data: [boolean, Ppemp | undefined]) => {
                this.showEmployePlanning = data[0];
                this.planningEmployee = data[1];
            });
    }

    @Input()
    public set formEntry(val: AdvMatchingParams | undefined) {
        if (val) {
            this.fillEntireForm(val, true);
        }
    }

    @Input()
    public set advSearchProfile(advProfileData: EsAdvancedMatch | undefined) {
        if (!advProfileData) {
            return;
        }

        if (isArray(advProfileData.professions)) {
            advProfileData.professions = advProfileData.professions.join(';');
        }

        this.fillEntireForm(advProfileData);
    }

    public get planningDateFrom() {
        return IwDateHelper.dateFormatFromString(this.getFormValue('datefrom'), 'MM-DD-YYYY');
    }

    public get regionsVal(): string {
        return this.getFormValue('regions')?.join(',');
    }

    public ngOnDestroy() {
        this._subscripts.next(undefined);
        this._subscripts.complete();
        super.ngOnDestroy();
    }

    public setTimeSlot(value: (1 | 0)[]) {
        // Check if no hours were selected
        if (value.filter(e => e !== 0).length > 0) {
            this.setFormValue('hours', value);
        } else {
            this.setFormValue('hours', undefined);
        }
    }

    public async openRegionsSelection() {
        try {
            const regValue: string[] = this.getFormValue('regions') || [];
            const res = await this._modalService.showModal(LookupsSelectionComponent, {
                list: regValue,
                lkupname: 'REGION'
            });

            const regions = res.split(';')
                .filter(e => e)
                .map((e) => e.trim());
            this.setFormValue('regions', regions);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    }

    public applySearch() {
        this.setKeywords();
        this.setWeekdays();
        this.setFormValue('regions', this.getFormValue('regions') || []);

        if (!!this.getFormValue('professions') && (typeof this.getFormValue('professions')) === 'string') {

            const professions: string[] = this.getFormValue('professions')
                .split(';');

            this.advancedSearchApplied.emit({
                ...this.formValue,
                professions
            });
        } else {
            this.advancedSearchApplied.emit({...this.formValue});
        }
        this.isMatchingModeOn = true;
    }

    public clearSearch() {
        this.clearForm();
        this.weekdayPicker?.reset();
        this.timeslotsPicker?.reset();
        if (!this.kwMatching) {
            return;
        }
        this.kwMatching.value = [];
    }

    public openPlanning() {
        this.showPlanningPanel = true;
    }

    public closeDialogPlanning() {
        this.showPlanningPanel = false;
    }

    public isBtnPlanningDisabled() {
        return !this.getFormValue('datefrom') || !this.isMatchingModeOn;
    }

    protected getFormControlNames(): FormKeys<AdvMatchingParams> {
        return [
            [
                'professions',
                new UntypedFormControl(undefined)],
            'hours',
            'datefrom',
            'dateto',
            'regions',
            'keywords',
            'weekdays'];
    }

    // eslint-disable-next-line complexity
    private fillEntireForm(val: AdvMatchingParams, submit = false) {
        this.fillFormData(val);
        // we enforce a new lifecycle in order to have the keywords shown here in the component of keywords.
        // not a good solution at all, but it works for now and we do not have time to spend on this one. We'll have to redo the whole keywords mechanism anyway as this is ugly from start to finish
        setTimeout(() => {
            if (val.keywords && this.kwMatching) {
                this.kwMatching.value = val.keywords as Ppkw[];
            }
            if (val.weekdays) {
                this.weekdayPicker?.setSelections(val.weekdays);
            }
            if (val.hours) {
                this.timeslotsPicker?.setSelectedHours(val.hours);
            }

            if (!submit) {
                return;
            }
            this.applySearch();
        }, 0);
    }

    private async setKeywords() {
        if (!this.kwMatching) {
            return;
        }
        const keywords: string[] = [];
        this.kwMatching.value.forEach((ppkw: Ppkw) => {
            if (ppkw.kw) {
                keywords.push(ppkw.kw);
            }
        });

        this.setFormValue('keywords', keywords);
    }

    private setWeekdays() {
        if (!this.weekdayPicker) {
            return;
        }
        const weekdays = this.weekdayPicker.extractSelections();

        this.setFormValue('weekdays', weekdays);
    }
}
