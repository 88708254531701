import {Component, ElementRef, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SmtpConfig} from '@sam-base/models/common/smtpconfig';

@Component({
    selector: 'iw-smtpconfig-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwSmtpConfigDropdownComponent)],
    standalone: false
})
export class IwSmtpConfigDropdownComponent extends BaseEntityDropdown<SmtpConfig> {
    @Input() public width?: string | number;

    @Input() public warningMessage?: string;

    constructor(private elRef: ElementRef) {
        super();
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    protected getEntityType() {
        return SmtpConfig;
    }

    protected buildLabel(e: SmtpConfig): string {
        return `${e.name}`;
    }
}
