<iw-dropdown (controlFocus)="$event ? onFocus() : onBlur();"
             (valueChange)="selectedChanged.emit($event)"
             (valueSelected)="valueSelected.emit()"
             [(value)]="value"
             [defaultOption]="defaultOption"
             [isDisabled]="isDisabled"
             [labelAlign]="labelAlign"
             [labelWidth]="labelWidth"
             [label]="label"
             [options]="options"
             [size]="size"
             [width]="width"></iw-dropdown>
