import {Component, ElementRef, Input} from '@angular/core';
import {BaseEntityDropdown, buildCustomValueProvider} from '@app/sam-base/base';
import {Gepaimode, GePaytype} from '@app/sam-base/models/invoices/gepaimode';

@Component({
    selector: 'iw-gepaimode-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwGepaimodeDropdownComponent)],
    standalone: false
})
export class IwGepaimodeDropdownComponent extends BaseEntityDropdown<Gepaimode> {
    @Input() public width?: string | number;

    @Input() public onlyLbvr?: boolean;
    @Input() public onlyQr?: boolean;
    @Input() public warningMessage?: string;
    @Input() private gePaytypes: GePaytype[] = [
        GePaytype.ENTREE,
        GePaytype.ATTRIBUTION,
        GePaytype.ENTREE_ATTRIBUTION];

    constructor(private elRef: ElementRef) {
        super();
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public resultFilters: (e: Gepaimode) => boolean = (e) => {
        let isInPayType = this.gePaytypes.indexOf(e.paytype!) !== -1;
        if (this.onlyLbvr) {
            return isInPayType && e.lbvr === true;
        }
        if (this.onlyQr) {
            return isInPayType && e.lqr === true;
        }
        return isInPayType;
    };

    protected getEntityType() {
        return Gepaimode;
    }

    protected buildLabel(e: Gepaimode): string {
        return e.titre || '';
    }
}
