import {Component, Input, Type} from '@angular/core';
import {BaseEntityDropdown, buildCustomValueProvider} from '@app/sam-base/base';
import {Ppagence} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-agency-combo-box',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwAgencyComboBoxComponent)],
    standalone: false
})
export class IwAgencyComboBoxComponent extends BaseEntityDropdown<Ppagence> {
    @Input() public noLabel = false;

    protected getEntityType(): Type<Ppagence> {
        return Ppagence;
    }

    protected buildLabel(e: Ppagence) {
        return e.ageLib || '';
    }
}
