import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base';
import {ModalService} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {Gecliatt} from '@app/sam-base/models/invoices/gecliatt';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {map} from 'rxjs';
import {IwTextfieldListComponent} from './iw-textfield-list.component';

@Component({
    templateUrl: './iw-textfield-list.component.html',
    selector: 'iw-textfield-gecliatt',
    providers: [buildCustomValueProvider(IwTextfieldGeCliAttComponent)],
    standalone: false
})
export class IwTextfieldGeCliAttComponent extends IwTextfieldListComponent<Gecliatt> {
    public readonly listOption: ModalSelectTableOption<Gecliatt, string>;

    @Input() public cliId?: string;

    @Output() public cliAttChanged = new EventEmitter<Gecliatt>();

    constructor(modalService: ModalService, restService: RestApiService, elRef: ElementRef) {
        super(modalService, elRef, restService);
        this.propsToFilter = ['nomcomplet'];
        this.listOption = {
            columns: [{prop: 'nomcomplet'}], map: (e: Gecliatt) => {
                this.cliAttChanged.emit(e);
                return e.nomcomplet || '';
            }, selection: 'single', fetchAction: () => restService
                .getEntityClient(Gecliatt)
                .getRefData()
                .pipe(map(e => e.filter(v => v.cliId === this.cliId)
                    .sort((a, b) => (a.nomcomplet || '') < (b.nomcomplet || '') ? -1 : 1)))
        };
    }
}
