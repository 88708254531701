import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {trim} from 'lodash';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';
import {IwGridColumn} from '../../models';

/** Interface to show on table */
interface Item {
    value: string
}

/**
 * Component to be used in a reactiveform.
 *
 * It display a [STRING] into a list by spliting it per new line
 * or other custom string sequence
 *
 * It allows to add new entries or remove existing ones
 */
@Component({
    templateUrl: './iw-multiline-view.component.html',
    selector: 'iw-multiline-view',
    providers: [buildCustomValueProvider(IwMultilineViewComponent)],
    standalone: false
})
export class IwMultilineViewComponent extends BaseFormControl<string> implements OnInit, OnDestroy {

    /** On close button click */
    @Output() public closed = new EventEmitter();

    @Input() public showClose = false;

    /** Char to separate the string values */
    @Input() public separator = '\n';
    @Input() public height = '350px';
    /** Grid columns */
    public tableColumn: IwGridColumn<Item>[] = [{prop: 'value'}];
    /** Selected items in table */
    public selectedItems: Item[] = [];
    private _subs = new Subject();

    constructor() {
        super();
    }

    public get ngStyle() {
        return {
            height: this.height
        };
    }

    /** This is just to keep the list visible */
    private _listValues: Item[] = [];

    /** List of values to show in list */
    public get listValues() {
        return this._listValues;
    }

    /** Header title */
    private _header = 'value';

    public get header() {
        return this._header;
    }

    /** Header value to show on table */
    @Input()
    public set header(v: string) {
        this._header = v;
        this.tableColumn[0].name = v;
    }

    /** RAZ button click action */
    public onRazClick() {
        this.clearList();
    }

    /** Suprimir button Click action */
    public onSuprimirClick() {
        const val = this.selectedItems[0];
        if (!val) {
            return;
        }
        const index = this._listValues
            .findIndex(e => e.value === val.value);
        this.removeEntry(index);
    }

    /** Add a new entry to list */
    public addEntry(value: string) {
        const copy = this.getListCopy();
        copy.push({value});
        this.updateStringValue(copy);
    }

    /** Remove entry by position */
    public removeEntry(index: number) {
        if (index < 0 || index >= this._listValues.length) {
            return;
        }

        const copy = this.getListCopy();
        copy.splice(index, 1); // Remove
        this.updateStringValue(copy);
    }

    /** Remove all elements */
    public clearList() {
        this.updateStringValue([]);
    }

    /** Listening for value changes */
    public ngOnInit() {
        this.valueChange
            .pipe(takeUntil(this._subs))
            .subscribe((e: string | undefined) => this.updateListString(e));
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    /** Update the current list from a source */
    private updateListString(v: unknown) {
        if (typeof v === 'string') {
            this._listValues = v.split(this.separator)
                .filter(e => !!e)
                .map(e => ({value: e}));
        } else {
            this._listValues = [];
        }
    }

    /** Update value from a list */
    private updateStringValue(list: Item[]) {
        this.value = list
            .map(e => trim(e.value))
            .filter(e => !!e)
            .join(this.separator) || '';
    }

    /** Return copy of current list */
    private getListCopy() {
        return [...this._listValues];
    }
}
