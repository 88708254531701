import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Sagen} from '@app/sam-base/models/salary/sagen';
import {ParametersService} from '@core/services/parameters.service';
import {AgencyHandlerService, IwEventHubService} from '@sam-base/core';
import {ProfileService} from '@shared/profile/profile.service';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject, from, lastValueFrom, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {RestClient} from '../rest-api/core';

@Injectable()
export class SagenService extends RestClient<any> {
    private status = new BehaviorSubject<number>(0);
    status$ = this.status.asObservable();

    constructor(public readonly _http: HttpClient,
                private readonly _keycloakService: KeycloakService,
                private readonly _agencyService: AgencyHandlerService,
                private readonly _profileService: ProfileService,
                private readonly _params: ParametersService,
                private _eventStore: IwEventHubService<string>) {
        super(_http);
    }

    public updateStatus(status: number) {
        this.status.next(status);
    }

    public latestActiveSalIdForSagen(): Observable<Sagen> {
        return this._params.getSagenCurrentYear()
            .pipe(map(sagens => {
                const orderedAndFilteredSalIds = sagens.filter(ele => ele.status == 1)
                    .sort((aSagen, bSagen) => {
                        const a = aSagen.salId ?? '';
                        const b = bSagen.salId ?? '';
                        // Split the salId into [year, month]
                        let aSplit = a.split(' ');
                        let bSplit = b.split(' ');

                        // Compare years first
                        if (aSplit[0] > bSplit[0]) return -1;
                        if (aSplit[0] < bSplit[0]) return 1;

                        // Years are the same, compare months
                        let aMonth = aSplit.length > 1 ? parseInt(aSplit[1]) : 0;
                        let bMonth = bSplit.length > 1 ? parseInt(bSplit[1]) : 0;
                        return bMonth - aMonth;
                    });
                return orderedAndFilteredSalIds[0];
            }));
    }

    public getInformations(salId: string): Observable<string> {
        return this._http.get(this._baseUrl + `sagen/${salId}/informations`, {
            responseType: 'text'
        });
    }

    public getSagenDataArray(annee: string): Observable<Sagen[]> {
        return this._http.get<Sagen[]>(this._baseUrl + `sagen/annee/${annee}`);
    }

    public getSagenBySalId(salId: string): Observable<Sagen> {
        return this._http.get<Sagen>(this._baseUrl + `sagen/salId/${salId}`);
    }

    public getOpenSalId(): Observable<Sagen[]> {
        return this._http.get<Sagen[]>(this._baseUrl + `sagen/active`);
    }

    public openPeriode(salId: string): Observable<Sagen> {
        return this._http.post<Sagen>(this._baseUrl + `sagen/${salId}/open`, {});
    }

    public closePeriode(salId: string): Observable<Sagen> {
        return this._http.post<Sagen>(this._baseUrl + `sagen/${salId}/close`, {});
    }

    public getSelectedSagen(): Observable<Sagen> {
        return from(this.getLocalStorageKey())
            .pipe(switchMap(key => {
                const value = localStorage.getItem(key);
                if (value) {
                    return this.getSagenBySalId(value);
                }
                return this.latestActiveSalIdForSagen();
            }));
    }

    public async getSelectedSagenSync(): Promise<Sagen> {
        const key = await this.getLocalStorageKey();
        const value = localStorage.getItem(key);
        if (value) {
            return lastValueFrom(this.getSagenBySalId(value));
        }
        return lastValueFrom(this.latestActiveSalIdForSagen());
    }


    public setCurrentSalId(salId: string) {
        if (!salId) return;
        from(this.getLocalStorageKey())
            .subscribe(key => {
                localStorage.setItem(key, salId);
                this._eventStore.emit('active_salId_sagen');
            });
    }

    private async getLocalStorageKey(): Promise<string> {
        return '_#SAM_USER_AGENCY_SAGEN#_' + this._keycloakService.getUsername() + '_' + (await this._profileService.getTenant()) + '_' + (await lastValueFrom(this._agencyService.loadAgencyId()));
    }


}
