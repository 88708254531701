import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ToastService} from '@app/sam-base/core/toast';
import {Ppwxdocs} from '@app/sam-base/models/placement';
import {DocumentEntityRef} from '@app/sam-base/models/placement/document-entity-ref';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {saveAs} from 'file-saver';
import {Observable, of, Subject} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class DocumentManagementService {

    public documentUpdateStream: Subject<Ppwxdocs> = new Subject<Ppwxdocs>();
    protected readonly _baseUrl: string;

    constructor(private _http: HttpClient, private _toastService: ToastService, private _translate: TranslateService) {
        this._baseUrl = environment.backendURL;
    }

    public getAllDocuments(): Observable<Ppwxdocs[]> {
        return this._http
            .get<Ppwxdocs[]>(this._baseUrl + 'documents');
    }

    public getDocumentById(id: string): Observable<Ppwxdocs> {
        return this._http.get<Ppwxdocs>(this._baseUrl + `documents/${id}`);
    }

    public getDocumentsByEntityId(id: string, entityRef: DocumentEntityRef): Observable<Ppwxdocs[]> {
        return this._http
            .get<Ppwxdocs[]>(this._baseUrl + `documents/${entityRef}/${id}`);
    }

    public updateDocumentName(id: string, payload: {}): Observable<any> {
        return this._http
            .patch<Ppwxdocs>(this._baseUrl + `document/${id}`, payload);
    }

    public deleteDocumentById(id: string): Observable<any> {
        return this._http.delete(this._baseUrl + `documents/${id}`, {
            observe: 'body',
            responseType: 'arraybuffer'
        });
    }

    public uploadDocument(formData: any): Observable<any> {
        return this._http.post<any>(this._baseUrl + `documents`, formData);
    }

    public downloadDocument(id: string, filename: string) {
        const url = this._baseUrl + `documents/${id}`;

        this._http.head(url)
            .pipe(catchError(() => {
                const message = this._translate.instant('error_download_doc');
                this._toastService.error(message);
                return of(undefined);
            }))
            .subscribe((res) => {
                // Head http request returns null when sucessful
                // eslint-disable-next-line no-null/no-null
                if (res === null) {
                    this.downloadFile(id, filename);
                }
            });
    }

    public downloadDocumentByPath(path: string, filename: string) {
        const url = this._baseUrl + `documents/download/path?path=${path}`;

        this._http.head(url)
            .pipe(catchError(() => {
                const message = this._translate.instant('error_download_doc');
                this._toastService.error(message);
                return of(undefined);
            }))
            .subscribe((res) => {
                // Head http request returns null when sucessful
                // eslint-disable-next-line no-null/no-null
                if (res === null) {
                    this.downloadFileByPath(path, filename);
                }
            });
    }

    public getPreSignedUrlInCommonByPath(path: string) {
        return this._http.get(this._baseUrl + `documents/url/common/path?path=${path}`, {responseType: 'text'});
    }

    public getUrlForDocument(id: string, filename: string) {
        const url = this._baseUrl + `documents/${id}/url`;

        this._http.head(url)
            .pipe(catchError(() => {
                const message = this._translate.instant('error_download_doc');
                this._toastService.error(message);
                return of(undefined);
            }))
            .subscribe((res) => {
                // Head http request returns null when sucessful
                // eslint-disable-next-line no-null/no-null
                if (res === null) {
                    this.openFileFromUrl(id, filename);
                }
            });
    }

    private openFileFromUrl(id: string, filename: string) {
        this._http.get(this._baseUrl + `documents/${id}/url`, {responseType: 'text'})
            .subscribe((url: string) => {
                if (url) {
                    window.open(url, '_blank')
                }
            });
    }


    private downloadFile(id: string, filename: string) {
        this._http.get(this._baseUrl + `documents/${id}`, {responseType: 'blob'})
            .subscribe((doc: Blob) => saveAs(doc, filename));
    }

    private downloadFileByPath(path: string, filename: string) {
        this._http.get(this._baseUrl + `documents/download/path?path=${path}`, {responseType: 'blob'})
            .subscribe((doc: Blob) => saveAs(doc, filename));
    }
}
