import {Component, ElementRef, OnInit} from '@angular/core';
import {buildCustomValueProvider} from '@sam-base/base';

import {IwTextFieldComponent} from '../iw-textfield/iw-textfield.component';

@Component({
    templateUrl: '../iw-textfield/iw-textfield.component.html',
    selector: 'iw-email-textfield',
    providers: [buildCustomValueProvider(EmailTextfieldComponent)],
    standalone: false
})
export class EmailTextfieldComponent<T> extends IwTextFieldComponent implements OnInit {

    constructor(elRef: ElementRef) {
        super(elRef);
    }

    public ngOnInit(): void {
        this.trimWhiteSpacesOnInput = true;
        super.ngOnInit();
    }
}
