import {Component, Type} from '@angular/core';
import {ParametersService} from '@core/services/parameters.service';

import {TranslateService} from '@ngx-translate/core';
import {BaseColumnDetail} from '@sam-base/base';
import {SaemcService} from '@sam-base/core/services/saemc.service';
import {SaemsService} from '@sam-base/core/services/saems.service';
import {IwGridColumn} from '@sam-base/models';
import {Ppsuivi} from '@sam-base/models/placement';
import {SaemcDetail} from '@sam-base/models/saempviewDetails';
import {Saempview, Sapar} from '@sam-base/models/salary';
import {Saemc} from '@sam-base/models/salary/saemc';
import {Saems} from '@sam-base/models/salary/saems';

@Component({
    templateUrl: './entity-detail-saemp.component.html',
    standalone: false
})
export class EntityDetailSaempComponent<T> extends BaseColumnDetail<T> {
    public entity: Type<any> = Ppsuivi;

    public noData = false;

    public empId = '';

    public sapar?: Sapar;

    public columns = this.getColumns()

    public tableData: SaemcDetail[] = [];
    public saemcDetails: SaemcDetail[] = [];

    public saemsObj: {
        value: number;
        nap: number;
        mntpaid: number;
        mntrep: number;
    }[] = [];


    public months = [
        this.capitalizeFirstLetter(this._translate.instant('january')),
        this.capitalizeFirstLetter(this._translate.instant('february')),
        this.capitalizeFirstLetter(this._translate.instant('march')),
        this.capitalizeFirstLetter(this._translate.instant('april')),
        this.capitalizeFirstLetter(this._translate.instant('may')),
        this.capitalizeFirstLetter(this._translate.instant('june')),
        this.capitalizeFirstLetter(this._translate.instant('july')),
        this.capitalizeFirstLetter(this._translate.instant('august')),
        this.capitalizeFirstLetter(this._translate.instant('september')),
        this.capitalizeFirstLetter(this._translate.instant('october')),
        this.capitalizeFirstLetter(this._translate.instant('november')),
        this.capitalizeFirstLetter(this._translate.instant('december')),
    ];

    constructor(
        private readonly _saemsService: SaemsService,
        private readonly _translate: TranslateService,
        private readonly _saemcService: SaemcService,
        private readonly _parametersService: ParametersService,
    ) {
        super();
    }

    public async instaciate(row: T) {
        const saemp = row as Saempview;
        this.empId = saemp.empId || '';

        if (saemp.empId) {
            this._parametersService
                .getCurrentSapar()
                .subscribe((sapar) => (this.sapar = sapar));

            if (this.sapar != null) {
                if (this.sapar.annee != null) {
                    this._saemsService
                        .getSaemsByEmpIdAndYear(saemp.empId, this.sapar.annee)
                        .subscribe((saems: Saems[]) => {
                            // Initialize saemsObj
                            this.saemsObj = saems.map(element => {
                                const salId = `${element.salId}`;
                                return {
                                    value: parseInt(`${element.salId}`.slice(-2), 10),
                                    nap: element.mntnap !== undefined && element.mntnap !== null
                                        ? parseFloat(element.mntnap.toFixed(2))
                                        : 0.00,
                                    mntpaid: element.mntpaid !== undefined && element.mntpaid !== null
                                        ? parseFloat(element.mntpaid.toFixed(2))
                                        : 0.00,
                                    mntrep: element.mntrep !== undefined && element.mntrep !== null
                                        ? parseFloat(element.mntrep.toFixed(2))
                                        : 0.00
                                };
                            });
                            //console.log("saemsobj", this.saemsObj);

                        });

                    if (this.sapar?.annee) {
                        this._saemcService
                            .findSaemcByEmpIdAndAnee(this.empId, this.sapar.annee)
                            .subscribe(saemc => {
                                // Initialize tableData
                                this.tableData = [];

                                for (let i = 1; i <= 12; i++) {
                                    const dinValue = `din${i}` as keyof Saemc;
                                    const doutValue = `dout${i}` as keyof Saemc;
                                    const napValue = `nap${i}` as keyof Saemc;

                                    // Find corresponding Saems data
                                    const matchingSaems = this.saemsObj.find(saems => saems.value === i);
                                    //console.log(`Matching Saems for month ${i}:`, matchingSaems);
                                    this.tableData.push({
                                        value: i,
                                        month: this.months[i - 1],
                                        din: saemc[dinValue] ? saemc[dinValue] as string : '', // default value
                                        dout: saemc[doutValue] ? saemc[doutValue] as string : '', // default value
                                        nap: typeof saemc[napValue] === 'number' && saemc[napValue] != null
                                            ? parseFloat(Number(saemc[napValue]).toFixed(2))
                                            : 0.00,
                                        mntpaid: matchingSaems ? matchingSaems.mntpaid : 0.00,
                                        mntrep: matchingSaems ? matchingSaems.mntrep : 0.00
                                    });
                                    //console.log(`Table Data for month ${i}:`, this.tableData.find(data => data.value === i));
                                }
                                //console.log("Table Data after push:", this.tableData);
                                this.saemcDetails = this.tableData;
                            });
                    }
                }
            }
        }
    }

    public getEntityName() {
        return 'saempview';
    }

    private capitalizeFirstLetter(text: string): string {
        if (!text) return '';
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    private getColumns(): IwGridColumn<any>[] {
        return [
            {
                index: 0,
                prop: 'month',
                type: 'string',
                name: 'mois',
            },
            {
                index: 1,
                prop: 'din',
                type: 'string',
                name: 'entree',
            },
            {
                index: 2,
                prop: 'dout',
                type: 'string',
                name: 'sortie',
            },
            {
                index: 3,
                prop: 'nap',
                type: 'number',
                name: 'mntnap',
                align: 'right',
                decimals: 2
            },
            {
                index: 4,
                prop: 'mntpaid',
                type: 'number',
                name: 'mntpaid',
                align: 'right',
                decimals: 2
            },
            {
                index: 5,
                prop: 'mntrep',
                type: 'number',
                name: 'mntrep',
                align: 'right',
                decimals: 2
            },

        ];
    }
}
