import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

export type TemplateModule = 'FA' | 'PP' | 'SA';

@IwRestEntity('template')
export class Template {
    @IwRestEntityId() public id?: number;
    @IwColumn({
        index: 1,
        name: 'temp_name'
    }) public name?: string;
    @IwColumn({
        index: 1,
        name: 'temp_filename'
    }) public filename?: string;
    @IwColumn({
        index: 1,
        name: 'temp_type',
        type: 'enum',
        enumPrefix: 'report'
    }) public report?: string;
    public content?: string;
    public file?: string;
    public module?: TemplateModule;
}
