import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {OverlayPanel} from 'primeng/overlaypanel';

import {IwGridColumn} from '../../../../models';

@Component({
    selector: 'iw-table-cell',
    templateUrl: './iw-table-cell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IwTableCellComponent<T> {
    @Input() public row?: T;
    @Input() public column?: IwGridColumn<T>;
    @Output() public previewOver = new EventEmitter<any>();
    @ViewChild('previewOp', {static: true}) public previewOp?: OverlayPanel;
    public imgToShow?: SafeUrl;

    constructor(
        private translateService: TranslateService,
        private httpClient: HttpClient,
        private domSanitizer: DomSanitizer
    ) {
    }

    public get value(): any {
        if (this.row && this.column?.prop) {
            return this.row[this.column.prop];
        }
        return '';
    }

    public get colorMapper(): ((value: any) => string) | undefined {
        return this.column?.colorMapper;
    }

    public onPreviewMouseOver(event: MouseEvent, show: boolean) {
        if (!this.previewOp) {
            return;
        }

        if (show && this.row && this.column?.prop) {
            const currentPreviewId = this.row[this.column.prop];
            if (currentPreviewId) {
                this.handleShowPreview(currentPreviewId, event);
            }
        } else {
            this.previewOp.hide();
            this.imgToShow = undefined;
        }
    }

    public getPreviewImg(currentPreviewId?: T[Extract<keyof T, string | number>]) {
        return this.httpClient.get('/api/documents/preview/' + currentPreviewId, {responseType: 'blob'});
    }

    protected getDocTrans(value: string) {
        return this.translateService.instant(`docType_${value}`);
    }

    private handleShowPreview(currentPreviewId: T[Extract<keyof T, string | number>], event: MouseEvent) {
        this.getPreviewImg(currentPreviewId).subscribe(img => {
            this.createImageFromBlob(img);
            this.previewOp?.show(event);
        });
    }

    private createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const imageUrl = '' + reader.result;
            this.imgToShow = this.domSanitizer.bypassSecurityTrustUrl(imageUrl);
        }, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    }
}