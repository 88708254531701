import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppgri} from '@app/sam-base/models/placement';
import {SortDirection} from '@sam-base/models';

@Component({
    selector: 'iw-ppgri-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwPpgriDropdownComponent)],
    standalone: false
})
export class IwPpgriDropdownComponent extends BaseEntityDropdown<Ppgri> {

    @Input() public width?: string | number;
    @Input() public data: Ppgri[] = [];

    public mainGriTypes = [
        2,
        3,
        6];

    public defaultGriTypes = [
        1,
        4,
        5];

    public getSortProperty(): keyof Ppgri {
        return 'griName';
    }

    public getSortDirection(): SortDirection {
        return 'asc';
    }

    public ngOnInit() {
        this.applySorting();
    }

    public applySorting() {
        if (this.data && this.getSortProperty()) {
            this.data.sort((a, b) => {
                const sortProperty = this.getSortProperty();
                const direction = this.getSortDirection();

                const valA = a[sortProperty] || '';
                const valB = b[sortProperty] || '';

                const comparison = valA < valB ? -1 : valA > valB ? 1 : 0;
                return direction === 'asc' ? comparison : -comparison;
            });
        }
    }

    /** Filter to use when showing list of ppgri */
    @Input()
    public set type(v: number) {
        if (v === 16) {
            // If type is 1, filter for griType 1 and 6
            this.resultFilters = (e) => !!e.griType && [
                1,
                6].includes(e.griType);
        } else {
            this.resultFilters = v ? (e) => !!e.griType && this.mainGriTypes.includes(e.griType) : (e) => !!e.griType && this.defaultGriTypes.includes(e.griType);
        }
    }

    protected getEntityType() {
        return Ppgri;
    }

    protected buildLabel(e: Ppgri): string {
        return e.griName || '';
    }
}
