import {AfterViewInit, Component, EventEmitter, Input, Output, Type, ViewChild} from '@angular/core';
import {EsAdvancedMatchService} from '@app/sam-base/core/services/es-advanced-match.service';
import {ToastService} from '@app/sam-base/core/toast';
import {AdvMatchingParams} from '@app/sam-base/models/adv-matching-params';
import {EsAdvancedMatch} from '@app/sam-base/models/es-advanced-match';
import {GridType} from '@app/sam-base/models/grid-type.model';
import {TranslateService} from '@ngx-translate/core';

import {Ppclimis} from '@sam-base/models/placement';
import {ProfileService} from '@shared/profile/profile.service';
import {lastValueFrom, Subscription} from 'rxjs';
import {contextMenusRoles} from '../../core/auth/access-rules/context-menus';
import {loadColumnMetadataFromType} from '../../core/column';
import {loadMenuItems} from '../../core/context-menu/helpers';
import {ContextMenuEvent} from '../../core/context-menu/models';
import {FormHandlerService} from '../../core/form-handler';
import {GridProfile} from '../../core/grid-profile/models';
import {DEBUG} from '../../core/logger/logger';
import {
    EsQueryStatement,
    getEntityMetadata,
    getEntityName,
    isRestEntity,
    QueryBuilder,
    RestApiService
} from '../../core/rest-api';
import {EsScrollQuery} from '../../core/rest-api/elastic-search/es-scroll-query';
import {IwActionService} from '../../core/store';
import {
    ContextMenuVisibleMode,
    IwGridColumn,
    IwGridOptions,
    MenuItem,
    RowClickEvent,
    SortDirection
} from '../../models';
import {IwContextMenuComponent} from '../iw-context-menu/iw-context-menu.component';
import {IwTableComponent} from '../iw-table/iw-table.component';

/**
 * Display Grid based on the Columns METADATA, see [core/column]
 *
 * ATTENTION!!!: This grid uses the elastic search endpoint to retrieve elements
 *  if you don't want any delay between update/insert use the iw-rest-grid
 */
@Component({
    selector: 'iw-smart-grid',
    templateUrl: './iw-smart-grid.component.html',
    standalone: false
})
export class IwSmartGridComponent<T> implements AfterViewInit {

    @Output() public columnsChange = new EventEmitter<number>();
    @Output() public selected = new EventEmitter<T[]>();
    @Output() public contextMenuClick = new EventEmitter<ContextMenuEvent<T>>();
    @Output() public rowDoubleClick = new EventEmitter<RowClickEvent<T>>();
    @Output() public esAdvancedMatchChanged = new EventEmitter<EsAdvancedMatch>();
    /** Enables raw table mode - hide headers and cell lines */
    @Input() public rawTableMode = false;
    /** If true, double click will open entity form */
    @Input() public autoFormClick = true;
    @Input() public hasFilter = true;
    @Input() public hasSortIcon = true;
    @Input() public hasGridColumnMenu = true;
    @Input() public customColumns: IwGridColumn<T>[] = [];
    @Input() public innerWidth = '100%';
    /** Enables grid details on click */
    @Input() public showGridDetails = true;
    @Input() public selectMode: 'single' | 'multi' | 'checkbox' = 'single';
    @Input() public contextMenuDetail?: string;
    @Input() public gridType?: GridType;
    @Input() public virtualScroll = false;
    @Input() public selectAllFromServerEnabled = false;
    @Input() public contextMenuEnabled = true;
    public contextMenuData?: ContextMenuEvent<T>;
    public contextMenuItems: MenuItem[] = [];
    @Output() public dataChanged = new EventEmitter<T[]>();
    public tableOptions: IwGridOptions<T> = {
        columns: [],
        data: [],
        lazyLoad: true,
        lazyLoadScroll: () => this.loadData(),
        lazyLoadReload: () => this.resetData(true),
    };
    @ViewChild('table', {static: true}) public grid?: IwTableComponent<T>;
    @ViewChild('menu', {static: true}) public menu?: IwContextMenuComponent;
    public selectedData?: {
        name: Extract<keyof T, string | number> | undefined; value: any;
    };
    public allServerEntities: T[] = [];
    private _pk?: Extract<keyof T, string | number>; // Primary key
    private _ready = false;
    private _scrollClient?: EsScrollQuery<T>;
    private _subscription?: Subscription;
    private isReloading = false;
    private currentRows = 0;
    private selectedAllFromServer = false;

    constructor(private readonly _restService: RestApiService, private readonly _action: IwActionService<T>,
                private readonly _formHandler: FormHandlerService<string>,
                private readonly _profileService: ProfileService,
                private readonly _esAdvMatchService: EsAdvancedMatchService<T>, private _toastService: ToastService,
                private _translate: TranslateService) {
    }

    @Input()
    public set sortProp(prop: string) {
        if (this.grid) {
            this.grid.sortProp = prop;
        }
    }

    @Input()
    public set sortDir(dir: SortDirection) {
        if (this.grid) {
            this.grid.sortDir = dir;
        }
    }

    public get columns() {
        return this.tableOptions.columns;
    }

    public get entityPk() {
        return this._pk || 'id';
    }

    private _columnsToHide: string[] = [];

    @Input()
    public set columnsToHide(columnsToHide: string[]) {
        this._columnsToHide = columnsToHide;
        this.columnsReset(columnsToHide);
    }

    private _type?: Type<T>; // Type of entity to load

    public get type() {
        return this._type;
    }

    @Input()
    public set type(v: Type<T> | undefined) {
        this.setType(v);
    }

    private _itemsPerPage = 100;

    public get itemsPerPage() {
        return this._itemsPerPage;
    }

    @Input()
    public set itemsPerPage(v: number) {
        this.setPageSize(v);
    }

    private _totalPages = 1; // Total number of pages

    public get totalPages() {
        return this._totalPages;
    }

    public get totalHits(): number {
        return this._scrollClient?.totalHits ?? 0;
    }

    private _queryStatements: EsQueryStatement<T>[] = [];

    public get queryStatements() {
        return this._queryStatements;
    }

    @Input()
    public set queryStatements(v: EsQueryStatement<T>[]) {
        this.setQueryStatement(v);
    }

    private _advancedMatchData?: AdvMatchingParams;

    @Input()
    public set advancedMatchData(v: AdvMatchingParams | undefined) {
        this._advancedMatchData = v;
    }

    public ngAfterViewInit() {
        this._ready = true;
        this.refresh();
    }

    public loadGridProfile(): GridProfile<T> {
        if (this.grid) {
            const p = this.grid.loadGridProfile();
            if (this._type) {
                p.entity = getEntityName(this._type) || '';
            }
            return p;
        }

        throw new Error('Grid not avaible!');
    }

    // eslint-disable-next-line complexity
    public async onContextMenu(event: ContextMenuEvent<T>) {
        event.extraData = this.contextMenuDetail ?? getEntityName(this._type);
        if (!this.showMisContextMenu(event.value)) {
            return;
        }
        if (this.canOpenContextMenu(getEntityName(this._type) ?? event.extraData)) {
            this.contextMenuClick.emit(event);
            if (this._type) {
                this.contextMenuData = event;
                const prop = event.column.prop || '';
                this.contextMenuItems = loadMenuItems(this._type, prop.toString());
                this.contextMenuItems = this.filterByVisibleMode(this.contextMenuItems, event);
                this.contextMenuItems = this.filterByProp(this.contextMenuItems, event);
                this.contextMenuItems = this.filterItemByAccessRoles(this.contextMenuItems);
                if (this.contextMenuItems.length) {
                    await this.showContextMenu(event.event);
                }
            }
        }

    }

    public async onRowDoubleClick(event: RowClickEvent<T>) {
        if (this.autoFormClick && this._type) {
            const item = new this._type(event.row);
            if (isRestEntity(item)) {
                const entityId = item.$getPk()
                    .toString();
                if (item.$entity !== 'ppsuivi') {
                    this._formHandler
                        .showFormDialog(item.$entity, undefined, e => ({
                            ...e,
                            entityId
                        }));
                }
            }
        }
        this.rowDoubleClick.emit(event);
    }

    public onSelection(data?: any) {
        this.hideContextMenu();

        if (typeof data !== 'undefined' && this.isReloading && data.length) {
            this.selected.emit(data);
            this.selectedData = {
                name: this._pk,
                value: data[0][this._pk]
            };
        } else {
            this.selected.emit(data);
            this.selectedData = undefined;
        }
    }

    /** Reload table data */
    public async refresh() {
        this.isReloading = true;
        if (this.grid) {
            await this.grid.refresh();
        }
    }

    /** Remove all data and reload */
    public async reset() {
        this.closeScrollClient();
        if (this.grid) {
            await this.grid.reset();
            await this.grid.refresh();
        }
    }

    public onDataChanged(data?: T[]) {
        this.dataChanged.emit(data);
    }

    /** Triggers the lazyload reload keeping the selected data */
    public refreshData() {
        this.grid?.softRefresh();
    }

    public onSelectAllFromServer($event: { selected: boolean; totalHits: number }) {
        this.selectedAllFromServer = $event.selected;
        if (!this.selectedAllFromServer) {
            this.allServerEntities = [];
            return;
        }
        if (this._type) {
            const gridProfile = this.loadGridProfile();
            const builder = QueryBuilder.fromGridProfile(gridProfile, this._type);
            builder.addStatement(...this._queryStatements);
            const req = builder.getRequest();
            const promise = lastValueFrom(this._restService.elasticSearchService.getScroll(this._type, req, $event.totalHits).next());
            promise.then((values) => {
                this.allServerEntities = values;
            });
        }
    }

    private filterItemByAccessRoles(contextMenuItems: MenuItem[]): MenuItem[] {
        return contextMenuItems.filter(item => {
            if (!item.accessRoles) {
                return true;
            }
            return this._profileService.checkUserPermission(item.accessRoles);
        });
    }

    private filterByProp(contextMenuItems: MenuItem[], event: ContextMenuEvent<T>): MenuItem[] {
        return contextMenuItems
            .filter((e) => {
                // Filters the menu items according ...
                // ... to the filter value of MenuItems
                if (e.filter) {
                    if (event.selected.length) {
                        const filter = e.filter;
                        return event.selected.every((elem: any) =>
                            Object.keys(filter).every(key => {
                                const filterKey = key as keyof typeof filter & keyof T;
                                return filter[filterKey] === elem[filterKey];
                            })
                        );
                    }
                    return false;
                }
                return true;
            });
    }

    private filterByVisibleMode(contextMenuItems: MenuItem[], event: ContextMenuEvent<T>): MenuItem[] {
        const currentVisibleMode: ContextMenuVisibleMode = event.selected.length > 1 ? 'multiple' : 'single';
        return contextMenuItems
            .filter((e) => e.contextMenuVisibleMode === currentVisibleMode || e.contextMenuVisibleMode === 'all');
    }

    private showMisContextMenu(entity?: unknown) {
        if (!entity) {
            return true;
        }
        return !this.isMissionInCreation(entity);
    }

    private isMissionInCreation(e?: unknown): boolean {
        return !!(this.isMission(e) && e.creating);
    }

    private isMission(e: unknown): e is Ppclimis {
        return isRestEntity(e) && e.$entity === 'ppclimis';
    }

    private canOpenContextMenu(entity?: string) {
        if (!entity) {
            return true;
        }
        return (this._profileService.checkUserPermission(contextMenusRoles(entity)));
    }

    private setQueryStatement(q?: EsQueryStatement<T>[]) {
        if (!q) {
            this._queryStatements = [];
            return;
        }

        this._queryStatements = q;
        this.refresh();
    }

    /** Request more data to endpoint */
    // eslint-disable-next-line complexity
    private async loadData(): Promise<T[]> {
        if (!this._ready) {
            return [];
        }

        if (this._scrollClient) {
            try {
                const data = await lastValueFrom(this._scrollClient.next());
                this.currentRows = data.length ? (this.currentRows === 0 ? this._itemsPerPage + data.length : this.currentRows + data.length) : this.currentRows;
                return data;
            } catch (err) {
                DEBUG('The data from the last point in time could not be retrieved');
                this._toastService.info([], this._translate.instant('table_refresh'));
                setTimeout(() => this.reset(), 500);
                return [];
            }
        } else {
            return this.resetData();
        }
    }

    /** Reset scroll params */
    // eslint-disable-next-line complexity
    private async resetData(keepResults = false): Promise<T[]> {
        if (!keepResults) {
            this.currentRows = 0;
        }

        if (!this._ready) {
            return [];
        }

        this.closeScrollClient();

        if (this._type) {
            const gridProfile = this.loadGridProfile();
            const builder = QueryBuilder.fromGridProfile(gridProfile, this._type);
            builder.addStatement(...this._queryStatements);
            const req = builder.getRequest();

            if (this.currentRows !== 0 && this.selectedData) {
                this.setPageSize(this.currentRows);
            } else {
                this.setPageSize(this._itemsPerPage);
            }
            if (this._advancedMatchData) {
                this.disableLoadOnScroll(true);
                const advData = this._advancedMatchData;
                const sortProp = gridProfile.columns.sortBy;
                const currentDir = gridProfile.columns.sordDir;
                const sortDir = this.getAdvMatchSortDir(sortProp, currentDir);

                const results: Promise<T[]> = lastValueFrom(this._esAdvMatchService
                    .getEsAdvancedResults(this._type, req, advData, sortDir));

                // eslint-disable-next-line max-len
                const esAdvMatch: EsAdvancedMatch = {
                    ...advData,
                    esQuery: JSON.stringify(req)
                };
                this.esAdvancedMatchChanged.emit(esAdvMatch);

                return results;
            }

            this.disableLoadOnScroll(false);

            this._scrollClient = this._restService.elasticSearchService.getScroll(this._type, req, this._itemsPerPage);

            if (!keepResults || !this.selectedData) {
                this.deselectRows();
            }
            return lastValueFrom(this._scrollClient.next());
        }

        this.deselectRows();
        return [];
    }

    private deselectRows() {
        if (this.grid && this.selectedData) {
            this.grid.deselectAll();
        }
    }

    private closeScrollClient() {
        if (this._scrollClient) {
            this._scrollClient.close();
            this._scrollClient = undefined;
        }
    }

    private getAdvMatchSortDir(sortProp: Extract<keyof T, string> | undefined,
                               sortDir: SortDirection | undefined): SortDirection | undefined {
        if (!sortProp) {
            return 'desc';
        } else {
            return sortProp === 'score' ? sortDir : undefined;
        }
    }

    private disableLoadOnScroll(v: boolean) {
        if (!this.grid) {
            return;
        }
        this.grid.isLoadOnScrollDisabled = v;
    }

    /**
     * Defines the number of items per scroll
     *
     * @param pageSize items number
     */
    private async setPageSize(pageSize: number) {
        if (!pageSize || pageSize === this.itemsPerPage) {
            return;
        }

        this._itemsPerPage = pageSize;
    }

    private async showContextMenu(event: MouseEvent) {
        if (this.menu) {
            await this.menu.show(event);
        }
    }

    private async hideContextMenu() {
        if (this.menu) {
            await this.menu.hide();
        }
    }

    private async setType(type?: Type<T>) {
        if (type && type !== this._type) {
            this._type = type;
            this._pk = <any>getEntityMetadata(type).$pk;
            this.tableOptions.data = [];
            this.buildColumnList(type);
            this.updateEntitySubscription();
            await this.reset();
        }
    }

    private buildColumnList(type: Type<T>) {
        const columns = this.customColumns.length > 0 ? this.customColumns : loadColumnMetadataFromType(type);
        this.hideColumns(columns);
        this.tableOptions.columns = columns;
    }

    private async columnsReset(columnsToHide: string[]) {
        if (!this.type || !columnsToHide) {
            return;
        }
        this.buildColumnList(this.type);
        await this.reset();
    }

    private hideColumns(columns: IwGridColumn<T>[]) {
        let indexes: number[] = [];
        this._columnsToHide.forEach(col => {
            const index = columns.findIndex(colm => colm.prop === col);
            if (index !== -1) {
                indexes.push(index);
            }
        });
        indexes = indexes.sort((a, b) => b - a);
        indexes.forEach(index => columns.splice(index, 1));
    }

    /** Add store subscription to reload when a value changes */
    private updateEntitySubscription() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }

        const t = this._type;

        if (t) {
            this._subscription = this._action.entityChange(t)
                .subscribe(() => setTimeout(() => this.refresh(), 0));
        }
    }
}
