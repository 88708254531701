import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SortDirection} from '@app/sam-base/models';
import {Ppcct} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-cct-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwCctDropdownComponent)],
    standalone: false
})
export class IwCctDropdownComponent extends BaseEntityDropdown<Ppcct> {

    @Input() public width?: string | number;

    @Input()
    public set activeOnly(v: boolean) {
        this.resultFilters = v ? (e: Ppcct) => e.cctstatus === '1' : (_: Ppcct) => true;
    }

    public getSortProperty(): keyof Ppcct {
        return 'cctLib';
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    protected getEntityType() {
        return Ppcct;
    }

    protected buildLabel(e: Ppcct): string {
        return `[${e.cctId}] ${e.cctLib || ''}`;
    }
}
