import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services';
import {FofouService} from '@app/sam-base/core/services/fofou.service';
import {Fofoudp} from '@app/sam-base/models/fornisseurs/fofoudp';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {map} from 'rxjs/operators';
import {IwTextfieldListComponent} from './iw-textfield-list.component';

@Component({
    templateUrl: './iw-textfield-list.component.html',
    selector: 'iw-textfield-fofoudp',
    providers: [buildCustomValueProvider(IwTextfieldFofoudpComponent)],
    standalone: false
})
export class IwTextfieldFofoudpComponent extends IwTextfieldListComponent<Fofoudp> {
    public readonly listOption: ModalSelectTableOption<Fofoudp, string>;

    @Input() public fouId = '';

    @Output() public fofoudpChanged = new EventEmitter<Fofoudp>();

    constructor(modalService: ModalService, restService: RestApiService, elRef: ElementRef, private readonly _fofouService: FofouService) {
        super(modalService, elRef, restService);
        this.propsToFilter = ['foudpId'];
        this.listOption = {
            columns: [{prop: 'foudpId', name: 'no', width: 30}, {prop: 'banque'}], map: (e: Fofoudp) => {
                this.fofoudpChanged.emit(e);
                return e.foudpId || '';
            }, selection: 'single', fetchAction: () => this._fofouService.getFofoudpByFouId(this.fouId)
                .pipe(map((l) => l.filter((e) => e.foudpId)))
        };
    }
}
