import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {buildCustomValueProvider} from '@sam-base/base';
import {ERROR, RestApiService} from '@sam-base/core';
import {NpaService} from '@sam-base/core/services/npa.service';
import {ToastService} from '@sam-base/core/toast';
import {Npa} from '@sam-base/models';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {IwTextFieldComponent} from '../iw-textfield/iw-textfield.component';

export class NpaResultEvent {
    public npa?: string;
    public results: Npa[] = [];
}

@Component({
    templateUrl: '../iw-textfield/iw-textfield.component.html',
    selector: 'iw-npa-textfield',
    providers: [buildCustomValueProvider(NpaTextfieldComponent)],
    standalone: false
})
export class NpaTextfieldComponent<T> extends IwTextFieldComponent implements OnInit, OnDestroy {

    @Output() public relatedLieu = new EventEmitter();

    private subscriptions = new Subject();

    constructor(elRef: ElementRef, private readonly _toastService: ToastService, private _restService: RestApiService, private readonly npaService: NpaService) {
        super(elRef);
    }

    public ngOnInit() {
        this.controlFocus
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => this.getLieuByNpa());
        super.ngOnInit();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    /**
     * Function to get lieu by npa
     */
    public async getLieuByNpa() {
        try {
            const npa = this.getValue();
            if (npa && this.canCalculateLieu(npa)) {
                this.npaService.getAllByNpa(npa)
                    .subscribe(npaData => {
                        const npaResultEvent = new NpaResultEvent();
                        npaResultEvent.npa = npa;
                        npaResultEvent.results = npaData;
                        this.relatedLieu.emit(npaResultEvent);
                    });
            }
        } catch (error) {
            ERROR('error calculating lieu');
        }
    }

    private canCalculateLieu(npa: string) {
        return (npa.length === 4 && !this.isDisabled);
    }

}
