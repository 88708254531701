import {Component, EventEmitter} from '@angular/core';
import {IwGridColumn, ModalComponent, RowClickEvent} from '@app/sam-base/models';
import {HtmlElementStatus} from '@app/sam-base/models/html-element-status.model';
import {EntityNavigationService} from '@sam-base/core';
import {Gerpl} from '@sam-base/models/invoices/gerpl';
import {GerplhdrView, invoiceReminderColorMapper} from '@sam-base/models/invoices/gerplhdrview';
import {ModalRappelsOptions} from './modal-rappels.component';


@Component({
    selector: 'iw-modal-message',
    templateUrl: './modal-rappels.component.html',
    standalone: false
})
export class ModalRappelsComponent implements ModalComponent<void, ModalRappelsOptions> {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public okDisabled: HtmlElementStatus = {
        enabled: true,
        value: 'Ok',
        visible: true
    };
    public cancelShow: HtmlElementStatus = {
        enabled: true,
        value: 'Cancel',
        visible: true
    };

    public tableColumns: IwGridColumn<Gerpl>[] = [
        {
            prop: 'rpsId',
            name: 'session',
            index: 1
        },
        {
            prop: 'dateRpl',
            name: 'date',
            type: 'date',
            index: 0
        },
        {
            prop: 'noRappel',
            name: 'norappel',
            index: 2,
            colorMapper: (row: GerplhdrView) => {
                return invoiceReminderColorMapper(row);
            }
        }];

    private _options!: ModalRappelsOptions;
    private _ok?: (result: void) => void;
    private _cancel?: (error?: any) => void;

    public constructor(private readonly _entityNavigationService: EntityNavigationService) {
    }

    public get content() {
        return this._options.content;
    }

    public get title() {
        return this._options.title;
    }

    public get width() {
        return this._options.width;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok();
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public async setData(data: ModalRappelsOptions): Promise<void> {
        this._options = data;
        if (this._options.okDisabled) {
            this.okDisabled = {
                ...this.okDisabled,
                enabled: false,
                visible: true
            };
        }

        if (!this._options.showCancel) {
            this.cancelShow = {
                ...this.cancelShow,
                enabled: false,
                visible: false
            };
        }
    }

    public registerOk(action: (result: void) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onRowDoubleClick($event: RowClickEvent<Gerpl>) {
        this._entityNavigationService.navigateToEntityForm(GerplhdrView, $event.row.rplhdrId!, undefined, 'read');
    }
}
