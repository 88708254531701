import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SortDirection} from '@app/sam-base/models';
import {Ppjf} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-ppjf-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwPpjfDropdownComponent)],
    standalone: false
})
export class IwPpjfDropdownComponent extends BaseEntityDropdown<Ppjf> {
    @Input() public width?: string | number;

    public getSortProperty(): keyof Ppjf {
        return 'jfdate';
    }

    public getSorDirection(): SortDirection {
        return 'desc';
    }

    protected getEntityType() {
        return Ppjf;
    }


    protected buildLabel(e: Ppjf): string {
        return `${e.jfdate || ''} | ${e.jflibelle || ''}`;
    }
}
