import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ComboboxItem} from '@app/sam-base/models';
import {NpaResultEvent} from '@sam-base/components/iw-npa-textfield/npa-textfield.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './npa-lieu.component.html',
    selector: 'iw-npa-lieu',
    standalone: false
})
export class NpaLieuComponent implements OnInit, OnDestroy {

    @Input() public pays = 'CH';

    @Input() public labelNpa = 'NPA';

    @Input() public labelNpaAlign: 'top' | 'left' | 'right' = 'left';

    @Input() public formGroup = new UntypedFormGroup({});

    @Input() public npaFormControlName = '';

    @Input() public lieuFormControlName = '';

    @Input() public isDisabled = true;
    public optionsNpa: ComboboxItem<string>[] = [];
    private subscriptions = new Subject();
    private currentNpa?: string;

    constructor() {
    }

    public get isCH(): boolean {
        return this.pays === 'CH';
    }

    public get npaControlValue(): string {
        return this.formGroup.get(this.npaFormControlName)?.value;
    }

    public ngOnInit() {
        this.reloadLieuFromItsValue();
        this.formGroup.get(this.npaFormControlName)
            ?.valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe((npa) => {
                this.currentNpa = undefined;
                this.reloadLieuFromItsValue();
            });
        this.formGroup.get(this.lieuFormControlName)
            ?.valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe((place) => {
                this.buildOptionsForLieuBasedOnValueForm(place);
            });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public updateRelatedLieu($event: NpaResultEvent) {
        this.currentNpa = $event.npa;
        this.optionsNpa = [];
        this.optionsNpa.push({
            name: '-------',
            value: ''
        });
        $event.results.forEach((npa) => {
            this.optionsNpa.push({
                name: npa.lieu ?? '',
                value: npa.lieu ?? ''
            });
        });

        // if this.lieuFormControlName is within the this.optionsNpa value then do nothing, otherwise, set this.lieuFormControlName to empty
        // and country is CH
        if (!this.optionsNpa.some(item => item.value === this.formGroup.get(this.lieuFormControlName)?.value) && this.isCH) {
            this.formGroup.get(this.lieuFormControlName)?.setValue('');
        }
    }

    private reloadLieuFromItsValue() {
        const lieu = this.formGroup.get(this.lieuFormControlName)?.value;
        this.buildOptionsForLieuBasedOnValueForm(lieu);
    }

    private buildOptionsForLieuBasedOnValueForm(lieu: string | undefined) {
        if (this.npaControlValue !== this.currentNpa || !this.npaControlValue) {
            this.optionsNpa = [];
            if (lieu) {
                this.optionsNpa.push({
                    name: lieu,
                    value: lieu
                });
            }
        }
    }


}
