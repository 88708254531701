import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppcliadr} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-cliadr-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwCliAdrDropdownComponent)],
    standalone: false
})
export class IwCliAdrDropdownComponent extends BaseEntityDropdown<Ppcliadr> {

    @Input() public width?: string | number;

    @Input() public showId ? = false;

    protected getEntityType() {
        return Ppcliadr;
    }

    protected buildLabel(e: Ppcliadr): string {
        if (!this.showId) {
            return e.adrlib || '';
        } else {
            return (e.adrlib + ' | ' + e.cliadrId) || '';
        }
    }
}
