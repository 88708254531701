import {Component, EventEmitter, Input, Output} from '@angular/core';

import {Weekday} from './weekday';

@Component({
    selector: 'iw-weekday-picker', templateUrl: 'iw-weekday-picker.component.html',
    standalone: false
})
export class IwWeekdaysPickerComponent {

    @Output() public selectionChange = new EventEmitter<(0 | 1 | 2 | 3 | 4 | 5 | 6)[]>();
    public weekdays: Weekday[] = [{label: 'Lun', val: 0, value: false}, {
        label: 'Mar', val: 1, value: false
    }, {label: 'Mer', val: 2, value: false}, {label: 'Jeu', val: 3, value: false}, {
        label: 'Ven', val: 4, value: false
    }, {label: 'Sam', val: 5, value: false}, {label: 'Dim', val: 6, value: false}];
    private _isDisabled = false;

    public get disabled() {
        return this._isDisabled;
    }

    @Input()
    public set disabled(v: boolean) {
        this._isDisabled = v;
    }

    public get allChecked() {
        return this.weekdays.every(e => e.value === true);
    }

    public setSelections(selections: (0 | 1 | 2 | 3 | 4 | 5 | 6)[]) {
        this.weekdays.forEach((wd) => {
            if (selections.includes(wd.val)) {
                wd.value = true;
            }
        });
        this.selectionChange.emit();
    }

    public onValueChange(value: boolean, index: number) {
        this.weekdays[index].value = value;
        this.selectionChange.emit();
    }

    public onSelectEveryClick() {
        if (this.allChecked) {
            this.weekdays.forEach(e => e.value = false);
        } else {
            this.weekdays.forEach(e => e.value = true);
        }
        this.selectionChange.emit();
    }

    public extractSelections() {
        const selection: (0 | 1 | 2 | 3 | 4 | 5 | 6)[] = [];
        this.weekdays.forEach((wday) => {
            if (wday.value) {
                selection.push(wday.val);
            }
        });
        return selection;
    }

    public reset() {
        this.weekdays.forEach(e => e.value = false);
    }
}
